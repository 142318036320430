import React, { Component } from "react";

import "react-toastify/dist/ReactToastify.css";
// import TripScheduler from "./TripScheduler";
import BasicTabs from './TripScheduler'

let fullDataSet = [];
class _TripScheduler extends Component {
  render() {
    return (
      <>
        <BasicTabs />
      </>
    );
  }
}

export default _TripScheduler;
