import { React, useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  ClickAwayListener,
  Grid,
  LinearProgress,
  Button,
  IconButton,
  Alert,
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { attestationList } from "./RosterPlanner";
import { Check, CloudUpload, Refresh, Edit } from "@mui/icons-material";
import CSVUploadErrorDialog from "../../dashboard/components/csvUploadErrorDialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment, { duration } from "moment";
import { makeStyles } from "@mui/styles";
import { darken, lighten, styled } from "@mui/material/styles";
import ResolveConfirmDialog from "./components/Dialog/ResolveConfirmDialog";
import { StyledAvatar } from "../../styled";
import LocationEditRibbon from "./components/LocationEditRibbon";
import { isNonNullChain } from "typescript";
import { Place } from "@mui/icons-material";

const datagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 2 },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#f5fafe",
    color: "#033d5f",
    fontWeight: "bold !important",
    fontSize: 14,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold !important",
    color: "#033d5f !important",
    overflow: "visible",
    lineHeight: "1.43rem",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-cell": {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
};

const useStyle = makeStyles({
  root: {
    "& .wrapHeader .MuiDataGrid-colCellTitle": {
      overflow: "visible",
      lineHeight: "1.43rem",
      whiteSpace: "normal",
    },
  },
  red: {
    backgroundColor: "red",
  },
});

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const PlannerDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .planner-view-grid--O-0": {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .planner-view-grid--O-1": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .planner-view-grid--O-3": {
    backgroundColor: getBackgroundColor(
      theme.palette.secondary.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <GridToolbarExport
        csvOptions={{
          allColumns: true,
          fileName: `${moment(new Date()).format(
            "YYYY-MM-DD HH:mm:ss"
          )}_mas_roster`,
        }}
      />
    </GridToolbarContainer>
  );
}



const AttestationsUploadView = (props) => {
  const classes = useStyle();

  const [showErrorUploadCsv, setShowErrorUploadCsv] = useState(false);
  const [processFailedCSVRows, setProcessFailedCSVRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [masDrivers, setMasDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("All");
  const [invoiceToResolve, setInvoiceToResolve] = useState(null);
  const [showUpateConfimation, setShowUpateConfimation] = useState(false);
  const [resolve, setResolve] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const columns = useMemo(
    () => [
      {
       
        field: duration,
        headerName: "Action",
        sortable: false,
        editable: true,
        width: 80,
        type: 'action',
        textAlign:"center",
        headerAlign:"center",
        align:"center",
        renderCell: (params) => {
          return (
              <div style={{  textAlign:"center", width:"50", display:"flex", flexDirection:"row",  alignItems:"center"}}>
                  <div style={{ marginLeft: 1 }}>
                    <IconButton variant="contained"
                            size="small"
                            // color={ params.row?.restartSequance?"secondary":"primary"}
                            style={{ marginLeft: 2 , width:30, height:30}}
                            tabIndex={params.hasFocus ? 0 : -1}
                            onClick={(e)=>{handleResolveInvoice(params.row.invoiceNumber)}}>
                              <StyledAvatar variant="rounded" 
                               style={{width:30, height:30}}
                                success={params.row.order && params.row.driverName?true:false} 
                                enabled={params.row.order && params.row.driverName?true:false}>R</StyledAvatar>
                    </IconButton>
                  </div>
              </div>
            );
          },

      },
      {
       
        field: "tripStatus",
        headerName: "Trip Status",
        sortable: true,
        editable: false,
        width: 80,
        align:"center",
        type: 'action',
        renderCell: (params) => {
          const tripStatus = params.row.tripStatus.toUpperCase();
          const legStatus = params.row.tripLegStatus.toUpperCase();
          return (
              <div style={{  textAlign:"center", width:"50"}}>
                  <div style={{ marginLeft: 2 }}>
                      {legStatus == 2? <StyledAvatar  enabled={true} success={false} sizes="small">DEL</StyledAvatar> :
                        tripStatus == 'Cancelled/Combined'.toUpperCase()? <StyledAvatar  enabled={true} success={false} sizes="small">CC</StyledAvatar>:
                        tripStatus == 'Cancelled/Weather'.toUpperCase()? <StyledAvatar  enabled={true} success={false} sizes="small">CW</StyledAvatar>:
                        tripStatus == 'Eligible'.toUpperCase()? <StyledAvatar  enabled={true} success={true} >EL</StyledAvatar>:
                        <StyledAvatar  enabled={true} success={false} sizes="small">CA</StyledAvatar>}
                  </div>
              </div>
            );
          },

      },
      {
        field: "invoiceNumber",
        headerName: "Invoice",
        width: 100,
        editable: false,
        sortable: true,
      },
      {
        field: "tripLegSequence",
        headerName: "Leg",
        type: "number",
        width: 50,
        editable: false,
        sortable: true,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "pickupDate",
        headerName: "Date",
        type: "number",
        width: 100,
        editable: false,
        sortable: true,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "customerName",
        headerName: "Customer",
        width: 150,
        editable: false,
        sortable: true,
      },
      {
        headerName: "Pickup / Dropoff Address",
        field: "pickup_dropoff_address",
        sortable: true,
        editable: false,
        width: 350,
        renderCell: (params) => {
          return (
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <div style={{ marginBottom: 5 }}>
                    <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                    {params.row.pickaddress}
                  </div>
                  {params.row?.waypoints?.map((item, index) => {
                    // Ignore the first and last items for Pickup and dropoff address
                    if (index === 0 || index === params.row.waypoints.length - 1) {
                      return null;
                    }
                    return (
                      <ListItem key={item.id} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemIcon sx={{ minWidth: 28 }}>
                          <Place fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2" sx={{ fontSize: '12px !important' }}>
                              {item.address}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                  <div>
                    <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                    {params.row.dropoffAddress}
                  </div>
                </div>
              </Grid>
              <Grid item xs>
                <IconButton color="primary" aria-label="adit address" onClick={() => handleEditAddressClick(params)}>
                  <Edit />
                </IconButton>
              </Grid>
            </Grid>
            
          );
        },
      },
      {
        field: "masPickupTime",
        headerName: "Pickup",
        sortable: true,
        editable: true,
        width: 70,
      },
      {
        field: "masDropoffTime",
        headerName: "Dropoff",
        sortable: true,
        editable: false,
        width: 70,
      },
      {
        field: "duration",
        headerName: "Duration",
        sortable: true,
        editable: false,
        width: 70,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "driverName",
        headerName: "Driver Name",
        sortable: true,
        editable: true,
        width: 150,
        type: "singleSelect",
        valueOptions: [
          ...new Set(masDrivers.map((option) => option.firstname.trim())),
        ],
      },
      {
        field: "vehicle",
        headerName: "Vehicle",
        sortable: true,
        editable: false,
        width: 120,
        valueGetter: ({ value }) =>
          value && masDrivers.find((md) => md.licencse == value)?.vehiclename,
      },
      {
        field: "order",
        headerName: "Order",
        sortable: true,
        editable: true,
        width: 100,
        align: "center",
        headerAlign: "center",
        type: "number",
      },
      {
        field: "assignedOrder",
        headerName: "Disp. Order",
        sortable: true,
        editable: false,
        width: 70,
        align: "center",
        headerAlign: "center",
        type: "number",
      },
      {
        field: "assignedDriver",
        headerName: "Disp. Driver",
        sortable: true,
        editable: false,
        width: 100,
        type: "singleSelect",
        valueOptions: [
          ...new Set(masDrivers.map((option) => option.firstname.trim())),
        ],
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        editable: true,
        width: 120,
        type: "singleSelect",
        valueOptions: ["Assigned", "Blocked", "Cancel", "No show", "Unassigned"],
      },
    ],
    [masDrivers]
  );




  useEffect(() => {
    async function getMasDrivers() {
      await attestationList
        .fetchMasDrivers()
        .then((res) => {
          setMasDrivers(res);
        })
        .catch((error) => {
          setMasDrivers([]);
        });
    }
    getMasDrivers();
  }, []);

  useEffect(() => {
    if (Object.keys(props.searchAttrs).length === 0) {
      loadPastAttestationsData({
        tripDate: props.searchAttrs.tripDate
          ? moment(props.searchAttrs.tripDate).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD"),
        source: props.searchAttrs.source,
      });
    } else {
      loadPastAttestationsData(props.searchAttrs);
    }
    setSelectedAddress(null)
  }, [props]);

  useEffect(()=>{
      async function resolveDrivers() {
          setLoading(true);
          await resolveAttestations();
          setSelectedDriver("All");
          setInvoiceToResolve(null)

      }
    if(resolve)
      resolveDrivers();
  },[selectedDriver, invoiceToResolve, resolve])
  

  const handleFileUpload = async (e) => {
    if (!e.target.files) {
      return;
    }

    try {
      setRows([]);
      setLoading(true);

      const file = e.target.files[0];
      const response = await attestationList.uploadAttatations(file);

      if (response.failedRows.length > 0) {
        setProcessFailedCSVRows(response.failedRows);
        setShowErrorUploadCsv(true);
      } else if (response.successRows.length === 0) {
        toast.warning("No valid records were found", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Attestations successfully uploaded", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        const res = response.successRows;
        res.forEach((rec) => {
          rec.pickup_dropoff_address = `${rec.pickaddress}|||${rec.dropoffAddress}`;
        });

        setRows(res);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const hideCSVUploadErrorDialog = useCallback(() => {
    setShowErrorUploadCsv(false);
  }, []);

  const processRowUpdate = useCallback(
    (newRow) => {
      const index = rows.findIndex((row) => row.id === newRow.id);
      const tempRows = [...rows];

      if (newRow.driverName) {
        newRow["vehicle"] = masDrivers.find(
          (driver) => driver.firstname.trim() === newRow.driverName.trim()
        )["licencse"];
      } else {
        newRow["vehicle"] = null;
      }

      tempRows[index] = newRow;
      setRows(tempRows);
      return newRow;
    },
    [rows, masDrivers]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    toast.error(`[Error] ${error.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  const updateRows = useCallback(async () => {
    if (rows.length < 1) {
      return;
    }
    handleCloseRibbon(null);
    setLoading(true);
    await attestationList
      .updateDailyPlannedRoster(rows)
      .then(() => {
        toast.success("Attestations successfully updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.onSuccess();
      })
      .catch(() => {
        toast.error("Attestations update failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [rows]);



  const resolveAttestations = useCallback(async () => {
    if (rows.length < 1) {
      return;
    }
    if(!resolve)
       return;
    let data = { date: rows[0].pickupDate, invoiceNumber:invoiceToResolve }
    if(selectedDriver != "All" && selectedDriver != null) 
      data.driverList  = selectedDriver
    await attestationList
      .resolveDailyPlannedRosterData(data)
      .then(() => {
        toast.success("Attestations successfully resolved", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.onSuccess();
      })
      .catch(() => {
        toast.error("Attestations resolving failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setLoading(false);
        setResolve(false);
      });
  }, [rows, selectedDriver, resolve]);

 
  const loadPastAttestationsData = useCallback(async (searchAttrs) => {
    setLoading(true);
    await attestationList
      .getPastAttestationsData(searchAttrs)
      .then((res) => {
        res.forEach((rec) => {
          rec.pickup_dropoff_address = `${rec.pickaddress}|||${rec.dropoffAddress}`;
          if (!rec.status || rec.status === "") {
            rec.status = "Assigned";
          } 
          // if (rec.assignedDriver != null || rec.assignedDriver) {
          //    console.log(rec.assignedDriver);
          //    console.log(rec.scheduleorder);
          // }
        });
        setRows(res);
      })
      .catch((error) => {
        setRows([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);



  function handleCloseConfimation(value){
    if(value != null){
      setResolve(true);
      setSelectedDriver(value);
    }
    setShowUpateConfimation(false)
  }

  
  function handleResolveInvoice(invoiceNumber) {
    setInvoiceToResolve(invoiceNumber);
    setResolve(true);
  }

  const handleEditAddressClick = (params) => {
    setSelectedAddress(params.row)
  };

  const handleCloseRibbon = (selectedRow) => {
    setSelectedAddress(null);    
  };

  const handleResolveOnCLick = () => {
    handleCloseRibbon(null)
    setShowUpateConfimation(true)
  }

  
  return (
    <Grid container>
      <ToastContainer />
      <CSVUploadErrorDialog
        showErrorUploadCsv={showErrorUploadCsv}
        failedRows={processFailedCSVRows}
        onClose={hideCSVUploadErrorDialog}
      />
      <Grid
        item
        sm={12}
        container
        justifyContent="flex-start"
        sx={{ marginBottom: 2 }}
      >
        <Box p="5">
          <Button
            component="label"
            variant="outlined"
            startIcon={<CloudUpload />}
            sx={{ marginRight: "1rem" }}
          >
            Upload CSV
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={handleFileUpload}
              onClick={(e) => (e.target.value = null)}
            />
          </Button>
        </Box>
      </Grid>
      <Grid>
        <ResolveConfirmDialog 
          showUpateConfimation ={showUpateConfimation} 
          handleCloseConfimation = {handleCloseConfimation} 
          masDrivers ={masDrivers}
          selectedDriver={selectedDriver}
          setSelectedDriver={setSelectedDriver}
        />
      </Grid>  
      <Grid container>
        <Box sx={{ width: '100%'}}>
            {selectedAddress && (
              <LocationEditRibbon selectedRow={selectedAddress} onClose={handleCloseRibbon} />
            )}
        </Box>
        <Box sx={{ height: selectedAddress ? '400px !important' : 'auto', width: '100%', overflow: 'auto' }}>
          <ClickAwayListener onClickAway={() => setRowSelectionModel([])}>
            <PlannerDataGrid
              rows={rows}
              className={classes.root}
              columns={columns}
              sx={datagridSx}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              slots={{
                loadingOverlay: LinearProgress,
                toolbar: CustomToolbar,
              }}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[25]}
              autoHeight
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              getRowHeight={() => "auto"}
              getRowClassName={(params) =>
                `planner-view-grid--O-${
                  params.row?.isStandingOrderTrip === 0 ||
                  params.row?.isStandingOrderTrip === false
                    ? 0
                    : params.row?.isNoneMainFacilityTrip === 1
                    ? 3
                    : 2
                }`
              }
            />
          </ClickAwayListener>
        </Box>
      </Grid>
      {rows?.length > 0 && (
        <Grid item sm={12} className={"attestations_container_button"}>
          <Button variant="contained" onClick={updateRows}>
            Save
          </Button>
          <Button variant="contained" onClick={handleResolveOnCLick}>
            Resolve
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AttestationsUploadView;
//