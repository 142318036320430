import { Accordion, AccordionDetails, AccordionSummary,Grid, Typography } from "@mui/material";
import React, {useState, useEffect,} from "react";
import { StyledButton} from "../../../styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";

import DriverProfileView from "./DriverProfileView";
import GeneralDetails from "./GeneralDetails";
import CredentialsView from "./CredentialsView";
import AddressDetailView from "./AddressDetailView";
import LicenceDetailView from "./LicenceDetailView";
import SpecialConfigsView from "./SpecialConfigsView";



const config = require("../../../config")
const staticURL = config.BASE_URL;


var driver = {
    firstName: "",
    lastName: "",
    dateOfJoin: moment(new Date()).format("yyyy-MM-dd"),
    email:"",
    mobile:"",
    active:true,
    userName:"",
    password:"",
    resetPassword:false,
    confirmPassword:"",
    address: "",
    lat: "",
    lng: "",
    licenseNo: "",
    vehicleLicense:"",
    vehicle_id:"",
    provider:"0",
    providerId:"",
    expieryDate:moment(new Date()).format("yyyy-MM-dd"),
    counties: [],
    sendGPS:false,
    dispatchonly:false,
}

const driverProfilePicture = {
    profilePicture: "",
    signatureList: ["","","",""],
    profilePictureContent: null,
    signatureListContent: [],    
}

function CreateEditDriver({handleSave, driverDetails,action}){
    const [expanded, setExpanded] = useState('panel1');
    const [values, setValues] = useState(driver);
    const [profilePictureData, setProfilePictureData] = useState(driverProfilePicture);
       
    useEffect(()=>{
        if(driverDetails != null){
            setValues({
                id:driverDetails.id,
                firstName: driverDetails.firstName,
                lastName: driverDetails.lastName,
                dateOfJoin: driverDetails.dateOfJoin?
                            moment(driverDetails.dateOfJoin).format("YYYY-MM-DD")
                            :moment(driverDetails.createAt).format("YYYY-MM-DD"),
                email:driverDetails.email,
                mobile:driverDetails.mobilePhone,
                active:driverDetails.active,
                licenseNo: driverDetails.licencse,
                vehicleLicense:driverDetails.license,
                provider:"0",
                providerId:driverDetails.masdriverid,
                expieryDate:driverDetails.expieryDate,
                vehicle_id:driverDetails.vehicle_id,
                address: driverDetails.address,
                lat: driverDetails.dispatchLat,
                lng: driverDetails.dispatchLng,
                userName:driverDetails.userName,
                password:driverDetails.password,
                confirmPassword:driverDetails.password,
                resetPassword:driverDetails.pwdreset,
                counties: driverDetails.counties?driverDetails.counties:[],
                sendGPS: driverDetails.sendgps?driverDetails.sendgps:0,
                dispatchonly: driverDetails.dispatchonly?driverDetails.dispatchonly:0,
            });
            let sigList = [];
            for(let i=1;i<=4;i++){
                let signs = driverDetails.signatureList? driverDetails.signatureList.split(","):["","","",""]
                try{
                    sigList.push(signs[i-1]);
                } catch(err){
                    sigList.push("404.jpg");
                }
                
            }
            console.log("SIG LIST ->",sigList, driverDetails.profilePicture)
            setProfilePictureData({
                id:driverDetails.id,
                profilePicture: driverDetails.profilePicture?`${driverDetails.profilePicture}`:"",
                profilePictureContent: driverDetails.profilePictureContent?driverDetails.profilePictureContent.split(","):null,
                signatureList: sigList,
                signatureListContent: driverDetails.signatureListContent?driverDetails.signatureListContent:[],
            })
        }else {
            setValues(driver)
        }
    },[action.action, action.driverid])

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const saveDriver = () =>{
        handleSave(values, profilePictureData, action.action);
    }

    return (
        <div>
           <Grid container>
             <Grid item md={8}>
               <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{
                paddingBottom:2
               }}
               disableGutters={true}
               >
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel1a-header"
                    
                >
                        <Typography>General Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <GeneralDetails 
                        values={values}
                        setValues={setValues}
                        action={action.action}
                    />
                </AccordionDetails>
               </Accordion>
               <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{
                paddingBottom:2
               }}
               disableGutters={true}
               >
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                        <Typography>Credentials</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CredentialsView 
                        values={values}
                        setValues={setValues}
                        action={action.action}
                    />
                </AccordionDetails>
               </Accordion>
               <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{
                paddingBottom:2
               }}
               disableGutters={true}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                        <Typography>Address Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AddressDetailView 
                        values={values}
                        setValues={setValues}
                        action={action.action}
                    />
                </AccordionDetails>
               </Accordion>
               <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{
                paddingBottom:2
               }}
               disableGutters={true}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                        <Typography>Licence Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <LicenceDetailView 
                        values={values}
                        setValues={setValues}
                        action={action.action}
                    />
                </AccordionDetails>
               </Accordion>
               <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{
                paddingBottom:2
               }}
               disableGutters={true}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                        <Typography>Special Configurations </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SpecialConfigsView 
                        values={values}
                        setValues={setValues}
                        action={action.action}
                    />
                </AccordionDetails>
               </Accordion>
             </Grid>
             <Grid item md={4}>
                <DriverProfileView 
                    values={profilePictureData}
                    setValues={setProfilePictureData}
                    action={action.action}
                />
             </Grid>
            
             <Grid item md={12} style={{display:"flex", justifyContent:"end", paddingTop:2}}>
                <StyledButton  variant={"contained"} theme 
                    onClick={()=>{saveDriver()}} > {
                          action.action=="create"?
                            "Create New Driver"
                          : action.action=="edit"? 
                            `Edit Driver`   
                          : "Delete Driver"
                        } 
                </StyledButton>
            </Grid>
           </Grid>  

        </div>
    )
}

export default CreateEditDriver