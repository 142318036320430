export const passWordValidator = (passWord) =>{    
    if(passWord.toLowerCase() == passWord) return false;
    return true;
}

export const matchConfirmPassword = (passWord, confirmPassWord) =>{
    if(!confirmPassWord) return false;
    if(passWord.toLowerCase() != confirmPassWord.toLowerCase()) return false;
    return true;
}


export const mandotoryDriverSetupFieldsValidation = (driver) => {
    let errorBag = []
    if(!driver) errorBag.push("Can not save empty driver information!");
    if(!driver.firstName) errorBag.push("Please enter drivers' first Name !");
    if(!driver.lastName) errorBag.push("Please enter drivers' last Name !");
    if(!driver.email) errorBag.push("Please enter drivers' valid email !");
    if(!driver.mobile) errorBag.push("Please enter drivers' valid mobile number !");
    if(!driver.userName) errorBag.push("Please enter drivers' valid userName !");
    if(!driver.password) errorBag.push("Please enter drivers' valid password !");
    if(!driver.address) errorBag.push("Please enter drivers' valid address !");
    if(!driver.provider) errorBag.push("Please select drivers' provider !");
    return errorBag;
}