
const axios  = require('axios');
const config = require("../config")

const BASE_URL = `${config.BASE_URL}/driveruser`;

async function getDriverList(user){
    return new Promise(async (resolve, reject) =>{
        try{
        console.log(`${BASE_URL}/all`)
        let response = await axios.get(`${BASE_URL}/all`,
           {headers: {
            "x-auth-key": user.key,
          }},)
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

async function getDriverDetails(driverId){
    return new Promise(async (resolve, reject) =>{
        try{
        let response = await axios.get(`${BASE_URL}/driver-details/${driverId}`)
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

async function createDriver(driver){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.post(BASE_URL, driver);
            if (response.status === 201)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

async function deleteDriver(driver){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.delete(`${BASE_URL}/${driver.id}`);
            if (response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}


async function updateDriver(driver){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.put(`${BASE_URL}/${driver.id}`, driver);
            if (response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

async function uploadProfilePictures(form) {
    return new Promise(async(resolve, reject) =>{
        try{
            let response = await axios.post(`${BASE_URL}/upload_profile`, form, {
                headers: {
                    "content-type":"multipart/form-data"
                }
            });
            console.log(response);
            if (response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

async function uploadSignaturePictures(form) {
    return new Promise(async(resolve, reject) =>{
        try{
            let response = await axios.post(`${BASE_URL}/upload_signatures`, form, {
                headers: {
                    "content-type":"multipart/form-data"
                }
            });
            console.log(response);
            if (response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

async function getVehicles(user){
    return new Promise(async (resolve, reject) =>{
        try{
        console.log(`${BASE_URL}/vehicles/all`)
        let response = await axios.get(`${BASE_URL}/vehicles/all`,
           {headers: {
            "x-auth-key": user.key,
          }},)
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

export {createDriver, deleteDriver, getDriverList, getDriverDetails, updateDriver, uploadProfilePictures, uploadSignaturePictures, getVehicles}