import * as React from "react";
import Box from "@mui/material/Box";
import UserLevelSearch from "./UserLevelSearch";

const preventDefault = (event) => event.preventDefault();

export default function UserLevelFilter({ setFilter }) {

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",
        gap: "20px",
      }}
      onClick={preventDefault}
    >
      <UserLevelSearch setFilter={setFilter}/>
    </Box>
  );
}
