import React, { Component } from "react";

import axios from "axios";
import { BASE_URL } from "../../../config";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
// import moment from "moment";
import moment from "moment-timezone";

import Button from "@mui/material/Button";

import "react-toastify/dist/ReactToastify.css";

import { Card, Autocomplete, TextField, Alert } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs, { Dayjs } from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
function RosterDisplayOne({
  tablerows,
  setSearchOrder,
  fetchTripRoster,
  viewRosterPdf,
  user,
}) {
  return (
    <Table
      sx={{ minWidth: 650, fontSize: 12 }}
      aria-label="simple table"
      className="trip-results-data-table"
    >
      <TableHead>
        <TableRow
          sx={{
            display: {
              xl: "table-row",
              md: "table-row",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                title="InvoiceNumber ascending"
                onClick={() => {
                  setSearchOrder("invoiceNumber", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                title="InvoiceNumber descending"
                onClick={() => {
                  setSearchOrder("invoiceNumber", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Invoice PA#</div>
          </TableCell>
          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("status", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("status", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Status </div>{" "}
          </TableCell>
          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("medicaidNumber", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("medicaidNumber", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">CIN # </div>{" "}
          </TableCell>
          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("firstName", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("firstName", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Recipient</div>{" "}
          </TableCell>
          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("gender", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("gender", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Sex</div>{" "}
          </TableCell>
          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("DOB", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("DOB", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">DOB</div>{" "}
          </TableCell>
          {user.userlevel == 1 ? (
            <TableCell className="trip-results-head-cell-fone">
              <div class="sort-wrapper">
                <div
                  class="sort-wrapper-up"
                  onClick={() => {
                    setSearchOrder("totalTripCost", "ASC");
                  }}
                >
                  <ArrowDropUpIcon />{" "}
                </div>
                <div
                  class="sort-wrapper-down"
                  onClick={() => {
                    setSearchOrder("totalTripCost", "DESC");
                  }}
                >
                  {" "}
                  <ArrowDropDownIcon />{" "}
                </div>
              </div>
              <div class="sortable-title">Trip Cost</div>{" "}
            </TableCell>
          ) : null}
          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("calculatedTripMileage", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("calculatedTripMileage", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Calc Milage</div>{" "}
          </TableCell>

          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("transportType", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("transportType", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Transport Type</div>{" "}
          </TableCell>

          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("pickupDate", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("pickupDate", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Trip Date</div>{" "}
          </TableCell>

          <TableCell className="trip-results-head-cell-fone">
            Trip Legs
          </TableCell>
          <TableCell className="trip-results-head-cell-fone">County</TableCell>
          <TableCell className="trip-results-head-cell-fone">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  setSearchOrder("originalCreateddate", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  setSearchOrder("originalCreateddate", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Created Date</div>
          </TableCell>

          <TableCell className="trip-results-head-cell-fone">Action </TableCell>
        </TableRow>

        <TableRow
          sx={{
            display: {
              xl: "none",
              md: "none",
              sm: "table-row",
              xs: "table-row",
            },
          }}
        >
          <TableCell className="grid-header-mobile-wrapper" colSpan={13}>
            <Grid container>
              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">Invoice PA#</div>

                {/* <div class="sort-wrapper"  sx={{ display: { xl: 'block',md: 'block', sm: 'none', xs: 'none' } }}  >
                                      <div
                                        class="sort-wrapper-up"
                                        title="InvoiceNumber ascending"
                                        onClick={() => {
                                          setSearchOrder(
                                            "invoiceNumber",
                                            "ASC"
                                          );
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        title="InvoiceNumber descending"
                                        onClick={() => {
                                          setSearchOrder(
                                            "invoiceNumber",
                                            "DESC"
                                          );
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>
              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">Status </div>{" "}
                {/* <div class="sort-wrapper"  sx={{ display: { xl: 'block',md: 'block', sm: 'none', xs: 'none' } }}   >
                                      <div
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder("status", "ASC");
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder("status", "DESC");
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>
              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">Medicaid # </div>{" "}
                {/* <div class="sort-wrapper"  sx={{ display: { xl: 'block',md: 'block', sm: 'none', xs: 'none' } }}   >
                                      <div
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder(
                                            "medicaidNumber",
                                            "ASC"
                                          );
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder(
                                            "medicaidNumber",
                                            "DESC"
                                          );
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>
              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">Recipient</div>{" "}
                {/* <div class="sort-wrapper"  sx={{ display: { xl: 'block',md: 'block', sm: 'none', xs: 'none' } }}  >
                                      <div
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder(
                                            "firstName",
                                            "ASC"
                                          );
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder(
                                            "firstName",
                                            "DESC"
                                          );
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>
              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">Sex</div>{" "}
                {/* <div class="sort-wrapper"  sx={{ display: { xl: 'block',md: 'block', sm: 'none', xs: 'none' } }}  >
                                      <div
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder("gender", "ASC");
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder("gender", "DESC");
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>
              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">DOB</div>{" "}
                {/* <div class="sort-wrapper"  sx={{ display: { xl: 'block',md: 'block', sm: 'none', xs: 'none' } }}   >
                                      <div
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder("DOB", "ASC");
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder("DOB", "DESC");
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>
              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">Trip Cost</div>{" "}
                {/* <div class="sort-wrapper">
                                      <div
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder(
                                            "totalTripCost",
                                            "ASC"
                                          );
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder(
                                            "totalTripCost",
                                            "DESC"
                                          );
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>

              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">Calc Milage</div>{" "}
                {/* <div class="sort-wrapper"  sx={{ display: { xl: 'block',md: 'block', sm: 'none', xs: 'none' } }}   >
                                      <div
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder(
                                            "calculatedTripMileage",
                                            "ASC"
                                          );
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder(
                                            "calculatedTripMileage",
                                            "DESC"
                                          );
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>

              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div class="sortable-title-mobile">Transport Type</div>{" "}
                {/* <div class="sort-wrapper"  sx={{ display: { xl: 'block',md: 'block', sm: 'none', xs: 'none' } }}   >
                                      <div
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder(
                                            "transportType",
                                            "ASC"
                                          );
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder(
                                            "transportType",
                                            "DESC"
                                          );
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </div> */}
              </Grid>

              <Grid item xs={4} sm={3} className="trip-results-head-cell-fone">
                <div item class="sortable-title-mobile">
                  Trip Date / Mode
                </div>{" "}
                {/* <Box component="div" 	sx={{ display: 'none' }}     item class="sort-wrapper aa-b"   >
                                      <div item
                                        class="sort-wrapper-up"
                                        onClick={() => {
                                          setSearchOrder(
                                            "pickupDate",
                                            "ASC"
                                          );
                                        }}
                                      >
                                        <ArrowDropUpIcon />{" "}
                                      </div>
                                      <div
                                        class="sort-wrapper-down"
                                        onClick={() => {
                                          setSearchOrder(
                                            "pickupDate",
                                            "DESC"
                                          );
                                        }}
                                      >
                                        {" "}
                                        <ArrowDropDownIcon />{" "}
                                      </div>
                                    </Box> */}
              </Grid>

              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone sortable-title-mobile"
              >
                Trip Legs
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone sortable-title-mobile"
              >
                County
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone sortable-title-mobile"
              >
                Created date
              </Grid>

              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone sortable-title-mobile"
              >
                Action{" "}
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tablerows.data.map((row) => (
          <>
            <TableRow
              key={row.name}
              sx={{
                display: {
                  xl: "table-row",
                  md: "table-row",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <TableCell align="right" className="trip-results-data-cell-fone">
                {row.invoiceNumber}
                <div>
                  {" "}
                  {row.exAcpriorAuthorizationNumber != null &&
                  row.exAcpriorAuthorizationNumber !== ""
                    ? " | " + row.exAcpriorAuthorizationNumber
                    : ""}
                </div>
                <div>
                  {" "}
                  {row.standingOrderEndDate != null &&
                  row.standingOrderEndDate !== ""
                    ? " | " +
                      moment(row.standingOrderEndDate).format("MM/DD/YYYY")
                    : ""}
                </div>
                {row.exAcattested === 1 &&
                (row.exAcpriorAuthorizationNumber === "" ||
                  row.exAcpriorAuthorizationNumber == null) ? (
                  <>
                    <Alert severity="warning">PA#</Alert>
                  </>
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell align="right" className="trip-results-data-cell-fone">
                {row.status}
              </TableCell>

              <TableCell align="right" className="trip-results-data-cell-fone">
                {row.medicaidNumber}
              </TableCell>

              <TableCell align="right" className="trip-results-data-cell-fone">
                {row.firstName} {row.lastName}
              </TableCell>
              <TableCell align="right" className="trip-results-data-cell-fone">
                <div> {row.gender} </div>
              </TableCell>
              <TableCell align="right" className="trip-results-data-cell-fone">
                <div> {moment(row.DOB).format("MM/DD/YYYY")} </div>
              </TableCell>
              {user.userlevel == 1 ? (
                <TableCell
                  align="right"
                  className="trip-results-data-cell-fone"
                >
                  $ {parseFloat(row.totalTripCost).toFixed(2)}
                </TableCell>
              ) : null}
              <TableCell align="right" className="trip-results-data-cell-fone">
                {parseFloat(row.calculatedTripMileage).toFixed(2)}
              </TableCell>

              <TableCell align="right" className="trip-results-data-cell-fone">
                {row.transportType}
              </TableCell>

              <TableCell align="right" className="trip-results-data-cell-fone">
                {moment(row.pickupDate).format("MM/DD/YYYY")} -{" "}
                {moment(row.pickupTime, "HH:mm").format("hh:mm A")}
              </TableCell>
              <TableCell align="right" className="trip-results-data-cell-fone">
                {row.triplegs.length}
              </TableCell>
              <TableCell align="right" className="trip-results-data-cell-fone">
                {row.medicaidCountyName}
              </TableCell>
              <TableCell align="right" className="trip-results-data-cell-fone">
                {`${row.originalCreateddate.split("T")[0]} ${moment(row.originalCreateddate.split("T")[1], "HH:mm").format("hh:mm A")} `} 
               
              </TableCell>

              <TableCell align="right" className="trip-results-data-cell-fone">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    fetchTripRoster(row.invoiceNumber);
                  }}
                >
                  View
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    viewRosterPdf(row.invoiceNumber);
                  }}
                >
                  PDF
                </Button>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                display: {
                  xl: "none",
                  md: "none",
                  sm: "table-row",
                  xs: "table-row",
                },
              }}
            >
              <TableCell
                className="grid-result-wrapper-cell-phone"
                colSpan={13}
              >
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {row.invoiceNumber}{" "}
                    {row.exAcpriorAuthorizationNumber != null &&
                    row.exAcpriorAuthorizationNumber !== ""
                      ? " | " + row.exAcpriorAuthorizationNumber
                      : ""}
                    {row.standingOrderEndDate != null &&
                    row.standingOrderEndDate !== ""
                      ? " | " +
                        moment(row.standingOrderEndDate).format("MM/DD/YYYY")
                      : ""}
                    {row.exAcattested === 1 &&
                    (row.exAcpriorAuthorizationNumber === "" ||
                      row.exAcpriorAuthorizationNumber == null) ? (
                      <>
                        <Alert severity="warning">PA#</Alert>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {row.status}
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {row.medicaidNumber}
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {row.firstName} {row.lastName}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    <div> {row.gender} </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    <div> {moment(row.DOB).format("MM/DD/YYYY")} </div>
                  </Grid>
                  {user.userlevel == 1 ? (
                    <Grid
                      item
                      xs={4}
                      sm={3}
                      align="right"
                      className="trip-results-data-cell-fone"
                    >
                      $ {parseFloat(row.totalTripCost).toFixed(2)}
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {parseFloat(row.calculatedTripMileage).toFixed(2)}
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {row.transportType}
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {moment(row.pickupDate).format("MM/DD/YYYY")} -{" "}
                    {moment(row.pickupTime, "HH:mm").format("hh:mm A")}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {row.triplegs.length}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    {row.medicaidCountyName}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                   {`${row.originalCreateddate.split("T")[0]} ${moment(row.originalCreateddate.split("T")[1], "HH:mm").format("hh:mm A")} `} 
                    {/* {moment(row.originalCreateddate).tz("America/New_York").format(
                      "MM/DD/YYYY hh:mm a"
                    )} */}
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sm={3}
                    align="right"
                    className="trip-results-data-cell-fone"
                  >
                    <Button
                      variant="contained"
                      className="mobile-button-a"
                      size="small"
                      onClick={() => {
                        fetchTripRoster(row.invoiceNumber);
                      }}
                    >
                      View
                    </Button>
                    &nbsp; | &nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      className="mobile-button-a"
                      onClick={() => {
                        viewRosterPdf(row.invoiceNumber);
                      }}
                    >
                      PDF
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
  );
}
export default RosterDisplayOne;
