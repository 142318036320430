import { React, useState, useEffect, useCallback } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import HeaderActions from "./HeaderActions";

import { attestationList } from "./RosterPlanner";

import "./roster_planning_styles.css";
import RosterPlanView from "./RosterPlanView";
import RosterScheduleView from "./RosterScheduleView";
import SearchRoasterPlan from "./SearchRoasterPlan";
import AttestInvoiceView from "../Attestations/AttestInvoiceView";

const RosterPlanController = () => {
  const [notify, seNotify] = useState(null);
  const [uploadView, setUploadView] = useState(true);
  const [searchAttrs, setSearchAttrs] = useState({});

  const [selectedView, setSelectedView] = useState("planning");

  useEffect(async () => {
    attestationList.registerNotifyAll(seNotify);
    await attestationList.loadAttestations();
  }, []);

  useEffect(() => {
    setUploadView(true);
    attestationList.registerNotifyAll(seNotify);
  }, [notify]);

  const onUploadCSV = useCallback(() => {
    setUploadView(true);
  }, []);

  const onSuccess = useCallback(() => {
    // setUploadView(false);
  }, []);

  const handleOnSearch = useCallback(
    (attr) => {
      setSearchAttrs(attr);
      // setUploadView(false);
    },
    [searchAttrs]
  );

  const handleOnChangeView = useCallback((event, newValue) => {
    setSelectedView(newValue);
    setUploadView(newValue === "planning");
  }, []);

  return (
    <>
      <Grid container className={"attestations_container"}>
        <Grid item sm={12}>
          <SearchRoasterPlan handleOnSearch={handleOnSearch} uploadView={uploadView}/>
        </Grid>
        <Grid item md={12} xs={12} lg={12} p={0}>
            <br></br>
        </Grid> 
        <Grid item sm={12} sx={{ marginBottom: 2 }}>
          <Tabs value={selectedView} onChange={handleOnChangeView}>
            <Tab value="planning" label="Planning" />
            <Tab value="scheduling" label="Scheduling" />
          </Tabs>
        </Grid>

        <Grid item sm={12}>
          {!uploadView && (
            <HeaderActions csv={true} onUploadCSV={onUploadCSV} />
          )}
        </Grid>
        <Grid item sm={12}>
          {!uploadView && selectedView!=="attestation" && <RosterScheduleView searchAttrs={searchAttrs} />}
          {uploadView && selectedView!=="attestation"  &&(
            <RosterPlanView
              onSuccess={onSuccess}
              searchAttrs={searchAttrs}
            />
          )}
        </Grid>
        <Grid item sm={12}>
         
        </Grid>
      </Grid>
    </>
  );
};

export default RosterPlanController;
