import { React, useState, useEffect, useCallback, useMemo, useContext } from "react";

import moment from "moment";
import { createStyles, makeStyles } from "@mui/styles";
import { darken, lighten, styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SelfIcon from "@mui/icons-material/RunCircleRounded";
import LockIcon from "@mui/icons-material/LockRounded";
import SaveIcon from "@mui/icons-material/SaveRounded";
import SendIcon from '@mui/icons-material/Send';


import RefdataContext from "../../../context/RefdataContext";

import {
    ClickAwayListener,
    LinearProgress,
    IconButton,
    Box,
  } from "@mui/material";

  import { ToastContainer, toast } from "react-toastify";


import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GRID_CHECKBOX_SELECTION_COL_DEF,
  } from "@mui/x-data-grid";
import { StyledButton } from "../../../styled";
import DispatchNotification from "./DispatchNotification";
import { sendDispatchNotification } from "../../../api/DispatchApi";

/* DATA GRID STYLES */
const datagridSx = {
    borderRadius: 2,
    "& .MuiDataGrid-main": { borderRadius: 2 },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#f5fafe",
      color: "#033d5f",
      fontWeight: "bold !important",
      fontSize: 14,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold !important",
      color: "#033d5f !important",
      overflow: "visible",
      lineHeight: "1.0rem",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-cell": {
      paddingTop: 1,
      paddingBottom: 1,
    },
  };
  
  const useStyles = makeStyles((theme) => 
    createStyles({
      root: {
        backgroundColor: theme.palette.red,
      },
      highlighted: {
        color: theme.palette.highlight.main,
        fontWeight:"bold"
      },
    }));
  
  

  const getBackgroundColor = (color, mode) =>
    {
      return (mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7));
    }
  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.9);
  
  const getSelectedBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.9);
  
  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.4) : lighten(color, 0.9);

const StyledGrid = styled(DataGrid)(({theme}) =>({
    "& .planner-view-grid--O-0": {
        backgroundColor: getBackgroundColor(
          theme.palette.background.default,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            theme.palette.neutral.light,
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            theme.palette.neutral.main,
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              theme.palette.neutral.light,
              theme.palette.mode
            ),
          },
        },
       
      },
      "& highlighted": {
        color:theme.palette.error
      }
}))

function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
        <GridToolbarExport
          csvOptions={{
            allColumns: true,
            fileName: `${moment(new Date()).format(
              "YYYY-MM-DD HH:mm:ss"
            )}_mas_roster`,
          }}
        />
      </GridToolbarContainer>
    );
  }

function DispatchGrid({
    rows,
    selectedIds,
    setSelectedIds,
    setRows,
    saveRow,
    loading,
}) {
    
    const classes = useStyles();

    // const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [drivers, setDriverList] = useState([])

    // Dispath Notification related variables
    const [openDNPopup, setOpenDNPopup] = useState(false);
    const [notificationData, setNotificationData] = useState({});

    const {driverList} = useContext(RefdataContext)

    useEffect(()=>{
      let _driverList = driverList.filter(dr=>dr.active == 1)
      setDriverList(_driverList.sort());
    },[])


      function handleSaveRow(row){
        saveRow(row);
      }
      
      const handleOpenDNPopup = (row) => {
        const driver = drivers.find((dr)=>{
          return(dr.firstName?.toLowerCase().replaceAll(" ","") == row.driverName?.toLowerCase().replaceAll(" ",""))
        })     
        setNotificationData({...row, driverInfo: driver})
        setOpenDNPopup(true);
      };
    
      const handleCloseDNPopup = () => {
        setOpenDNPopup(false);
      };
    
      const handleConfirmDNPopup = async(data) => {
        const user = await JSON.parse(await localStorage.getItem("session"));
        console.log('Confirmed: ', data, '  user', user);

        try{
          let response = await sendDispatchNotification({...data, userId: user.id, userName: user.userName});

          toast.success(`Notification sent successfully`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setOpenDNPopup(false);
        } catch (error) {
          toast.error(`[Error] ${error.message || 'Notification Sending failed. Please try again later'}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }        
      };
    
      const handleCancelDNPopup = () => {
        console.log('Cancelled');
        setOpenDNPopup(false);
      };
      
      function handleSelfDriver(id) {
        setRows((prevRows) => {
          return prevRows.map((row, index) =>
            {
              return(row.id == id ? { ...row, self: !row.self }: row)
            }
          );
        });
      }

      function handleLockDriver(id){
        setRows((prevRows) => {
          return prevRows.map((row, index) =>
            {
              return(row.id == id ? { ...row, locked: !row.locked }: row)
            }
          );
        });
      }


      const processRowUpdate = useCallback(
              (newRow) => {
              const index = rows.findIndex((row) => row.id === newRow.id);
              const tempRows = [...rows];
              tempRows[index] = newRow;
              setRows(tempRows);
          
              return newRow;
              },
              [rows]
          );

        const handleProcessRowUpdateError = useCallback((error) => {
            toast.error(`[Error] ${error.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }, []);        
        
        const columns = useMemo(
            () => [
            
            {
                field: "invoiceNumber",
                headerName: "Invoice Number",
                width: 120,
                editable: false,
                sortable: true,
                
            },
            {
                field: "tripLegSequence",
                headerName: "Leg",
                type: "number",
                width: 10,
                editable: false,
                sortable: false,
                align: "center",
                headerAlign: "center",
            },
            {
                field: "pickupDate",
                headerName: "Date",
                type: "number",
                width: 120,
                editable: false,
                sortable: true,
                align: "center",
                headerAlign: "center",
            },
            {
                field: "customerName",
                headerName: "Customer / Medicaid Number",
                width: 150,
                editable: false,
                sortable: true,
                renderCell: (params) => {
                  return (
                          
                          <div style={{ paddingTop: 4, paddingBottom: 4, borderBottom:1 }}>
                              <div style={{ marginBottom: 5 }}>
                                { params.row.customerName} 
                              </div>
                              <div style={{ marginBottom: 5 }}>
                                { params.row.medicaidNumber} 
                              </div>
                          </div>
                  );
                  },
            },
            {
                headerName: "Pickup ",
                field: "pickaddress",
                sortable: false,
                editable: true,
                width: 300,
                renderCell: (params) => {
                return (
                        
                        <div style={{ paddingTop: 4, paddingBottom: 4, borderBottom:1 }}>
                            <div style={{ marginBottom: 5 }}>
                            { params.row.pickaddress}
                            </div>
                        </div>
                );
                },
            },
            {
                headerName: "Dropoff",
                field: "dropoffAddress",
                sortable: false,
                editable: false,
                width: 300,
                renderCell: (params) => {
                return (
                        
                        <div style={{ paddingTop: 4, paddingBottom: 4, borderBottom:1 }}>
                            <div>
                            { params.row.dropoffAddress}
                            </div>
                        </div>
                );
                },
            },
            {
                field: "groupKeyMASPickupTime",
                headerName: "MAS Pickup",
                align: "center",
                sortable: true,
                editable: false,
                width: 80,
                renderCell: (params) =>{
                  return (
                      <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                      <div style={{ marginBottom: 5 }}>
                          {params.row.masPickup}
                      </div>
                      </div>
                  )
                },
                // sortComparator: (v1, v2) => {
                //   console.log("V! - V2 -> ",v1, v2)
                //   return(v1 > v2)
                // }
            },
            {
                field: "actPickUp",
                headerName: "Act. Pickup",
                align: "center",
                sortable: true,
                editable: true,
                width: 80,
                renderCell: (params) =>{
                return (
                  params.row.actPickUp == params.row.masPickup?
                    <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <div style={{ marginBottom: 5 }}>
                            {params.row.actPickUp}
                        </div>
                    </div>
                    :
                    <div style={{ paddingTop: 4, paddingBottom: 4}} className={classes.highlighted}>
                        <div style={{ marginBottom: 5 }}>
                            {params.row.actPickUp}
                        </div>
                    </div>
                )
                }
            },
            {
                field: "duration",
                headerName: "Distance",
                sortable: true,
                editable: false,
                width: 80,
                type: "action",
                renderCell: (params) =>{
                let duration = `${params.row.duration==null?'N/A':Number(params.row.duration)+' miles'} `;
                return (
                    <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                    {`${duration}`}
                    </div>
                )
                }
            },
            {
                field: "driverName",
                headerName: "Driver",
                sortable: true,
                editable: true,
                width: 200,
                type: "singleSelect",
                renderCell: (params) =>{
                const driver = !params.row.driverName?{}:drivers.find((dr)=>{
                    return(dr.firstName?.toLowerCase().replaceAll(" ","") == params.row.driverName?.toLowerCase().replaceAll(" ",""))
                })          
                return (
                    <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                        disabled={params.row.self?true:false}
                      >
                      < div>{driver?.firstName} - {driver?.license}</div>
                      <ArrowDropDownIcon />
                    </Box>
                )
                },
                valueOptions: [
                ...new Set(drivers.map((option) => ({value:`${option.firstName}`, label:`${option.firstName} - ${option.masdriverid}`}))),
                ],
            },
            {
              field: "scheduleorder",
              headerName: "Order",
              headerAlign:"center",
              sortable: true,
              editable: true,
              width: 10,
              align:"center",
              type: "action",
          },
            {
                field: "status",
                headerName: "Status",
                sortable: true,
                editable: false,
                width: 80,
                type: "action",
            },
            {
                headerName: "Assign",
                field: "actions",
                type: "actions",
                align: "center",
                width: 160,
                height:100,
                renderCell: (params) => {
                return (
                    <div style={{ paddingTop: 4, paddingBottom: 4,  display:"flex", textAlign:"center"}}>
                        <div style={{ marginLeft: 2 }}>
                          <IconButton variant="contained"
                                  size="small"
                                  color={ params.row?.self?"secondary":"primary"}
                                  style={{ marginLeft: 2 }}
                                  tabIndex={params.hasFocus ? 0 : -1}
                                  onClick={(e)=>{handleSelfDriver(params.row.id)}}>
                                    <SelfIcon color={ params.row?.self?"secondary":"primary"}/>
                            </IconButton>
                            <IconButton variant="contained"
                                  size="small"
                                  color={ params.row?.locked?"secondary":"primary"}
                                  style={{ marginLeft: 2 }}
                                  tabIndex={params.hasFocus ? 0 : -1}
                                  onClick={(e)=>{handleLockDriver(params.row.id)}}>
                                    <LockIcon ccolor={ params.row?.locked?"secondary":"primary"}/>
                            </IconButton>
                            <IconButton variant="contained"
                                  size="small"
                                  color={ params.row?.scheduled?"secondary":"primary"}
                                  style={{ marginLeft: 2 }}
                                  tabIndex={params.hasFocus ? 0 : -1}
                                  // disabled={params.row.diff < 0 || params.row.driverName == null?true:false}
                                  onClick={(e)=>{handleSaveRow(params.row)}}>
                                    <SaveIcon color={ params.row?.scheduled?"secondary":"primary"}/>
                            </IconButton>
                            <IconButton variant="contained"
                                  size="small"
                                  color={ "primary"}
                                  style={{ marginLeft: 2 }}
                                  tabIndex={params.hasFocus ? 0 : -1}
                                  onClick={(e)=>{handleOpenDNPopup(params.row)}}>
                                    <SendIcon color={ "primary"}/>
                            </IconButton>                            
                        </div>
                    </div>
                );
                },
            },
            ],
            [drivers]
        );


        return(
          <>
            <ClickAwayListener onClickAway={() => setRowSelectionModel([])}>
                    <StyledGrid
                        rowHeight={50}
                        rows={rows}
                        columns={columns}
                        sx={datagridSx}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                            // setSelectedIds(new Set(newRowSelectionModel));
                        }}
                        rowSelectionModel={rowSelectionModel}
                        slots={{
                            loadingOverlay: LinearProgress,
                            toolbar: CustomToolbar,
                        }}
                        loading={loading}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[25, 50, 100]}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        getRowClassName={(params) =>
                            `planner-view-grid--O-0`
                        }
                        getRowHeight={() => "auto"}
                        // isRowSelectable={(params) =>{
                        //   console.log("ROW -> ",params.row);
                        //     return (params.row?.diff < 0?false:true)
                        // }}
                    />
            </ClickAwayListener>
            <DispatchNotification
              open={openDNPopup}
              onClose={handleCloseDNPopup}
              onConfirm={handleConfirmDNPopup}
              onCancel={handleCancelDNPopup}
              notificationData={notificationData}
            />
        </>
            )
    }

    export default DispatchGrid;