import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  StyledFormControl,
  StyledFrame,
  StyledInputText,
  StyledMenuItem,
  StyledSelect,
} from "../../../styled";

import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";
import { toast } from 'react-toastify';
import { getMasAddressInfo } from "../../../api/CustomerManagementApi";

const config = require("../../../config");

const providers = [
  { id: "01", name: "MAS" },
  { id: "02", name: "Internal" },
  { id: "03", name: "EdiCare" }
];

function MedicaidInformation({ values, setValues }) {

  const typingTimeoutRef = useRef(null);
  const [initLoadingComplete, setInitLoadingComplete] = useState(false)

  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
    
  }

  const handleChangeCinNo = (event) => {
    handleChange(event)
    const newValue = event.target.value;
    
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    
    typingTimeoutRef.current = setTimeout(() => {
      if (newValue.trim()) {
        loadMasAddressDetails(newValue, true)
      }
    }, 500);
  };

  const loadMasAddressDetails = useCallback(async (cinno, fromSearch) => {
    setInitLoadingComplete(true)

    try {      
      const response = await getMasAddressInfo(cinno)
      
      setValues({
        ...values,
        ['cinno']: cinno,
        ['medicaidAddress']: response.address,
        ['city']: response.city,
        ['zip']: response.zip,
        ['county']: response.county,
        ['state']: response.state,
        ['firstname']: response.firstname,
        ['lastname']: response.lastname,
        ['dateOfBirth']: response.dateOfBirth,
        ['contactnumber']: response.contactnumber,

      });

      if(fromSearch) {
        toast.success("Information found for the entered Medicaid ID", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log('[Error] : ', error);

      setValues({
        ...values,
        ['cinno']: cinno,
        ['medicaidAddress']: '',
        ['city']: '',
        ['zip']: '',
        ['county']: '',
        ['state']: ''

      });

      toast.error("No information found for the entered Medicaid ID", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [])

  useEffect(() => {
    if(initLoadingComplete == false && values.cinno && (!values.medicaidAddress || values.medicaidAddress.length === 0)) {
      loadMasAddressDetails(values.cinno, false)
    }
  }, [values.cinno, initLoadingComplete])

  

  return (
    <StyledFrame>
      <Grid container>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={4}>
              <StyledFormControl variant="standard">
                <StyledFormInputLabel primary={false}>
                  Provider
                </StyledFormInputLabel>
                <StyledSelect
                  name="provider"
                  value={values.provider}
                  defaultValue={values.provider}
                  onChange={(e) => handleChange(e)} disabled
                >
                  {providers?.map((c, idx) => {
                    return (
                      <StyledMenuItem key={idx} value={c.id}>
                        {c.name}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </StyledFormControl>
            </Grid>
            <Grid item md={4}>
              <StyledFormControl variant="standard">
                <StyledFormInputLabel primary={false}>
                  Provider ID
                </StyledFormInputLabel>
                <StyledInputText
                  name="providerId"
                  value={values.providerId}
                  onChange={(e) => handleChange(e)} disabled
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={4}>
              <StyledFormControl variant="standard">
                <StyledFormInputLabel primary={false}>
                  Medicaid ID
                </StyledFormInputLabel>
                <StyledInputText
                  name="cinno"
                  value={values.cinno}
                  onChange={(e) => handleChangeCinNo(e)} disabled={values.id ? true : false}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledFrame>
  );
}

export default MedicaidInformation;
