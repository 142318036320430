import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Grid,
  LinearProgress,
  Button,
} from "@mui/material";
import { StyledFrame } from "../../../styled";
import NotificationManagementSummaryHeader from "./NotificationManagementSummaryHeader";
import { useEffect, useState } from "react";
import { getDriverList } from "../../../api/DriverManagementApi";
import SearchBy from "./SearchBy";
import moment from "moment";
import { getNotificationData } from "../../../api/NotificationManagementApi";
import NotificationView from "./NotificationView";

const tableSx = {
  "& .MuiTableCell-head": {
    backgroundColor: "#f5fafe",
    color: "#033d5f",
    fontWeight: "bold !important",
    fontSize: 14,
  }
};

const NotificationManagementSummary = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [reload, setReload] = useState(true);
  const [isNotificationInfoOpen, setNotificationInfoOpen] = useState(false);
  const [filteredDrivers, setFilteredDrivers] = useState([]);

  const [search, setSearch] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [loading, setLoading] = useState([]);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRowClick = (rowIndex) => {
    setSelectedRow(rowIndex);
    setNotificationInfoOpen(true);
  };

  useEffect(() => {
    async function getRows() {
      try {
        if (!search) {
          return;
        }
        
        setLoading(true);

        let results = await getNotificationData(searchParams);
        if (results) {
          setData(results);
        }

        setLoading(false);
      } catch (error) {
        console.log('[Error] ', error);
      }
    }
    getRows();
    setSearch(false);
  }, [search, page, rowsPerPage]);

  useEffect(() => {
    const loadDrivers = async () => {
      if (reload) {
        if (!user) {
          if (localStorage.getItem("session")) {
            var user = JSON.parse(localStorage.getItem("session"));
          }
        }
        var driverList = await getDriverList(user);
        let _dkDrivers = driverList.data.map((d) => {
          return {
            ...d,
            ["fullName"]: `${d.firstName} ${d.lastName}`,
          };
        });

        setFilteredDrivers(_dkDrivers);
        setReload(false);
      }
    };
    loadDrivers();
  }, [reload]);

  const handleCloseInfoView = (event, reason) => {
    if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setNotificationInfoOpen(false);
    }
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearch(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSearch(true);
  };

  const exportToCSV = () => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    data.forEach(row => {
      const values = headers.map(header => {
        let escaped = null;
        if(header === 'sentOn') {
          escaped = moment(('' + row[header]).replace(/"/g, '\\"')).format('YYYY-MM-DD HH:mm:ss');
        } else {
          escaped = ('' + row[header]).replace(/"/g, '\\"');
        }
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });

    const csvData = csvRows.join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'notification_data.csv');
    
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <NotificationManagementSummaryHeader />

      <Grid item md={12} xs={12} lg={12} p={1}>
        <StyledFrame>
          <SearchBy
            setSearch={setSearch}
            setSearchParams={setSearchParams}
            drivers={filteredDrivers}
          />
        </StyledFrame>
      </Grid>
      
      {loading && (
        <div>
          <LinearProgress />
        </div>
      )}

      <Grid item md={12} xs={12} lg={12} p={1}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <Button onClick={exportToCSV} variant="contained" color="primary">Export to CSV</Button>
        </div>
        <TableContainer component={Paper}>
          <Table sx={tableSx}>
            <TableHead>
              <TableRow>
                <TableCell>Invoice</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Driver</TableCell>
                <TableCell>Trip Leg</TableCell>
                <TableCell>Sent At</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={row.id}
                  onClick={() => handleRowClick(index)}
                  selected={selectedRow === index}
                  hover className="hover-row"
                >
                  <TableCell>{row.invoiceNumber}</TableCell>
                  <TableCell>{row.customerName}</TableCell>
                  <TableCell>{row.receiverName}</TableCell>
                  <TableCell>{row.leg}</TableCell>
                  <TableCell>{moment(row.sentOn).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  <TableCell>{row.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            component="div"
            count={100}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>

      <NotificationView
        open={isNotificationInfoOpen}
        onClose={handleCloseInfoView}
        notificationData={data[selectedRow]}
      />
    </div>
  );
};

export default NotificationManagementSummary;
