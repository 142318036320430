import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import AddressViewTableColumn from './AddressViewTableColumn';
import { DialogTitle, TableCell, TableRow, Typography } from '@mui/material';
import MedicaidSpecialTiming from './MedicaidSpecialTiming';

function SpecialNotesView({ open, onClose, row }) {

  const setValues = useCallback(() => {} , [])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ bgcolor: '#1976D2', color: 'white' }}>
        <Typography sx={{ fontWeight: '600' }} >Special Notes</Typography>
      </DialogTitle>
      <DialogContent>
        <MedicaidSpecialTiming values={row} setValues={setValues} action={'read-only'}/>
      </DialogContent>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white'
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default SpecialNotesView;
