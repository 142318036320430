import React, { useEffect, useState } from 'react';
import { Paper, Typography, TextField, List, ListItem, ListItemText, ListItemIcon, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles({
  ribbon: {
    top: 0,
    left: 0,
    right: 0,
    padding: '10px',
    zIndex: 1000,
    marginBottom: '10px',
    border: '1px solid lightgray',
    borderRadius: '5px',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '10px',
  },
  listItem: {
    cursor: 'grab',
    maxWidth: '400px', 
    border: '1px solid lightgray', 
    borderRadius: '5px',
    margin: '5px 0',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  dragIcon: {
    marginRight: '10px',
  },
  listItemText: {
    maxWidth: 'calc(100% - 30px)',
  },
  fixedAddress: {
    maxWidth: '450px', 
    backgroundColor: '#fff',
    border: '1px solid lightgray',
    borderRadius: '5px',
    padding: '5px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  button: {
    marginLeft: '10px',
  },
  addAddressField: {
    width: '400px'
  },
  prefix: {
    fontWeight: 'bold',
    color: "#033d5f",
    marginRight: '10px',
  },
});

const LocationEditRibbon = ({ selectedRow, onClose }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [endAddress, setEndAddress] = useState('');

  useEffect(() => {
    if(selectedRow?.waypoints?.length > 2) {      
      const waypoints = [...selectedRow.waypoints.map(item => item.address)]
      waypoints.shift();
      waypoints.pop();
      setAddresses(waypoints)
    }else {
      setAddresses([])
    }

    setEndAddress(selectedRow.dropoffAddress)
  }, [selectedRow])

  const handleAddAddress = () => {
    if (searchTerm) {
      setAddresses([...addresses, searchTerm]);
      setSearchTerm('');
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedAddresses = Array.from(addresses);
    const [removed] = reorderedAddresses.splice(result.source.index, 1);
    reorderedAddresses.splice(result.destination.index, 0, removed);

    setAddresses(reorderedAddresses);
  };

  const handleDeleteAddress = (index) => {
    const updatedAddresses = [...addresses];
    updatedAddresses.splice(index, 1);
    setAddresses(updatedAddresses);
  };

  const handleOnSave = () => {
    if(addresses.length > 0) {
      addresses.unshift(selectedRow.pickaddress);
      addresses.push(endAddress);
      selectedRow['waypoints'] = addresses.map((element, index) => ({mastripId: selectedRow.id, tripLegSequence: selectedRow.tripLegSequence, addressOrder: index, address: element, invoiceNumber: selectedRow.invoiceNumber}))
    }else {
      const tempWayPoints = []
      tempWayPoints.push(selectedRow.pickaddress);
      tempWayPoints.push(endAddress);
      selectedRow['waypoints'] = tempWayPoints.map((element, index) => ({mastripId: selectedRow.id, tripLegSequence: selectedRow.tripLegSequence, addressOrder: index, address: element, invoiceNumber: selectedRow.invoiceNumber}))
    }

    onClose(selectedRow)
  }

  const handleOnClose = () => {
    onClose(selectedRow)
  }

  return (
    <Paper className={classes.ribbon}>

      <div className={classes.searchBox}>
        <TextField
          label="Add Address"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={classes.addAddressField}
        />
        <IconButton onClick={handleAddAddress}>
          <AddIcon />
        </IconButton>
      </div>

      <Typography variant="h6" className={classes.fixedAddress}>
        <ListItemIcon style={{ minWidth: '30px' }}>
          <HomeIcon />
        </ListItemIcon>
        <span><strong style={{ color: "#033d5f" }}>Pickup : </strong> {selectedRow.pickaddress}</span>
      </Typography>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="addresses">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {addresses.map((address, index) => (
                <Draggable key={index} draggableId={`address-${index}`} index={index}>
                  {(provided) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classes.listItem}
                    >
                      <ListItemIcon className={classes.dragIcon}>
                        <DragIndicatorIcon />
                      </ListItemIcon>
                      <ListItemText primary={address} className={classes.listItemText} />
                      <IconButton onClick={() => handleDeleteAddress(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>

      {/* <Typography variant="h6" className={classes.fixedAddress}>
        <ListItemIcon style={{ minWidth: '30px' }}>
          <FlagIcon />
        </ListItemIcon>
        <span><strong style={{ color: "#033d5f" }}>Dropoff : </strong> {selectedRow.dropoffAddress}</span>
      </Typography> */}
      <div className={classes.fixedAddress}>
        <ListItemIcon style={{ minWidth: '30px' }}>
          <FlagIcon />
        </ListItemIcon>
        <Typography variant="body1" className={classes.prefix}>
          Dropoff:
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          value={endAddress}
          onChange={(e) => setEndAddress(e.target.value)}
          fullWidth
        />
      </div>

      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSave}>Apply</Button>
        <Button variant="contained" color="secondary" onClick={handleOnClose} className={classes.button}>Close</Button>
      </div>
    </Paper>
  );
};

export default LocationEditRibbon;
