import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

function AddressViewTableColumn({ columns }) {
  return (
    <TableRow>
      {columns.map((column, index) => (
        <TableCell key={index} component="th" scope="row" style={{fontWeight: 'bold', color: '#1976d2'}}>
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default AddressViewTableColumn;