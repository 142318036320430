import { Grid, Typography } from "@mui/material";
import React, {useState, useEffect, useContext} from "react";
import { 
    StyledDatePicker, 
    StyledFormControl, 
    StyledFrame, 
    StyledInputText, 
    StyledMenuItem, 
    StyledSelect 
} from "../../../styled";
import moment from "moment";

import RefdataContext from "../../../context/RefdataContext";
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";


const config = require("../../../config")
const staticURL = config.BASE_URL;

const providers = [
    {"id":0, "name":"MAS"},
    {"id":1, "name":"Internal"},
]


function LicenceDetailView({values, setValues}){

    const {vehicleList} = useContext(RefdataContext);
    const {providerDriverList} = useContext(RefdataContext);

    const [vehicles, setVehicles] = useState(vehicleList);
    const [providerDrivers, setProviderDrivers] = useState([])
 
 
    useEffect(()=>{
        if(vehicleList)
            setVehicles(vehicleList)
    },[vehicleList]);


    useEffect(()=>{
        // console.log("providerDriverList -> ", providerDriverList)
        if(providerDriverList)
            setProviderDrivers(providerDriverList)
    },[providerDriverList]);

    useEffect(()=>{
        if(values.providerId){
            setrProviderData(values.providerId)
        } 
            
    },[values.providerId])

    useEffect(()=>{
        if(values.vehicleLicense){
            let vehicle = vehicleList.find(v=>v.license == values.vehicleLicense)
            if(vehicle){
                setValues({
                    ...values,
                    ["vehicle_id"]:vehicle.id
                })    
            }
        } 
            
    },[values.vehicleLicense])

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setValues({
            ...values,
            [name]:value
        })
    }

    function setrProviderData(value) {
        let pd = providerDrivers.find(pd=> pd.id == value)
        if(pd)
            setValues({
                ...values,
                ["expieryDate"]:moment(pd.expiration).format("YYYY-MM-DD"),
                ["licenseNo"]:pd.license
            })
    }

    return(
        <StyledFrame>
            <Grid container>
                <Grid item md={12}>
                    <Grid container>
                    <Grid item md={6}>
                            <StyledFormControl variant="standard">
                                <StyledFormInputLabel
                                    primary={false}>
                                    Provider
                                </StyledFormInputLabel>
                                <StyledSelect 
                                    name="provider"
                                    value={values.provider} 
                                    defaultValue={values.provider}
                                    onChange={e=>handleChange(e)}>
                                    { 
                                        providers?.map((c, idx) => {
                                            return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                        })
                                    }    
                                </StyledSelect>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={6}>
                            <StyledFormControl variant="standard">
                                    <StyledFormInputLabel
                                        primary={false}>
                                        Provider ID
                                    </StyledFormInputLabel>
                                    <StyledSelect 
                                        name="providerId"
                                        value={values.providerId} 
                                        defaultValue={values.providerId}
                                        onChange={e=>{handleChange(e)}}>
                                        { 
                                            providerDrivers?.map((pd, idx) => {
                                                return (<StyledMenuItem key={idx} value={pd.id}>{pd.firstname} - {pd.license}</StyledMenuItem>)
                                            })
                                        }    
                                    </StyledSelect>
                                </StyledFormControl> 
                        </Grid>
                        <Grid item md={4}>
                            <StyledFormControl>
                            <StyledFormInputLabel
                                    primary={true}>
                                    License
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    name="licenseNo"
                                    value={values.licenseNo} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={4}>
                            <StyledFormControl>
                            <StyledFormInputLabel
                                    primary={true}>
                                    Expiery
                                </StyledFormInputLabel>
                                <StyledDatePicker
                                    type="date"
                                    id="expieryDate"
                                    name="expieryDate"
                                    value={values.expieryDate?values.expieryDate:moment(new Date()).format("yyyy-MM-dd")}
                                    onChange={(e)=>{handleChange(e)}}
                                />
                            </StyledFormControl> 
                        </Grid>   
                          
                        <Grid item md={4}>
                            <StyledFormControl variant="standard">
                                <StyledFormInputLabel
                                    primary={false}>
                                    Vehicle
                                </StyledFormInputLabel>
                                <StyledSelect 
                                    name="vehicleLicense"
                                    value={values.vehicleLicense} 
                                    defaultValue={values.vehicleLicense}
                                    onChange={e=>handleChange(e)}>
                                    { 
                                        vehicles?.map((c, idx) => {
                                            return (
                                                <StyledMenuItem key={idx} value={c.license}>{c.license} - {c.vehiclename}</StyledMenuItem>)
                                        })
                                    }    
                                </StyledSelect>
                            </StyledFormControl> 
                        </Grid>    
                    </Grid>
                </Grid>
            </Grid>
        </StyledFrame>
    )
}

export default LicenceDetailView;