import { Grid } from "@mui/material";
import React from "react";
import {
  StyledFormControl,
  StyledFrame,
  StyledInputText,
  StyledMenuItem,
  StyledSelect,
} from "../../../styled";

import { formatPhoneNumber } from "../../../utils/formaters";
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";

const config = require("../../../config");

const states = [{ id: "NY", name: "NY" }];

const countries = [{ id: "USA", name: "USA" }];

function MedicaidAddressDetails({ values, setValues }) {
  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "mobile") value = formatPhoneNumber(value);
    setValues({
      ...values,
      [name]: value,
    });
  }

  return (
    <StyledFrame>
      <Grid container>
        <Grid item md={12} style={{ marginTop: "22px", marginBottom: "10px" }}>
          MEDICAID ADDRESS
        </Grid>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={12}>
              <StyledFormControl>
                <StyledFormInputLabel>Address</StyledFormInputLabel>
                <StyledInputText
                  name="medicaidAddress"
                  value={values.medicaidAddress} disabled
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={6}>
              <StyledFormControl>
                <StyledFormInputLabel>City</StyledFormInputLabel>
                <StyledInputText
                  name="city"
                  value={values.city} disabled
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={6}>
              <StyledFormControl>
                <StyledFormInputLabel>Zip</StyledFormInputLabel>
                <StyledInputText
                  name="zip"
                  value={values.zip} disabled
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={6}>
              <StyledFormControl variant="standard">
                <StyledFormInputLabel primary={false}>
                  County
                </StyledFormInputLabel>
                <StyledInputText
                  name="zip"
                  value={values.county} disabled
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={6}>
              <StyledFormControl variant="standard">
                <StyledFormInputLabel primary={false}>
                  State
                </StyledFormInputLabel>
                <StyledInputText
                  name="state"
                  value={values.state} disabled
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledFrame>
  );
}

export default MedicaidAddressDetails;
