import React from "react";
import { StyledButton, StyledHeader, StyledIconWrapper, StyledWrapper } from "../../../styled";
import { Grid, IconButton } from "@mui/material";
import { AddCircleOutline, ListAltOutlined, Padding } from "@mui/icons-material";

const NotificationManagementSummaryHeader = ({setAction,  action}) => {
  return (
    <Grid container p={1}>
      <Grid item md={12} sm={12} xs={12}>
        <StyledHeader>
          Notification Summary
        </StyledHeader>
      </Grid>
    </Grid>  
  );
};

export default NotificationManagementSummaryHeader;
