import { useEffect, useState } from "react";
import UserFilter from "./UserFilter";
import UserManagementHeader from "./UserManagementHeader";
import UserTable from "./UserTable";
import { Grid, IconButton } from "@mui/material";
import { StyledIconWrapper } from "../../../styled";
import { AddCircleOutline } from "@mui/icons-material";

import {
  getUserList,
    getUserLevels
  } from "../../../api/UserManagementApi"
import AddEditUser from "./AddEditUser";
import UserDeleteConfirmationPopup from "./UserDeleteConfirmationPopup";
import { toast } from 'react-toastify';

const UserManagement = () => {

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [action, setAction] = useState({"action":"list","user":null});
    const [userLevels, setUserLevels] = useState([]);
    const [queryFilter, setQueryFilter] = useState(null);
    
    const [reload, setReload] = useState(true);
    const [openAddEditUser, setOpenAddEditUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const handleCloseAddEditUser = () => {
      setOpenAddEditUser(false);
      setReload(true);
    };

    const loadUsers = async (page, rowsPerPage, filter) =>{
      if(reload || pageChanged){
        if(!user){
          if (localStorage.getItem("session")) {
            var user = JSON.parse(localStorage.getItem("session"));
          }
        }
        
        var userLevels = await getUserLevels(user);        
        var userList = await getUserList(user, page, rowsPerPage, filter);

        setUserLevels(userLevels.data)
        
        let _userList = userList.data.map((d)=>{
          return {
            ...d,
          ["userleveldisplay"]: userLevels.data.find(item => item.id === d.userlevel).name
          }
        })

        setFilteredUsers(_userList);
        setReload(false);

        setTotalRows(userList.total);
        setTotalPages(userList.total_pages);
      } 
    }

    useEffect(()=>{        
      loadUsers(0, 10, null);
    },[reload])

    const reloadData = (page, rowsPerPage, filter) => {
        setPageChanged(true)
        loadUsers(page, rowsPerPage, filter);
    }

    /*
    const handleFilterChange = (filterType) => {
        if (filterType === "all") {
          setFilteredCustomers(allCustomers);
        } else if (filterType === "assigned") {
          let Customers = allCustomers.filter((customer) => customer.vehicle_id);
          setFilteredCustomers(Customers);
        } else if (filterType === "unassigned") {
          const Customers = allCustomers.filter(
            (customer) => !customer.vehicle_id
          );
          setFilteredCustomers(Customers);
        } else if (filterType === "active") {
          const Customers = allCustomers.filter(
            (customer) => customer.active
          );
          setFilteredCustomers(Customers);
        } else if (filterType === "inactive") {
          const Customers = allCustomers.filter(
            (customer) => !customer.active
          );
          setFilteredCustomers(Customers);
        }
      };
    */
      useEffect(() => {
        if(action.action === 'edit') {
          showHideUserView(action.user)
        } else if(action.action === 'delete') {
          setCurrentUser(action.user);
          setShowDeleteConfirm(true)
        }
      }, [action])

    const showHideUserView = (currentUser) =>{

      if(currentUser != null) {
        setCurrentUser(currentUser);
      } else {
        let newUser = {
          firstName: '',
          lastName: '',
          email: '',
          mobilePhone: '',
          password: '',
          userName: '',
          userlevel: 3,
          active: true
        }
  
        setCurrentUser(newUser);
      }
      setOpenAddEditUser(true);
    }

    const handleDeleteConfirm = async() => {
      try {
        await deleteUser(currentUser)
        
        toast.success("User deleted" , {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
        setShowDeleteConfirm(false)
        setReload(true);
      } catch (error) {
        console.log('[Error] : ', error);
        toast.error(("User deletion failed. Please try again later"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    const handleDeleteClose = () => {
      setShowDeleteConfirm(false)
    }

    return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >          
          <UserManagementHeader />
          <Grid container>
            <Grid item md={6}>
              <UserFilter setFilter={setQueryFilter} />
            </Grid>
            <Grid item md={6} style={{textAlign:"end"}}>
              <IconButton style={{padding:0}} variant={"contained"} theme onClick={()=>{showHideUserView(null)}}>
                <StyledIconWrapper style={{padding:0}} >
                  <AddCircleOutline fontSize="large" />
                </StyledIconWrapper>
              </IconButton>
            </Grid>
            <Grid item md={12} style={{marginTop:"1.5rem"}}>
              <UserTable users={filteredUsers} setAction={setAction} reloadData={reloadData} totalPages={totalPages} filter={queryFilter}/>  
            </Grid>
          </Grid> 
          <AddEditUser open={openAddEditUser} onClose={handleCloseAddEditUser} userLevels={userLevels} user={currentUser}/>
          <UserDeleteConfirmationPopup open={showDeleteConfirm} handleConfirm={handleDeleteConfirm} handleClose={handleDeleteClose}/>
        </div>
      );
}

export default UserManagement;