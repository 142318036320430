import React, { useState } from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import axios from "axios";
import { BASE_URL } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var md5 = require("md5");

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://massapp.com/">
        DKCare LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn(props) {
  const [userName, setUsername] = useState("");
  const [modleProgress, setModleProgress] = useState(false);

  const [password, setPassword] = useState("");
  const [user, setUser] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let user = { userName, password };
    user.password = md5(user.password);
    // send the username and password to the server
    try {
      setModleProgress(true);
      //
      const response = await axios.post(BASE_URL + "/user/validate", user);
      setModleProgress(false);
      // set the state of the user
      setUser(response.data.user);
      // store the user in localStorage
      localStorage.setItem("session", JSON.stringify(response.data.user));
      localStorage.setItem("portalConfig", JSON.stringify(response.data.portalConfig));
      console.log("USER --->", response.data.user);
      props.onLogin(response.data.user);
      toast.success("Welcome  !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log("error Print");
      setModleProgress(false);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />
      <CssBaseline />
      <Grid container spacing={2} padding={1}>
        <Grid item xs={1}>
          {" "}
        </Grid>
        <Grid
          item
          display={{ xs: "none", sm: "none", md: "block", xl: "block" }}
          xs={7}
          className="front-banner-section"
          sx={{ background: "./DKcare-fleet.png" }}
        >
          {" "}
          <Grid item xs={12}>
            <Grid item xs={12}>
              <h1 className="signin-banner-text">
                Quality & Excellence in Medical Transportation{" "}
              </h1>
            </Grid>
            <Grid item xs={12}>
              <img
                src={process.env.PUBLIC_URL + "/DKcare-fleet.png"}
                style={{ width: "110%" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className="front-login-section"
          item
          xs={12}
          sm={12}
          md={3}
          xl={3}
          display="flex"
          textAlign={"right"}
          justify="flex-end"
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={process.env.PUBLIC_URL + "/logo512.png"} />

            <Typography component="h3" variant="h5" className="sign-in-title">
              MAS Interface - Sign IN
            </Typography>
            <Box
              class="sign-in-form"
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
              method="POST"
            >
              <TextField
                margin="normal"
                required
                fullWidth
                className="signin-input"
                id="userName"
                label="User Name"
                name="userName"
                autoComplete="userName"
                onChange={({ target }) => setUsername(target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                className="signin-input"
                name="password"
                label="Password"
                onChange={({ target }) => setPassword(target.value)}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              {!modleProgress ? (
                <>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                </>
              ) : (
                <>
                  <Box className="sign-in-loading">
                    <CircularProgress /> Checking ...
                  </Box>
                </>
              )}
              {/*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>  */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} display="flex" textAlign={"right"} justify="flex-end">
          {" "}
        </Grid>
        <Grid
          item
          xs={12}
          textAlign={"center"}
          className="front-footer-section"
        >
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Grid>
      </Grid>
    </Box>
  );
}
