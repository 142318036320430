import React from 'react';
import { TextField, InputLabel, Input, IconButton, InputAdornment, Box, useTheme } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import moment from 'moment';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { styled } from "@mui/material/styles";

const DateRangeSelector = ({ rangeStartDate, rangeEndDate, onIconClick }) => {
    const theme = useTheme();

  return (
    <Box border={1} sx={{paddingLeft: '10px', borderRadius: '5px', border: `1px solid ${theme.palette.primary.light}`, cursor: 'pointer'}} onClick={onIconClick}>
        {moment(rangeStartDate).format("MM/DD/YYYY")} -{" "}
        {moment(rangeEndDate).format("MM/DD/YYYY")} &nbsp;
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <CalendarMonthIcon />
        </IconButton>
    </Box>
  );
};

export default DateRangeSelector;