import {Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {  
    StyledFormControl, 
    StyledFrame, 
    StyledInputText, 

} from "../../../styled";
import {matchConfirmPassword} from "../../../utils/validators"
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";


const config = require("../../../config")
const staticURL = config.BASE_URL;

function CredentialsView({values, setValues}){
    
    const [validationErrorStyle, setValidationErrorStyle] = useState({})

    useEffect(()=>{
        // console.log(values.password, values.confirmPassword,matchConfirmPassword(values.password, values.confirmPassword))
        if(!matchConfirmPassword(values.password, values.confirmPassword)){
            setValidationErrorStyle({
                borderColor:"red"
            })
        } else {
            setValidationErrorStyle({
                
            })
        }
    },[values.password, values.confirmPassword]);

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setValues({
            ...values,
            [name]:value
        })
    }

    function handleSelect(e) {
        // console.log(e, e.target)
        let name = e.target.name;
        let value = e.target.checked ? true :false;
        setValues({
            ...values,
            [name]:value
        })
    }

    return(
        <StyledFrame>
            <Grid container>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <StyledFormControl>
                                <StyledFormInputLabel
                                    primary={true}>
                                    Username
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    name="userName"
                                    value={values.userName} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>  
                        <Grid item md={6}>
                            <StyledFormControl>
                            <StyledFormInputLabel
                                    primary={true}>
                                    Password
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    name="password"
                                    type="password"
                                    value={values.password} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid> 
                        <Grid item md={6}>
                            <StyledFormControl>
                                <StyledFormInputLabel
                                    primary={false}>
                                    Confirm Password
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    type="password"
                                    name="confirmPassword"
                                    style={validationErrorStyle}
                                    value={values.confirmPassword} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>
                        <Grid item md={6} style={{display:"flex", alignItems:"end"}}>
                            <StyledFormControl>
                                <FormControlLabel  control={
                                    <Checkbox 
                                        name="resetPassword"
                                        value={values.resetPassword?true:false} 
                                        defaultValue={values?.resetPassword}
                                        onChange={e=>handleSelect(e)}
                                    />} label="Reset Password" />
                            </StyledFormControl>
                        </Grid>    
                    </Grid>
                </Grid>
            </Grid>
        </StyledFrame>
    )
}

export default CredentialsView;