import { Grid } from "@mui/material";
import React from "react";
import {
  StyledDatePicker,
  StyledFormControl,
  StyledFrame,
  StyledInputText,
} from "../../../styled";
import moment from "moment";

import { formatPhoneNumber } from "../../../utils/formaters";
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";

const config = require("../../../config");

function GeneralDetails({ values, setValues }) {
  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "mobile") value = formatPhoneNumber(value);
    setValues({
      ...values,
      [name]: value,
    });
  }

  return (
    <StyledFrame>
      <Grid container>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={3}>
              <StyledFormControl>
                <StyledFormInputLabel primary={true}>
                  First Name
                </StyledFormInputLabel>
                <StyledInputText
                  name="firstname"
                  value={values.firstname}
                  onChange={(e) => handleChange(e)}
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={3}>
              <StyledFormControl>
                <StyledFormInputLabel primary={true}>
                  Last Name
                </StyledFormInputLabel>
                <StyledInputText
                  name="lastname"
                  value={values.lastname}
                  onChange={(e) => handleChange(e)}
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={3}>
              <StyledFormControl>
                <StyledFormInputLabel primary={true}>
                  Contact Number
                </StyledFormInputLabel>
                <StyledInputText
                  name="contactnumber"
                  value={formatPhoneNumber(values.contactnumber)}
                  onChange={(e) => handleChange(e)}
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={3}>
              <StyledFormControl>
                <StyledFormInputLabel primary={false}>
                  Date of Birth
                </StyledFormInputLabel>
                <StyledDatePicker
                  type="date"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={
                    values.dateOfBirth
                      ? values.dateOfBirth
                      : moment(new Date()).format("yyyy-MM-dd")
                  }
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledFrame>
  );
}

export default GeneralDetails;
