import React, { Component } from "react";
import "fontsource-roboto";
import { BASE_URL } from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import moment from "moment";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import InfoIcon from '@mui/icons-material/Info';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import { DateRangePicker, DateRange } from "mui-daterange-picker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';
import {
  BarChart,
  Bar,
  CartesianGrid,
  LineChart,
  Line,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  PieChart,
  Pie,
  Label,
  Sector,
  Cell,
  LabelList,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

import Modal from "@mui/material/Modal";
import {
  Button,
  Card,
  CardHeader,
  IconButton,
  InputBase,
  Paper,
  TableFooter,
  TextField,
  Typography
} from "@mui/material";
import { StyledFormControl, StyledMenuItem, StyledSelect } from "../../styled";
import DateRangeSelector from "../components/DateRangeSelector";
import FilterPopover from "../components/FilterPopover";
import TripStatusSummaryByDriver from "../components/tripStatusSummaryByDriver";

const CardRoot = styled(Card)(() => ({
  height: "100%",
  padding: "20px 24px",
  borderRadius: 25,
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  boxShadow: "none",
  // boxShadow: "rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important",
  borderRadius: " 8px",
}));

const CardTitle = styled("div")(() => ({
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#1F1F1F",
  marginBottom: "2px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const expGridCell = {
  fontSize: "12px",
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const CustomTooltipMultiLine = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
        <p className="label">{`${label} : ${payload[1].value}`}</p>
      </div>
    );
  }

  return null;
};

const TripsByCountyChildDiv = styled('div')({
  marginLeft: '25%',
});

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

class MainDashboard extends Component {
  constructor(props) {
    super(props);
    let url = new URL(window.location.href);

    this.weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    this.drivers = [];

    this.state = {
      tripsByPatientsCounty: [],
      tripsByMedicaidCounty: [],
      pageLoading: true,
      dailyTripsCount: [],
      dailyTripsMileage: [],
      dailyTripsAndMileageLastTwoMonths: [],
      filteredDailyTripsLastTwoMonths: [],
      filteredDailyMileageLastTwoMonths: [],
      tripsPerDriverPerWeek: [],
      filteredTripsPerDriverPerWeek: [],
      driverdata: [],
      middayTrips : [],
      availableFutureDates: [],
      totalByCountryTotal: 0.0,
      tripsByCountyDate: moment().format("MM/DD/YYYY"),
      tripCostsStats: {
        totalattested: 0,
        lastweekattested: 0,
        lastmonthattested: 0,
        lastyearattested: 0,
      },
      dateRangeOpen: false,
      rangeStartDate: moment()
        .add(-1, "day")
        .startOf("day")
        .format("MM/DD/YYYY"),
      rangeEndDate: moment().add(-1, "day").endOf("day").format("MM/DD/YYYY"),
      user: {},
      selectedDateRange: "today",
      anchorElTrips: null,
      anchorElMileage: null,
      anchorEl2: null,
      filterOpenTrips: false,
      filterOpenMileage: false,
      filterOpen2: false,
      driverPerWeekFilter: this.drivers,
      tripsFilter: this.weekDays,
      mileageFilter: this.weekDays,
      tripStatusSummaryActiveIndex: 0,
      tripSummaryByDriverDialog: false,
      tripsStatusSummary: [],
      tripsStatusSummaryByDriver: [],
      allTripsSummary: {}
    };
    this.refreshTripsByCountyChart = this.refreshTripsByCountyChart.bind(this);
    this.randomColor = this.randomColor.bind(this);
    this.chartColor = this.chartColor.bind(this);
    this.renderPatientsColorfulLegendText = this.renderPatientsColorfulLegendText.bind(this);
    this.renderMedicaidColorfulLegendText = this.renderMedicaidColorfulLegendText.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
    this.setDateRangeToggle = this.setDateRangeToggle.bind(this);
    this.setDateRangeDropDown = this.setDateRangeDropDown.bind(this);
  }
  componentDidMount() {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }

    this.setState({ user: user }, () => {
      this.refreshTripsByCountyChart();
    });
  }

  setDateRangeDropDown = (e) => {
    const { name, value } = e.target;
    this.setState({ selectedDateRange: value });

    let startDate = null, endDate = null;
    switch (value) {
      case 'today':
        startDate = moment();
        endDate = moment();
        break;

      case 'yesterday':
        startDate = moment().subtract(1, 'day');
        endDate = moment().subtract(1, 'day');
        break;

      case 'thisWeek':
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
        break;

      case 'lastWeek':
        startDate = moment().subtract(1, 'weeks').startOf('week');
        endDate = moment().subtract(1, 'weeks').endOf('week');
        break;

      case 'thisMonth':
        startDate = moment().startOf('month');
        endDate = moment().endOf('month');
        break;

      case 'last7Days':
        startDate = moment();
        endDate = moment().subtract(7, 'days');
        break;

      case 'lastMonth':
        startDate = moment().subtract(1, 'month').startOf('month');
        endDate = moment().subtract(1, 'month').endOf('month');
        break;

      case 'thisYear':
        startDate = moment().startOf('year');
        endDate = moment().endOf('year');
        break;

      case 'lastYear':
        startDate = moment().subtract(1, 'year').startOf('year');
        endDate = moment().subtract(1, 'year').endOf('year');
        break;
    }

    this.setState(
      {
        rangeStartDate: startDate.format('MM/DD/YYYY'),
        rangeEndDate: endDate.format('MM/DD/YYYY'),
      },
      this.refreshTripsByCountyChart
    );
  };

  setDateRange = (range) => {

    if(!range.label) {
      this.setState({ selectedDateRange: '' });
    } else {
      switch (range.label) {
        case 'Today':
          this.setState({ selectedDateRange: 'today' });
          break;
        case 'Yesterday':
          this.setState({ selectedDateRange: 'yesterday' });
          break;
        case 'This Week':
          this.setState({ selectedDateRange: 'thisWeek' });
          break;
        case 'Last Week':
          this.setState({ selectedDateRange: 'lastWeek' });
          break;
        case 'Last 7 Days':
          this.setState({ selectedDateRange: 'last7Days' });
          break;
        case 'This Month':
          this.setState({ selectedDateRange: 'thisMonth' });
          break;
        case 'Last Month':
          this.setState({ selectedDateRange: 'lastMonth' });
          break;
        case 'This Year':
          this.setState({ selectedDateRange: 'thisYear' });
          break;
        case 'Last Year':
          this.setState({ selectedDateRange: 'lastYear' });
          break;
      
        default:
          this.setState({ selectedDateRange: '' });
          break;
      }
      
    }

    this.setState(
      {
        rangeStartDate: moment(range.startDate)
          .startOf("day")
          .format("MM/DD/YYYY"),
        rangeEndDate: moment(range.endDate).endOf("day").format("MM/DD/YYYY"),
      },
      this.refreshTripsByCountyChart
    );
    this.setDateRangeToggle();
  };
  setDateRangeToggle = () => {
    this.setState({ dateRangeOpen: !this.state.dateRangeOpen });
  };
  renderMedicaidColorfulLegendText = (value, entry, index) => {  
      const { color } = entry;
      let totalTrips = 0;
      for (let city of this.state.tripsByMedicaidCounty) {
        totalTrips += city.trips;
      }
      return (
        <span style={{ color, fontSize: 12 }}>
          {value} - {this.state.tripsByMedicaidCounty[index].trips} Trips -{" "}
          {parseFloat(
            (100 * this.state.tripsByMedicaidCounty[index].trips) / totalTrips
          ).toFixed(2)}{" "}
          %
        </span>
      );
    };
  renderPatientsColorfulLegendText = (value, entry, index) => {
    const { color } = entry;
    let totalTrips = 0;
    for (let city of this.state.tripsByPatientsCounty) {
      totalTrips += city.trips;
    }
    return (
      <span style={{ color, fontSize: 12 }}>
        {value} - {this.state.tripsByPatientsCounty[index].trips} Trips -{" "}
        {parseFloat(
          (100 * this.state.tripsByPatientsCounty[index].trips) / totalTrips
        ).toFixed(2)}{" "}
        %
      </span>
    );
  };
  randomColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  chartColor(index) {
    let chartc = ["#003f5c", "#374c80", "#7a5195", "#bc5090", "#ef5675", "#ff764a", "#ffa600", "#003f5c", "#004064", "#00436b", "#004772", "#004b78", "#004f7e", "#005385", "#00578b", "#005b92", "#005f98",  "#00639f", "#0067a5", "#006bac", "#006fb2", "#0073b9", "#0077bf", "#007bc6", "#007fce", "#0083d5", "#0087db",  "#008be2", "#008fe8", "#0093ef", "#0097f5", "#009bfc", "#009eff", "#0795ff", "#138cff", "#1e82ff", "#2878ff",  "#336eff", "#3e65ff", "#485bff", "#5351ff", "#5e47ff", "#683eff", "#7234ff", "#7d2aff", "#881fff", "#9225ff",  "#9d1bff", "#a711ff", "#b207ff", "#b200f8", "#b200ec", "#b200e0", "#b200d5", "#b200ca", "#b200be", "#b200b3",  "#b200a7", "#b2009c", "#b20090", "#b20085", "#b2007a", "#b2006e", "#b20063", "#b20057", "#b2004c", "#b20040",  "#b20035", "#b20029", "#b2001e", "#b20013", "#b20007", "#b30000", "#b30d00", "#b31a00", "#b32600", "#b33300",  "#b34000", "#b34c00", "#b35900", "#b36500", "#b37200", "#b37f00", "#b38b00", "#b39800", "#b3a400", "#b3b100",  "#b3bd00", "#b3ca00", "#b3d600", "#b3e300", "#b3f000", "#b3fc00", "#a8ff00", "#9cff00", "#91ff00", "#85ff00",  "#7aff00", "#6eff00", "#63ff00", "#57ff00", "#4cff00", "#40ff00", "#35ff00", "#29ff00", "#1eff00", "#13ff00",  "#07ff00", "#00ff0b", "#00ff16", "#00ff22", "#00ff2d", "#00ff39", "#00ff44", "#00ff50", "#00ff5b", "#00ff67"]
    return chartc[index];
  }
  async refreshTripsByCountyChart() {

    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": this.state.user.key,
    };

    await this.setState({ pageLoading: true });

    const response = await axios.post(
      BASE_URL + "/dashboard/tripsByCountyDate",
      {
        tripsByCountyDate: this.state.tripsByCountyDate,
        rangeStartDate: this.state.rangeStartDate,
        rangeEndDate: this.state.rangeEndDate,
        selectedDateRange: this.state.selectedDateRange
      },
      {
        headers: headers,
      }
    );

    let totalByCountryTotal = 0.0;
    for (let trip of response.data.totalByCounty) {
      totalByCountryTotal += trip.totalCost;
    }
    
    console.log(response.data);

    this.drivers = response.data.tripsPerDriverPerWeek.length > 0 && response.data.tripsPerDriverPerWeek.map((item) => item.driver)

    await this.setState({
      tripsByPatientsCounty: response.data.tripsByPatientsCounty,
      tripsByMedicaidCounty: response.data.tripsByMedicaidCounty,
      totalByCounty: response.data.totalByCounty,
      dailyTripsCount: response.data.dailyTripsCount,
      dailyTripsMileage: response.data.dailyTripsMileage,
      dailyTripsAndMileageLastTwoMonths: response.data.dailyTripsAndMileageLastTwoMonths,
      filteredDailyTripsLastTwoMonths: response.data.dailyTripsAndMileageLastTwoMonths,
      filteredDailyMileageLastTwoMonths: response.data.dailyTripsAndMileageLastTwoMonths,
      tripsPerDriverPerWeek: response.data.tripsPerDriverPerWeek,
      filteredTripsPerDriverPerWeek: response.data.tripsPerDriverPerWeek,
      tripCostsStats: response.data.tripCostsStats,
      availableFutureDates: response.data.availableFutureDates,
      /* driverdata: response.data.driverdata,*/
      driverdata: response.data.expiriengcontracts,
	    middayTrips : response.data.middayTrips.filter( t => t.tripLegSequence == 1 ),
      driverPerWeekFilter: this.drivers,
      totalByCountryTotal: parseFloat(totalByCountryTotal).toFixed(2),
      pageLoading: false,
      tripsStatusSummary: response.data.tripsStatusSummary,
      tripsStatusSummaryByDriver: response.data.tripsStatusSummaryByDriver,
      allTripsSummary: response.data.allTripsSummary
    });
  }

  setDateRangeToggle = () => {
    this.setState({ dateRangeOpen: !this.state.dateRangeOpen });
  };

  handleClickTripFilter = (event) => {
    this.setState({ anchorElTrips: event.currentTarget, filterOpenTrips: Boolean(event.currentTarget) });
  };

  handleClickMileageFilter = (event) => {
    this.setState({ anchorElMileage: event.currentTarget, filterOpenMileage: Boolean(event.currentTarget) });
  };

  handleClick2 = (event) => {
    this.setState({ anchorEl2: event.currentTarget, filterOpen2: Boolean(event.currentTarget) });
  };

  handleFilterCloseTrips = (param) => {
    const days = Array.isArray(param) && param.length > 0 ? param : this.weekDays

    const dates = this.listLastTwoMonthsDays(days);   
    const tripsInfo = this.state.dailyTripsAndMileageLastTwoMonths;
    const filteredTripInfo = tripsInfo.filter((trip) => dates.includes(trip.pickupDay))

    this.setState({ anchorElTrips: null, filterOpenTrips: false, tripsFilter: days,  filteredDailyTripsLastTwoMonths: filteredTripInfo});
  };

  handleFilterCloseMileage = (param) => {
    const days = Array.isArray(param) && param.length > 0 ? param : this.weekDays

    const dates = this.listLastTwoMonthsDays(days);   
    const tripsInfo = this.state.dailyTripsAndMileageLastTwoMonths;
    const filteredTripInfo = tripsInfo.filter((trip) => dates.includes(trip.pickupDay))

    this.setState({ anchorElMileage: null, filterOpenMileage: false, mileageFilter: days,  filteredDailyMileageLastTwoMonths: filteredTripInfo});
  };

  handleSelectedValuesChangeTrips = (values) => {
    this.setState({ tripsFilter: values });
  };

  handleSelectedValuesChangeMileage = (values) => {
    this.setState({ mileageFilter: values });
  };

  handleFilterCloseDriverPerWeek = (param) => {
    const drivers = Array.isArray(param) && param.length > 0 ? param : this.drivers
    const filteredTripInfo = this.state.tripsPerDriverPerWeek.filter((trip) => drivers.includes(trip.driver))
    this.setState({ anchorEl2: null, filterOpen2: false, driverPerWeekFilter: Array.isArray(param) && param.length > 0 ? param : this.drivers, filteredTripsPerDriverPerWeek: filteredTripInfo });
  };

  handleSelectedValuesChangeDriverPerWeek = (values) => {
    this.setState({ driverPerWeekFilter: values });
  };  

  listLastTwoMonthsDays = (days) => { 
    let allDates = []
    for(const day of days) {
      let dates = this.getLastTwoMonthsDays(day);
      allDates = [...allDates, ...dates]
    }

    return allDates
  }

  getLastTwoMonthsDays = (dayName) => {
    const today = moment();
    const lastMonth = today.clone().subtract(1, 'months');
    const lastTwoMonths = today.clone().subtract(2, 'months');

    const days = [];
    let currentDay = lastTwoMonths.startOf('month');

    while (currentDay.isSameOrBefore(today)) {
        if (currentDay.format('dddd') === dayName) {
            days.push(currentDay.format('YYYY-MM-DD'));
        }
        currentDay.add(1, 'days');
    }

    return days;
  }

  onTripStatusPieEnter = (_, index) => {
    this.setState({
      tripStatusSummaryActiveIndex: index,
    });
  };

  handleOpenTripSummaryByDriver = () => {
    this.setState({
      tripSummaryByDriverDialog: true,
    });
  };

  handleCloseTripSummaryByDriver = () => {
    this.setState({
      tripSummaryByDriverDialog: false,
    });
  };

  render() {
    return (
      <>
        <ToastContainer />
        <Grid container className="main-dashbord-container" padding={1}>
          <Grid container item xs={12}>
            <Grid item container spacing={3} sx={{marginLeft: '0px'}}>
              <Grid container className="screen-sub-title">
                <Grid item xs={10} className="screen-sub-title"> Analytical Dashboard  </Grid>
                <Grid item xs={2} className="dashboard-notification"> 
                          {this.state.middayTrips.filter(m => m.status == "Eligible" ).length > 0 ? <><Badge badgeContent={this.state.middayTrips.filter(m => m.status == "Eligible" ).length} color="error">
                      <InfoIcon color="action" />
                  </Badge>  </>:<></> }	
                </Grid>                 
              </Grid>
              <Grid container>
                    <Grid item>
                      <StyledFormControl
                        variant="standard"
                        sx={{
                          width: 200,
                          backgroundColor: "transparent",
                          marginBottom: "5px",
                        }}
                      >
                        <StyledSelect
                          name="dashBoardDateRange"
                          value={this.state.selectedDateRange}
                          defaultValue={this.state.selectedDateRange}
                          onChange={(e) => this.setDateRangeDropDown(e)}
                          sx={{ padding: "5px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          }}
                        >
                          {[
                            { id: "today", label: "Today" },
                            { id: "yesterday", label: "Yesterday" },
                            { id: "thisWeek", label: "This week" },
                            { id: "lastWeek", label: "Last week" },
                            { id: "last7Days", label: "Last 7 Days" },
                            { id: "thisMonth", label: "This month" },
                            { id: "lastMonth", label: "Last Month" },
                            { id: "thisYear", label: "This Year" },
                            { id: "lastYear", label: "Last Year" },
                          ].map((c, idx) => {
                            return (
                              <StyledMenuItem key={idx} value={c.id}>
                                {c.label}
                              </StyledMenuItem>
                            );
                          })}
                        </StyledSelect>
                      </StyledFormControl>
                    </Grid>
                    <Grid item sx={{ marginTop: "7px", marginLeft: "8px" }}>
                      <DateRangeSelector
                        rangeStartDate={this.state.rangeStartDate}
                        rangeEndDate={this.state.rangeEndDate}
                        onIconClick={this.setDateRangeToggle}
                      />
                      <DateRangePicker
                        wrapperClassName="main-dashboard-date-range-wrapper"
                        open={this.state.dateRangeOpen}
                        toggle={this.setDateRangeToggle}
                        onChange={(range) => {
                          this.setDateRange(range);
                        }}
                      />
                    </Grid>
              </Grid>
              {this.state.user.userlevel == 1 ? (
                <Grid item xs={12} md={12} lg={12}>
                  <Card className="date-range-card">
                    <CardTitle className="main-dashbord-widget-title">
                      Revenue ( $ ) By County on{" "}
                      {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                      {moment(this.state.rangeEndDate).format("MM/DD/YYYY")}{" "}
                      With PA #
                    </CardTitle>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8} lg={8} xl={8} height={200}>
                          <ResponsiveContainer>
                            <BarChart
                              margin={{
                                top: 16,
                                right: 16,
                                bottom: 0,
                                left: 24,
                              }}
                              data={this.state.totalByCounty}
                            >
                              <CartesianGrid strokeDasharray="3 3" />

                              <XAxis
                                dataKey="name"
                                tick={{ fontSize: 12 }}
                                // scale="band"
                              />
                              <YAxis tick={{ fontSize: 12 }} />
                              <Tooltip content={<CustomTooltip />} />
                              {/* <Legend /> */}
                              <Bar dataKey="totalCost" fill="#64d542" name="Total Cost" barSize="30%" >
                                <LabelList dataKey="totalCost" position="top" />
                              </Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </Grid>

                        <Grid item xs={12} md={4} lg={4} xl={2} height={200}>
                          <TableContainer style={{ maxHeight: 150, overflow: 'auto' }}>
                            <Table>
                              <TableBody>
                                {this.state.totalByCounty &&
                                  this.state.totalByCounty.map((t) => {
                                    return (
                                      <>
                                        <TableRow className="trip-row-legend">
                                          <TableCell className="trip-row-legend-cap">
                                            {" "}
                                            {t.name}{" "}
                                          </TableCell>
                                          <TableCell className="trip-row-legend-val">
                                            $ {t.totalCost}{" "}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  })}

                                <TableRow className="trip-total-legend">
                                  <TableCell className="trip-row-legend-cap">
                                    {" "}
                                    Total{" "}
                                  </TableCell>
                                  <TableCell className="trip-row-legend-val">
                                    $ {this.state.totalByCountryTotal}{" "}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>{" "}
                          </TableContainer>
                        </Grid>

                        <Grid item xs={12} md={4} lg={4} xl={2} height={200}>
                          <TableContainer style={{ maxHeight: 150, overflow: 'auto' }}>
                            <Table>
                              <TableBody>
                                {this.state.totalByCounty &&
                                  this.state.totalByCounty.map((t) => {
                                    return (
                                      <>
                                        <TableRow className="trip-row-legend">
                                          <TableCell className="trip-row-legend-cap">
                                            {" "}
                                            {t.name}{" "}
                                          </TableCell>
                                          <TableCell className="trip-row-legend-val">
                                            $ {(t.totalCost - t.comparetotalCost).toFixed(2)} {" "} {(t.totalCost - t.comparetotalCost) > 0 ? <ArrowUpward sx={{ color: green[500], fontSize: 'small' }} /> : <ArrowDownward sx={{ color: red[500], fontSize: 'small' }} />}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  })}
                              </TableBody>
                            </Table>{" "}
                          </TableContainer>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ color: "warning.main" }}>
                          * Attested trips with PA numbers only
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ) : null}
              <Grid item xs={12} md={6} lg={6}>
                <CardRoot elevation={6}>
                  <CardTitle className="main-dashboard-card-header-sm">Upcoming expiring contracts</CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <TableContainer style={{ maxHeight: 200 }}>
                        <Table
                          aria-label="simple table"
                          className="trip-results-data-table"
                          stickyHeader
                        >
                          <TableHead>
                            {" "}
                            <TableRow>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                Expiration
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                Dates
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                Client Name
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                County
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.driverdata.map((row) => (
                              <>
                                <TableRow
                                  className={
                                    row.expdiff < 2
                                      ? "driver-row-expired"
                                      : row.expdiff < 7
                                      ? "driver-row-week"
                                      : row.expdiff < 14
                                      ? "driver-row-twoweek"
                                      : row.expdiff < 30
                                      ? "driver-row-month"
                                      : row.expdiff < 90
                                      ? "driver-row-three-months"
                                      : row.expdiff < 365
                                      ? "driver-row-year"
                                      : "driver-row-more-year"
                                  }
                                >
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {moment(row.expirationnt).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.expdiff}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.client}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.county}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CardRoot elevation={6}>
                  <CardTitle className="main-dashboard-card-header-sm">Newly Added or Modified Trips</CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} height={200} className="trip-results-data-table-container">
                      <ResponsiveContainer>
                        <TableContainer style={{ maxHeight: 200 }}>
                        <Table
                          aria-label="simple table"
                          className="trip-results-data-table"
                          stickyHeader
                        >
                          <TableHead>
                            {" "}
                            <TableRow>
                              <TableCell
                                align="left"
                                className="driver-data-header test main-dashboard-table-header"
                              >
                                Invoice
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                               Pickup Date
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                Client Name
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                status
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                Modified At
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.middayTrips.map((row) => (
                              <>
                                <TableRow
                                
                                >
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.invoiceNumber}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {moment(row.pickupDate).format('MM/DD/YYYY')}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.firstName} {row.lastName}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.status}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {moment(row.lastModified).format('MM/DD/YYYY hh:mm')}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>

              <Grid item xs={12} md={7} lg={7}>
                <CardRoot elevation={6}>
                  <CardTitle className="main-dashboard-card-header-sm">
                    Trips By County on{" "}
                    {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                    {moment(this.state.rangeEndDate).format("MM/DD/YYYY")}
                  </CardTitle>
                  <Grid container>
                    <Grid item xs={6} md={6} lg={6} height={200}>
                      <TripsByCountyChildDiv>By Patients</TripsByCountyChildDiv>
                      <ResponsiveContainer
                        width="100%"
                        height="100%"
                        margin={25}
                      >
                        <PieChart margin={25}>
                          <Legend
                            layout="vertical"
                            align="right"
                            formatter={this.renderPatientsColorfulLegendText}
                            wrapperStyle={{
                              height: '80%',
                              overflow: 'auto',
                              marginBottom: '30px'
                            }}
                          />
                          
                          <Pie
                            dataKey="trips"
                            isAnimationActive={false}
                            data={this.state.tripsByPatientsCounty}
                            fill="#8884d8"
                          >
                            {this.state.tripsByPatientsCounty.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={this.chartColor(index)}
                              />
                            ))}
                          </Pie>

                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                      <Box sx={{ color: "info.main" }}>
                        * Eligible trips regardless of attestation status
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} height={200}>
                      <TripsByCountyChildDiv>By Medicaid</TripsByCountyChildDiv>                      
                      <ResponsiveContainer
                        width="100%"
                        height="100%"
                        margin={25}
                      >
                        <PieChart margin={25}>
                          <Legend
                            layout="vertical"
                            align="right"
                            formatter={this.renderMedicaidColorfulLegendText}
                            wrapperStyle={{
                              height: '80%',
                              overflow: 'auto',
                              marginBottom: '30px'
                            }}
                          />
                          
                          <Pie
                            dataKey="trips"
                            isAnimationActive={false}
                            data={this.state.tripsByMedicaidCounty}
                            fill="#8884d8"
                          >
                            {this.state.tripsByMedicaidCounty.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={this.chartColor(index)}
                              />
                            ))}
                          </Pie>

                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                      <Box sx={{ color: "info.main" }}>
                        * Eligible trips regardless of attestation status
                      </Box>
                    </Grid>{" "}
                  </Grid>{" "}
                </CardRoot>
              </Grid>
              <Grid item xs={12} md={5} lg={5}>
                <CardRoot elevation={6}>
                  <CardTitle className="main-dashboard-card-header-sm">Dispatch Summary on{" "}
                    {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                    {moment(this.state.rangeEndDate).format("MM/DD/YYYY")}</CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={6} height={300} onClick={this.handleOpenTripSummaryByDriver}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={this.state.tripStatusSummaryActiveIndex}
                            activeShape={renderActiveShape}
                            data={this.state.tripsStatusSummary}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={this.onTripStatusPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} height={300} onClick={this.handleOpenTripSummaryByDriver}>
                      <div style={{ maxWidth: 400, overflowX: 'auto' }}>
                        <Table>
                          <TableBody>
                            <TableRow>
                                <TableCell>TOTAL LEGS</TableCell>
                              <TableCell>{this.state.allTripsSummary.eligibleTrips}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>TOTAL ASSIGNED</TableCell>
                              <TableCell>{this.state.allTripsSummary.assignedTrips}</TableCell>
                              { this.state.allTripsSummary.eligibleTrips > 0 && <TableCell> {parseFloat((this.state.allTripsSummary.assignedTrips / this.state.allTripsSummary.eligibleTrips ) * 100).toFixed(2)} % </TableCell>}
                            </TableRow>

                            {this.state.allTripsSummary.assignedTrips > 0 && this.state.tripsStatusSummary.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell> &nbsp; &nbsp; &nbsp;{row.name}</TableCell>
                                <TableCell>{row.value}</TableCell>
                                <TableCell>{parseFloat((row.value / this.state.allTripsSummary.assignedTrips ) * 100).toFixed(0)} % </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Grid>
                    <TripStatusSummaryByDriver open={this.state.tripSummaryByDriverDialog} handleClose={this.handleCloseTripSummaryByDriver} data={this.state.tripsStatusSummaryByDriver} />
                  </Grid>
                </CardRoot>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CardRoot elevation={6} >
                  <CardTitle className="main-dashboard-card-header-sm">Number of Trips on{" "}
                    {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                    {moment(this.state.rangeEndDate).format("MM/DD/YYYY")}</CardTitle>
                  <Grid container style={{marginTop:"10px"}}>
                    <Grid item xs={12} md={12} lg={12} height={200}>
                      <ResponsiveContainer>
                        <BarChart
                          margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 24,
                          }}
                          data={this.state.dailyTripsCount}
                        >
                          <CartesianGrid strokeDasharray="3 3" />

                          <XAxis
                            dataKey="pickupDate"
                            tick={{ fontSize: 10 }}
                            // scale="band"
                            
                          />
                          <YAxis tick={{ fontSize: 12 }} />
                          <Tooltip content={<CustomTooltip />} />
                          {/* <Legend /> */}
                          <Bar dataKey="tripCount" fill="#52cdff"  barSize="30%">
                          <LabelList dataKey="tripCount" position="top" fontSize={16} fontWeight={900} style={{padding:"10px"}}/>
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <CardRoot elevation={6}>
                  <CardTitle className="main-dashboard-card-header-sm">Trips Daily Mileage on{" "}
                    {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                    {moment(this.state.rangeEndDate).format("MM/DD/YYYY")}</CardTitle>
                  <Grid container style={{marginTop:"10px"}}>
                    <Grid item xs={12} md={12} lg={12} height={200}>
                      <ResponsiveContainer>
                        <BarChart
                          margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 24,
                          }}
                          data={this.state.dailyTripsMileage}
                        >
                          <CartesianGrid strokeDasharray="3 3" />

                          <XAxis
                            dataKey="pickupDate"
                            tick={{ fontSize: 10 }}
                            // scale="band"
                          />
                          <YAxis tick={{ fontSize: 12 }} />
                          <Tooltip content={<CustomTooltip />} />
                          {/* <Legend /> */}
                          <Bar dataKey="totalMileage" fill="#52cdff" barSize="30%">
                          <LabelList dataKey="totalMileage" position="top" fontSize={16} backgroundColor="red"/>
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>

             

              <Grid item xs={12} md={12} lg={12}>
                <CardRoot elevation={6}>
                  <CardHeader
                    title="Total Trips for last two months"
                    className="main-dashboard-card-header-custom"
                    action={
                      <>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          style={{ backgroundColor: 'white', color: 'black' }}
                          onClick={this.handleClickTripFilter}
                          startIcon={<FilterAltOutlinedIcon />}
                        >
                          Filter
                        </Button>
                        <FilterPopover 
                          open={this.state.filterOpenTrips} 
                          anchorEl={this.state.anchorElTrips}
                          options={this.weekDays}
                          filterParam={this.state.tripsFilter} 
                          handleClose={this.handleFilterCloseTrips} 
                          onSelectedValuesChange={this.handleSelectedValuesChangeTrips}
                        />
                      </>
                    }
                  />
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} height={200}>
                      <ResponsiveContainer>
                        <BarChart
                          margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 24,
                          }}
                          data={this.state.filteredDailyTripsLastTwoMonths}
                        >
                          <CartesianGrid strokeDasharray="3 3" />

                          <XAxis
                            dataKey="pickupDay"
                            tick={{ fontSize: 10 }}
                            scale="band"
                          />
                          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" tick={{ fontSize: 12 }} />
                          <Tooltip />
                          <Legend />
                          <Bar yAxisId="left" dataKey="tripCount" fill="#8884d8" barSize={20}/>
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <CardRoot elevation={6}>
                  <CardHeader
                    title="Total Mileage for last two months"
                    className="main-dashboard-card-header-custom"
                    action={
                      <>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          style={{ backgroundColor: 'white', color: 'black' }}
                          onClick={this.handleClickMileageFilter}
                          startIcon={<FilterAltOutlinedIcon />}
                        >
                          Filter
                        </Button>
                        <FilterPopover 
                          open={this.state.filterOpenMileage} 
                          anchorEl={this.state.anchorElMileage}
                          options={this.weekDays}
                          filterParam={this.state.mileageFilter} 
                          handleClose={this.handleFilterCloseMileage} 
                          onSelectedValuesChange={this.handleSelectedValuesChangeMileage}
                        />
                      </>
                    }
                  />
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} height={200}>
                      <ResponsiveContainer>
                        <BarChart
                          margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 24,
                          }}
                          data={this.state.filteredDailyMileageLastTwoMonths}
                        >
                          <CartesianGrid strokeDasharray="3 3" />

                          <XAxis
                            dataKey="pickupDay"
                            tick={{ fontSize: 10 }}
                            scale="band"
                          />
                          <YAxis yAxisId="left" orientation="left" stroke="#82ca9d" tick={{ fontSize: 12 }} type="number"/>
                          <Tooltip />
                          <Legend />
                          <Bar yAxisId="left" dataKey="totalMileage" fill="#82ca9d" barSize={20}/>
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <CardRoot elevation={6}>
                  <CardTitle className="main-dashboard-card-header-sm">Available future dates</CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} height={200}>
                      <ResponsiveContainer>
                        <AreaChart
                          height={200}
                          data={this.state.availableFutureDates}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                          <YAxis tick={{ fontSize: 12 }} />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="trips"
                            stroke="#8884d8"
                            fill="#8884d8"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>              
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={this.state.pageLoading}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CircularProgress /> Loading ...
          </Box>
        </Modal>
      </>
    );
  }
}

export default MainDashboard;
