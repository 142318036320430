import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
  Button,
  Checkbox,
  TableContainer,
  TablePagination,
  Grid,
} from "@mui/material";
import { BASE_URL } from "../../../config";
import axios from "axios";
import {
  getMaintenanceData,
  updateMaintenanceDataResolved,
} from "../../../api/MaintenanceManagementApi";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

const tableSx = {
  "& .MuiTableCell-head": {
    backgroundColor: "#f5fafe",
    color: "#033d5f",
    fontWeight: "bold !important",
    fontSize: 14,
  },
};

const MaintenanceDialog = ({ open, handleClose, vehicleInfo }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [maintenanceData, setMaintenanceData] = useState([]);

  useEffect(async () => {
    if (vehicleInfo?.id) {
      try {
        let info = await getMaintenanceData(vehicleInfo.id);
        setMaintenanceData(info);
      } catch (error) {}
    }
  }, [vehicleInfo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePDFDownload = async () => {
    try {
      const response = await axios.get(
        BASE_URL + `/maintenance-management/pdf/${vehicleInfo.id}`,
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${vehicleInfo.license}-${moment().format('YYYY-MM-DD')}.pdf`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleCheckboxChange = async (index) => {
    try {
      const updatedMaintenanceData = [...maintenanceData];
      let info = await updateMaintenanceDataResolved(
        updatedMaintenanceData[index].id,
        !updatedMaintenanceData[index].resolved
      );

      updatedMaintenanceData[index].resolved =
        !updatedMaintenanceData[index].resolved;

      setMaintenanceData(updatedMaintenanceData);
      toast.success("Resolve status successfully updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log("Error", error);
      toast.error("Error update resolve status , Please try again later", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const formatFieldType = (fieldType, item) => {
    switch (fieldType) {
      case "Form":
        item = JSON.parse(item);
        if (item?.length > 0) {
          return item
            .map((info) => `${info.label} : ${info.fieldValue}`)
            .join(",");
        }
        break;

      case "Boolean":
        return item.includes("true") ? "Satisfied" : "Unsatisfied";

      default:
        return item;
    }
  };

  const isCommentRow = (value) => {
    return value === 'remark';
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      height="xl"
    >
      <ToastContainer />
      <DialogTitle style={{ backgroundColor: "#1976D2", color: "white" }}>
        Maintenance Data
      </DialogTitle>
      <DialogContent
        style={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}
      >
        <div
          style={{ display: "flex", flex: "1 1 auto", marginBottom: "16px" }}
        >
          <Card
            style={{
              flex: "0 0 auto",
              marginRight: "10px",
              minWidth: "320px",
              marginTop: "8px",
            }}
          >
            <CardContent>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ color: "#1976D2", marginBottom: "8px" }}
                  >
                    <span style={{ fontWeight: "bold", color: "black" }}>
                      Inspection Detail
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography>Vehicle Name:</Typography>
                  <Typography>Model:</Typography>
                  <Typography>License:</Typography>
                  <Typography>License Expiring In:</Typography>
                  <Typography>Assigned To:</Typography>
                  <Typography>Last Maintenance:</Typography>
                  <Typography>Done by:</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>{vehicleInfo?.vehiclename}&nbsp;</Typography>
                  <Typography>{vehicleInfo?.vehicletype}&nbsp;</Typography>
                  <Typography>{vehicleInfo?.license}&nbsp;</Typography>
                  <Typography>{vehicleInfo?.expiringIn && moment(vehicleInfo.expiringIn, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD')}&nbsp;</Typography>
                  <Typography>{vehicleInfo?.assignedTo}&nbsp;</Typography>
                  <Typography>{vehicleInfo?.lastMaintenance}&nbsp;</Typography>
                  <Typography>{vehicleInfo?.doneBy}&nbsp;</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Paper
            style={{
              flex: "1",
              overflowY: "hidden",
              padding: "10px",
              minWidth: "300px",
              marginTop: "8px",
            }}
          >
            <Typography
              variant="h5"
              style={{ color: "#1976D2", marginBottom: "8px" }}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>
                Inspection Items
              </span>
            </Typography>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" sx={tableSx}>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Resolved</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {maintenanceData.map((entry, index) => (
                    <TableRow key={index} >
                      <TableCell>{entry.groupName}</TableCell>
                      <TableCell style={{ fontStyle: isCommentRow(entry.type) ? 'italic' : 'normal', fontWeight: isCommentRow(entry.type) ? 'bold' : 'normal' }}>{entry.title}</TableCell>
                      <TableCell>
                        {entry.status}
                      </TableCell>
                      <TableCell>
                        {entry.type === "Boolean" && (
                          <Checkbox
                            checked={entry.resolved ? true : false}
                            onChange={() => handleCheckboxChange(index)}
                            color="primary"
                            disabled={entry.value === "false"}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={maintenanceData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handlePDFDownload}>
          PDF Download
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          style={{ marginLeft: "8px" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MaintenanceDialog;
