import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { FormHelperText, Checkbox, Dialog, AppBar, Toolbar, Typography, IconButton, DialogContent, DialogActions, FormControlLabel, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { StyledButton } from '../../../styled';
import { ToastContainer, toast } from 'react-toastify';
import {
  StyledFormControl,
  StyledInputText,
} from "../../../styled";
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";

import {
  createUserLevel,
  updateUserLevel
  } from "../../../api/UserLevelManagementApi"

var md5 = require('md5');

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  buttonContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  dialogContainer: {
    margin: theme.spacing(2)
  },
  customHelperTextError: {
    color: 'red',
    marginLeft: '4px'
  },
  customHelperTextErrorNone: {
    color: '#f0f0f500',
    marginLeft: '4px'
  },
}));

const AddUserLevel = ({ open, onClose, userLevels, userLevel }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel1');
  const [values, setValues] = useState({});
  const action = {action: 'edit'}

  const [formValues, setFormValues] = useState({
    id: null,
    name: '',
    description: '',
    superuser: true
  });

  const [errors, setErrors] = useState({
    name: null,
    description: null
  });

  useEffect(() => {
    if(userLevel) {      
      setFormValues((prevState) => ({
        ...prevState,
        ['id']: userLevel.id,
        ['name']: userLevel.name,
        ['description']: userLevel.description,
        ['superuser']: userLevel.superuser
      }))
    }
  }, [userLevel])

  const handleSave = async() => {
    let newErrors = {};
    let valid = true;

    if (formValues.name.trim() === '') {
      newErrors.name = 'User Level Name is required';
      valid = false;
    }

    if (formValues.description.trim() === '') {
      newErrors.description = 'Description is required';
      valid = false;
    }

    if(!valid) {
      setErrors(newErrors);
      return
    }
    
    let request = {...formValues}

    try {      
      if(request.id) {
        await updateUserLevel(request)
      } else {
        await createUserLevel(request)
      }
      
      toast.success((request.id ? "Updated ! " : "Created ! ") , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      onClose();
    } catch (error) {
      console.log('[Error] : ', error);
      toast.error((request.id ? "Update failed. Please try again later" : "Create failed. Please try again later"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors({
      ...errors,
      [name]: null,
    });
  }

  const handleSelect = (e) => {
      let name = e.target.name;
      let value = e.target.checked ? true : false;
      
      setFormValues((prev) => ({
          ...prev,
          [name]:value
      }))

      setErrors({
        ...errors,
        [name]: null,
      });
  }

  return (
    <>
      <ToastContainer />
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" height="xl" className={classes.dialogContainer}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h3" className={classes.title}>
              {userLevel?.id == null ? "New User Level" : "Edit User Level"}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container>
              <Grid item md={12}>
                  {/*  */}
                  <Grid container>
                    <Grid item md={6}>
                      <StyledFormControl>
                        <StyledFormInputLabel primary={true}>User Level Name</StyledFormInputLabel>
                        <StyledInputText
                          name="name"
                          value={formValues.name}
                          onChange={(e) => handleChange(e)} 
                        />
                        <FormHelperText className={Boolean(errors.name) ? classes.customHelperTextError : classes.customHelperTextErrorNone}>{Boolean(errors.name) ? errors.name : '.'}</FormHelperText>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl>
                        <StyledFormInputLabel primary={true}>Description</StyledFormInputLabel>
                        <StyledInputText
                          name="description"
                          value={formValues.description}
                          onChange={(e) => handleChange(e)} 
                        />
                        <FormHelperText className={Boolean(errors.description) ? classes.customHelperTextError : classes.customHelperTextErrorNone}>{Boolean(errors.description) ? errors.description : '.'}</FormHelperText>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl variant="standard">
                        <StyledFormInputLabel primary={true}>
                          Super User
                        </StyledFormInputLabel>
                        <FormControlLabel  control={
                          <Checkbox 
                              name="superuser"
                              checked={formValues.superuser === true ? true : false}
                              onChange={e=>handleSelect(e)}
                          />} label={formValues.superuser === true ? 'Yes' : 'No'} />
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6} sx={{background: "#efefef"}}>
                      <StyledFormControl variant="standard">
                        
                      </StyledFormControl>
                    </Grid>
                  </Grid>
                  {/*  */}
              </Grid>
          </Grid>
          </DialogContent>
          <DialogActions>
              <StyledButton variant="contained" onClick={(e)=>{handleSave()}}>
                  Save
              </StyledButton>
              <StyledButton variant="outlined" onClick={(e)=>{onClose()}}>
                  Close
              </StyledButton>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default AddUserLevel;
