import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Icon, IconButton, Typography } from "@mui/material";
import EditRounded from "@mui/icons-material/EditRounded"
import DeleteRounded from "@mui/icons-material/DeleteRounded"
import { StyledIconWrapper } from "../../../styled";
import { ErrorOutline, Notes } from "@mui/icons-material";

import {formatPhoneNumber} from "../../../utils/formaters"
import AddressViewPopup from "./AddressViewPopup";
import { toast } from 'react-toastify';
import { getMasAddressInfo } from "../../../api/CustomerManagementApi";
import SpecialNotesView from "./SpecialNotesView";

const columns = [
  { id: "fullName", label: "Name", minWidth: 120 },
  { id: "address", label: "Address", minWidth: 100 },
  { id: "contactnumber", label: "Contact Number", minWidth: 150 },
  { id: "cinno", label: "Medicade Number", minWidth: 100 },
  { id: "soExpireDate", label: "So Expire Date", minWidth: 100 },
  { id: "notes", label: "Notes", minWidth: 100 }
];

function CustomerTable({ customers , setAction, reloadData, totalPages, filter}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [masAddressInfo, setMasAddressInfo] = useState(null);
  const [openSpecialNotesPopup, setOpenSpecialNotesPopup] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    reloadData(page, rowsPerPage, filter);
  }, [page, rowsPerPage, filter])
  
  const handleShowAdressView = async(row) => {
    setSelectedRow(row)
    try {      
      const response = await getMasAddressInfo(row.cinno);
      setMasAddressInfo(response)
    } catch (error) {
      console.log('[Error] : ', error);
      setMasAddressInfo(null)
      toast.error("No information found for the Medicaid ID", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setOpenAddressPopup(true);
  };

  const handleCloseAddressView = () => {
    setOpenAddressPopup(false);
  };

  const handleShowPreferences = (row) => {
    setSelectedRow(row)
    setOpenSpecialNotesPopup(true);
  }

  const handleCloseSpecialNotesView = () => {
    setOpenSpecialNotesPopup(false);
  }

  return (
    <>
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:"red"}}>
            <TableRow style={{fontWeight:"bold", }}>
            <TableCell align="center" style={{fontWeight:"bold" ,fontSize:"14px", color:"#033d5f", backgroundColor:"#f5fafe" }}>
                Action
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth,fontWeight:"bold",fontSize:"14px",color:"#033d5f", backgroundColor:"#f5fafe" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((row) => (
              <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
                <TableCell key={row.id} align="center">
                  <div style={{display:"flex"}}>
                    
                    <IconButton 
                      size="small"
                      onClick={(e)=>setAction({'action':'edit','customer':row})}>
                        <StyledIconWrapper style={{padding:0}}><EditRounded /> </StyledIconWrapper>
                    </IconButton>
                    
                    <IconButton  size="small"
                      onClick={(e)=>setAction({'action':'delete','customer':row})}>
                        <StyledIconWrapper style={{padding:0}}><DeleteRounded  /></StyledIconWrapper> 
                    </IconButton>
                  </div>
                </TableCell>

                {columns.map((column) => {
                  const value = row[column.id];
                  const label = column.id;
                  return (
                    label === 'address' && row.addressUpdated == 1 ? <TableCell key={column.id} align="left">
                    <Typography variant="body1">
                      {value}
                      <IconButton  size="small"
                        onClick={(e) => handleShowAdressView(row)}>
                          <StyledIconWrapper style={{padding:0}}><ErrorOutline  /></StyledIconWrapper> 
                      </IconButton>
                    </Typography>
                    </TableCell> : label === 'notes' ?
                    <TableCell key={column.id} align="left">
                      <IconButton  size="small"
                        onClick={(e) => handleShowPreferences(row)}>
                          <StyledIconWrapper style={{padding:0}}><Notes  /></StyledIconWrapper> 
                      </IconButton>
                    </TableCell>
                    : 
                    <TableCell key={column.id} align="left">
                      {label == "contactnumber" ? formatPhoneNumber(value) : value == true ? "Yes" : value }
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalPages}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    <AddressViewPopup open={openAddressPopup} onClose={handleCloseAddressView} row={selectedRow} masAddressInfo={masAddressInfo}/>
    <SpecialNotesView open={openSpecialNotesPopup} onClose={handleCloseSpecialNotesView} row={selectedRow} />
    </>
  );
}

export default CustomerTable;
