import { React, useState, useEffect, useContext, useMemo } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";


import moment from "moment";

import {StyledButton, StyledDatePicker, StyledFormControl, StyledInputText, StyledMenuItem, StyledSelect} from "../../../styled";
import RefdataContext from "../../../context/RefdataContext";


const formElems = {
    county:"All",
    tripType:"Eligible",
    standingOrder:"Any",
  }

const radioOptions = ["Yes","No","Any"];

function FilterBy({setFilters}) {

    const [values, setValues] = useState(formElems);
    const [counties, setCounties] = useState([]);
    const [tripStatus, setTripStatus] = useState([]);
    
    
    const {countyList,tripStatusList } = useContext(RefdataContext)

    useEffect(()=>{
      setCounties(counties=> [...counties, {"id":"All","name":"All"}, ...countyList])
      setTripStatus(tripStatusList.sort())
    },[])



    function handleInputChange(e) {
      const {name, value} = e.target;
      setValues({
        ...values,
        [name] : value,
      })
    }
  

    return (
        <Grid container>
          <Grid item md={12} sm={12}>
            <form>
                <Grid container  justifyContent="space-between" direction="row">
                    <Grid item md={3} lg={2} sm={null} xs={null}>
                    <StyledFormControl variant="standard">
                        <Typography  sx={{
                        fontWeight:800
                        }}>County</Typography>
                        <StyledSelect 
                            id="county"
                            name="county"
                            value={values.county}
                            onChange={(e)=>{handleInputChange(e)}}>
                            { 
                                counties?.map((c, idx) => {
                                    return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                })
                            }
                        </StyledSelect>
                    </StyledFormControl>
                    </Grid>
                    <Grid item md={3} lg={2} sm={null} xs={null}>
                    <StyledFormControl variant="standard">
                    <Typography  sx={{
                        fontWeight:800
                        }}>Trip Status</Typography>
                        <StyledSelect 
                            id="tripType"
                            name="tripType"
                            value={values.tripType}
                            onChange={(e)=>{handleInputChange(e)}}>
                            { 
                                tripStatus?.map((s, idx) => {
                                    return (<StyledMenuItem key={idx} value={s.name}>{s.name}</StyledMenuItem>)
                                })
                            }
                        </StyledSelect>
                    </StyledFormControl>
                    </Grid>
                    <Grid item md={3} lg={2} sm={null} xs={null}>
                    <StyledFormControl>
                    <Typography  sx={{
                        fontWeight:800
                        }}>Is Standing Order</Typography>
                        <RadioGroup
                            id="standingOrder"
                            name="standingOrder"
                            row
                            value={values.standingOrder}
                            onChange={(e)=>{handleInputChange(e)}}
                            
                        >
                            {
                                radioOptions?.map((ro,idx) =>{
                                    return (<FormControlLabel key={idx} value={ro} control={<Radio />} label={ro} />)
                                })
                            }
                        </RadioGroup>
                    </StyledFormControl>
                    </Grid>
                    <Grid item md={3} lg={6} sm={null} xs={null}>
                      <StyledFormControl 
                          style={{
                            width:"100%",
                          }}>
                          <div style={{
                            marginTop:"16px",
                            width:"100%",
                            textAlign:"right",
                            justifyContent:"center",
                          }}>
                          <StyledButton 
                            variant={"contained"}  
                            onClick={(e)=>{console.log("Setting -", values);setFilters(values)}}
                            theme >
                            Filter
                          </StyledButton>
                        </div>
                      </StyledFormControl>
                    </Grid>
                </Grid>
            </form>
          </Grid>
        </Grid>
    )
  }  

  export default FilterBy;