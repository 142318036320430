import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { StyledInputText } from "../../../styled";

const UserSearch = ({ setFilter}) => {
  const [query, setQuery] = useState("");

  useEffect(()=>{
    setFilter(query);
  },[query])

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div>
        <StyledInputText
          id="searchUser"
          name="searchUser"
          placeholder="Search"
          style={{borderRadius:"1rem"}}
          onChange={handleSearch}
        />
        <SearchIcon 
        style={{
          position:"relative",
          marginBottom:"-.4rem",
          marginLeft:"-1.5rem"
        }}/>
      </div>
    </div>
  );
};

export default UserSearch;
