
// import { BASE_URL } from "../config";


const axios  = require('axios');
const config = require("../config")

const BASE_URL = `${config.BASE_URL}/dispatch`;


async function getTripSchedules(searchData){
    return new Promise(async (resolve, reject) =>{
        try{
        let response = await axios.post(`${BASE_URL}/schedule-view`, searchData)
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

async function createTripSchedules(schdules){
    return new Promise(async (resolve, reject) =>{
        try{
        let response = await axios.post(`${BASE_URL}/schdule-crete-or-update`, schdules)
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

async function sendDispatchNotification(data){
    return new Promise(async (resolve, reject) =>{
        try{
        let response = await axios.post(`${BASE_URL}/send-dispatch-notification`, data)
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.response.data)
       }
    })
}

async function updateTripSchedules(schdules){
    return new Promise((resolve, reject) =>{
        
    })
}

async function deleteTripSchedules(schdules){
    return new Promise((resolve, reject) =>{
        
    })
}

// module.exports={
//     createTripSchedules,
//     getTripSchedules,
// }

export {createTripSchedules,
    getTripSchedules, sendDispatchNotification}