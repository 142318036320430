import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { BASE_URL } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var md5 = require("md5");


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://massapp.com/">
      DKCare LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ChangePassword(props) {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const [user, setUser] = useState();

  let loggedUser = { active: false, name: "" };
  if (localStorage.getItem("session")) {
    loggedUser = JSON.parse(localStorage.getItem("session"));
  }
  console.log(loggedUser);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password != "" && password == passwordAgain) {
      let user = { userName, password };
      user.key = loggedUser.key;
      user.password = md5(user.password);
      // send the username and password to the server
      try {
        const response = await axios.post(
          BASE_URL + "/user/changepassword",
          user
        );
        // set the state of the user
        //setUser(response.data);
        // store the user in localStorage
       // localStorage.setItem("session", JSON.stringify(response.data));

        console.log(response.data);
       // props.onLogin(response.data);
        toast.success("Welcome ! ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });


        user.active = false ;  props.onLogin(user);
      } catch (error) {
        console.log("error Print");
        console.log(error);
        if(error.response.data){
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
      }
    } else {
        if (password == "" ) {
            toast.error("Password can not be empty ", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
        }
        if (password != passwordAgain) {
            toast.error("Password mismatch ", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
        }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <ToastContainer />
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            User : {loggedUser.userName} | {loggedUser.userFullName}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              onChange={({ target }) => setPassword(target.value)}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwordAgain"
              label="Password Again"
              onChange={({ target }) => setPasswordAgain(target.value)}
              type="password"
              id="passwordAgain"
              autoComplete="password-again"
            />
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
            >
              Change password
            </Button>
            <Button
              onClick={() =>{ 
                let user = {}
                user.key = loggedUser.key;
                 user.active = false ;  
                  props.onLogin(user);
                } } 
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
            >
              <LogoutIcon /> Log Out
            </Button>
             
            {/*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>  */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
