import { React, useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";


import moment from "moment";

import {StyledButton, StyledDatePicker, StyledFormControl, StyledInputText} from "../../../styled";


const formElems = {
    invoiceNumber:"",
    medicaidNumber:"",
    patientName:"",
    dateFrom: moment(new Date()).format("YYYY-MM-DD"),
    dateTo:moment(new Date()).format("YYYY-MM-DD"),
  }
  //  dateTo:moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
function SearchBy({setSearch, setSearchParams}) {
    const [values, setValues] = useState(formElems);

    useEffect(()=>{
      setSearchParams(values);
      setSearch(true)
    },[]);
    
    function handleInputChange(e) {
      const {name, value} = e.target;
      setValues({
        ...values,
        [name] : value,
      })
    }

    function handleDates(name, value){
      setValues({
        ...values,
        [name] : value,
      })
    }

    function search(){
      setSearchParams(values);
      setSearch(true)
    }
    return (
        <Grid container p={0}>
          <Grid item md={12} sm={12} p={0}>
            <form >
              <Grid container  justifyContent="space-between" direction="row" p={0}>
                <Grid item md={3} lg={2} sm={null} xs={null} p={0}>
                  <StyledFormControl>
                    <Typography  sx={{
                      fontWeight:800
                    }}>Invoice Number</Typography>
                    <StyledInputText 
                      id="invoiceNumber"
                      name="invoiceNumber"
                      // label="Invoice Number"
                      value={values.invoiceNumber}
                      onChange={(e)=>{handleInputChange(e)}}>
                    </StyledInputText>
                  </StyledFormControl>
                </Grid>
                <Grid item md={3} lg={2} sm={null} xs={null}>
                  <StyledFormControl>
                  <Typography  sx={{
                      fontWeight:800
                    }}>Medicaid Number</Typography>
                    <StyledInputText 
                      id="medicaidNumber"
                      name="medicaidNumber"
                      // label="Invoice Number"
                      value={values.medicaidNumber}
                      onChange={(e)=>{handleInputChange(e)}}>
                    </StyledInputText>
                  </StyledFormControl>
                </Grid>
                <Grid item md={3} lg={2} sm={null} xs={null}>
                  <StyledFormControl>
                  <Typography  sx={{
                      fontWeight:800
                    }}>Customer Name</Typography>
                    <StyledInputText 
                      id="patientName"
                      name="patientName"
                      // label="Invoice Number"
                      value={values.patientName}
                      onChange={(e)=>{handleInputChange(e)}}>
                    </StyledInputText>
                  </StyledFormControl>
                </Grid>
                <Grid item md={3} lg={6} sm={null} xs={null}>
                  <Grid container style={{
                    alignText:"center",
                    justifyContent:"center"
                  }}>
                    <Grid item md={4} lg={4} sm={6} xs={6}>
                      <StyledFormControl>
                      <Typography  sx={{
                          fontWeight:800
                        }}>From</Typography>
                          <StyledDatePicker
                            type="date"
                            id="dateFrom"
                            name="dateFrom"
                            value={values.dateFrom?values.dateFrom:moment(new Date()).format("YYYY-MM-DD")}
                            onChange={(e)=>{handleInputChange(e)}}
                          />
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={4} lg={4} sm={6} xs={6}>
                      <StyledFormControl>
                        <Typography  sx={{
                            fontWeight:800
                          }}>To</Typography>
                          <StyledDatePicker
                              type="date"
                              id="dateTo"
                              name="dateTo"
                              value={values.dateTo?values.dateTo:moment(new Date()).format("YYYY-MM-DD")}
                              onChange={(e)=>{handleInputChange(e)}}
                            />
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={4} lg={4} sm={null} xs={null}>
                      <StyledFormControl 
                          style={{
                            width:"100%",
                          }}>
                          <div style={{
                            marginTop:"16px",
                            width:"100%",
                            textAlign:"right",
                            justifyContent:"center",
                          }}>
                          <StyledButton 
                            variant={"contained"}  
                            onClick={(e)=>{search()}}
                            theme >
                            Search
                          </StyledButton>
                        </div>
                      </StyledFormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
    )
  }  

  export default SearchBy;