import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import {
  Grid,
  Stack,
  TextField,
  ListItemAvatar,
  ListItemText,
  List,
  Avatar,
  ListItemButton,
  InputAdornment,
  IconButton,
  ListItem,
  LinearProgress,
} from "@mui/material";
import FleetPreviewMap from "./FleetPreviewMap";
import FleetActivityHeader from "./FleetActivityHeader";
import { styled } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

import {
  getFleetActivities,
  getFleetDiversWithSummay,
  getGPSDateForInvoice,
} from "../../../api/FleetActivityApi";
import TablePagination from "@mui/material/TablePagination";
import { StyledDatePicker, StyledFormControl } from "../../../styled";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import Brightness1Icon from "@mui/icons-material/Brightness1";

const driverInfo = makeStyles((theme) => ({
  smallText: {
    fontSize: theme.typography.caption.fontSize,
    display: "flex",
    alignItems: "center",
  },
  iconActive: {
    marginRight: theme.spacing(1),
    color: "#52f548",
  },
  iconOffline: {
    marginRight: theme.spacing(1),
    color: "#767676",
  },
}));

const DriverFilterText = styled(TextField)({
  backgroundColor: "#ffffff",
  boxShadow: "inset 0 0 4px #46443f",
  padding: "3px",
  borderRadius: "4px",
  width: "100%",
  marginLeft: "-5px",
  "& .MuiInputBase-input": {
    border: "0px solid #bedfff !important",
  },
});

const FleetActivity = () => {
  const classes = driverInfo();

  const [allDrivers, setAllDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [reload, setReload] = useState(true);
  const [user, setUser] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [errors, setErrors] = useState(undefined);
  const [currentDriver, setCurrentDriver] = useState(null);
  const [driverText, setDriverText] = useState("");
  const [modleProgress, setModleProgress] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [trips, setTrips] = useState([]);
  const [fleetActivirtyPreviewInfo, setFleetActivirtyPreviewInfo] = useState({
    mainMarkers: [],
    markers: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  // Table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const columns = [
    { id: "driverFullName", label: "Driver", minWidth: "220", width: "10%" },
    { id: "name", label: "Client Name", minWidth: 200, width: "15%" },
    {
      id: "pickupaddress",
      label: "Pick-up Address",
      minWidth: 300,
      width: "20%",
    },
    {
      id: "dropaddress",
      label: "Drop-off Address",
      minWidth: 300,
      width: "20%",
    },
    {
      id: "startingdateime",
      label: "Pick-up Time",
      minWidth: 200,
      align: "right",
      width: "10%",
    },
    {
      id: "endingdatetime",
      label: "Drop-off time",
      minWidth: 200,
      align: "right",
      width: "10%",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 200,
      align: "right",
      width: "10%",
    },
  ];

  useEffect(() => {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }
    setUser(user);
  }, []);

  useEffect(async () => {
    if (reload && selectedDate) {
      await loadDrivers(selectedDate);
      setReload(false);
    }
  }, [reload, selectedDate]);

  const loadDrivers = useCallback(async (selectedDate) => {
    const driverList = await getFleetDiversWithSummay({
      date: selectedDate,
    });

    let _dkDrivers = driverList.map((d) => {
      return {
        ...d,
        ["fullName"]: `${d.firstName} ${d.lastName}`,
      };
    });
    setAllDrivers(_dkDrivers);
    setFilteredDrivers(_dkDrivers);
  }, []);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleDriverChange = useCallback(
    async (event, index) => {
      setCurrentDriver(null);
      setSelectedRows([]);
      setCurrentDriver(filteredDrivers[index]);

      if (filteredDrivers[index] && selectedDate) {
        await loadFleetActivities(filteredDrivers[index].id, selectedDate);
      }
    },
    [selectedDate, filteredDrivers]
  );

  useEffect(async () => {
    if (!currentDriver && selectedDate && allDrivers.length > 0) {
      await loadFleetActivities(null, selectedDate);
    }
  }, [currentDriver, selectedDate, allDrivers]);

  const loadFleetActivities = async (driverId, selectedDate) => {
    try {
      setIsLoading(true)
      setFleetActivirtyPreviewInfo({ mainMarkers: [], markers: [] });

      let fleetActRequest = {
        driverId: driverId,
        date: selectedDate,
      };

      const response = await getFleetActivities(fleetActRequest);
      setTrips(
        response.map((item) => ({
          ...item,
          driverFullName: allDrivers.find((driver) => driver.id == item.driver)
            ?.fullName,
        }))
      );

      setModleProgress(false);
      setIsLoading(false)
    } catch (e) {
      console.log("[Error]", e);
      setModleProgress(false);
      setIsLoading(false)
      toast.error(
        "Error fetching data , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowClick = (id) => {
    const isSelected = selectedRows.includes(id);

    const newSelectedRows = isSelected
      ? selectedRows.filter((rowId) => rowId !== id)
      : [id];

    setSelectedRows(newSelectedRows);
  };

  const isSelected = (id) => selectedRows.includes(id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedRows.length > 0) {
          const tripInfo = trips.find((trip) => trip.id === selectedRows[0]);

          setCurrentDriver(
            filteredDrivers.find(
              (driverInfo) => driverInfo.id == tripInfo.driver
            )
          );

          const gpsData = await getGPSDateForInvoice({
            invoice: tripInfo.invoice,
          });

          let fleetPreviewData = {};

          if (gpsData.length == 0) {
            fleetPreviewData = {
              tripStatus: tripInfo.status,
              mainMarkers: [
                {
                  id: 1,
                  position: {
                    lat: Number(tripInfo.startinglat),
                    lng: Number(tripInfo.startinglong),
                  },
                  title: tripInfo.name,
                  address: tripInfo.pickupaddress,
                  time: tripInfo.startingdateime,
                  status:
                    tripInfo.gpsStatus != null
                      ? tripInfo.gpsStatus.replaceAll("_", " ")
                      : tripInfo.status,
                },
                {
                  id: 2,
                  position: {
                    lat: Number(tripInfo.endinglat),
                    lng: Number(tripInfo.endinglong),
                  },
                  title: tripInfo.name,
                  address: tripInfo.dropaddress,
                  time: tripInfo.endingdatetime,
                  status:
                    tripInfo.gpsStatus != null
                      ? tripInfo.gpsStatus.replaceAll("_", " ")
                      : tripInfo.status,
                },
              ],
              markers: [],
            };
          } else {
            fleetPreviewData = {
              tripStatus: tripInfo.status,
              mainMarkers: [
                {
                  id: 1,
                  position: {
                    lat: Number(gpsData[0].latitude),
                    lng: Number(gpsData[0].longitude),
                  },
                  title: tripInfo.name,
                  address: tripInfo.pickupaddress,
                  time: tripInfo.startingdateime,
                  status:
                    tripInfo.gpsStatus != null
                      ? tripInfo.gpsStatus.replaceAll("_", " ")
                      : tripInfo.status,
                },
                {
                  id: 2,
                  position: {
                    lat: Number(gpsData[gpsData.length - 1].latitude),
                    lng: Number(gpsData[gpsData.length - 1].longitude),
                  },
                  title: tripInfo.name,
                  address: tripInfo.dropaddress,
                  time: tripInfo.endingdatetime,
                  status:
                    tripInfo.gpsStatus != null
                      ? tripInfo.gpsStatus.replaceAll("_", " ")
                      : tripInfo.status,
                },
              ],
              markers: gpsData.map((gps) => ({
                id: gps.id,
                position: {
                  lat: Number(gps.latitude),
                  lng: Number(gps.longitude),
                },
              })),
            };
          }

          console.log("tripInfo: ", tripInfo);
          setFleetActivirtyPreviewInfo(fleetPreviewData);
        } else {
          setFleetActivirtyPreviewInfo({ mainMarkers: [], markers: [] });
        }

        setModleProgress(false);
      } catch (e) {
        console.log("[Error] ", e);
        setModleProgress(false);
        toast.error(
          "Error fetching data , Please check your internet connection  ",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    };

    fetchData();
  }, [selectedRows]);

  const handleSetTripDate = useCallback(
    (e) => {
      setSelectedDate(e.target.value);
      loadDrivers(e.target.value);
      if (currentDriver != null) {
        loadFleetActivities(currentDriver.id, e.target.value);
      }
    },
    [currentDriver]
  );

  const handleDriverTextChange = useCallback(
    (event) => {
      const value = event ? event.target.value : "";
      setDriverText(value);
      if (value === "") {
        setFilteredDrivers(allDrivers);
      } else {
        const filtered = allDrivers.filter((item) =>
          item.fullName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredDrivers(filtered);
      }
    },
    [allDrivers]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      {/* <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="error" onClose={handleCloseSnack}>
            {errors?.map((e) => {
              return <li>{e}</li>;
            })}
          </Alert>
        </Snackbar>
      </Stack> */}
      <ToastContainer />
      <FleetActivityHeader />
      <Grid container>
        <Grid
          item
          xs={9}
          md={9}
          style={{
            height: "calc(100vh - 460px)",
          }}
        >
          <FleetPreviewMap
            currentDriver={currentDriver}
            previewInfo={fleetActivirtyPreviewInfo}
            selectedDate={selectedDate}
            allDrivers={allDrivers}
          />
        </Grid>
        <Grid container item xs={3} md={3}>
          <Stack spacing={2} sx={{ width: 1 }}>
            <Grid item>
              <StyledFormControl>
                <Typography
                  sx={{
                    fontWeight: 800,
                  }}
                >
                  Trip Date
                </Typography>
                <StyledDatePicker
                  type="date"
                  id="dateFrom"
                  name="dateFrom"
                  value={selectedDate}
                  onChange={(e) => {
                    handleSetTripDate(e);
                  }}
                />
              </StyledFormControl>
            </Grid>

            <Grid item sx={{ paddingLeft: "10px" }}>
              <DriverFilterText
                hiddenLabel
                id="diver-text-filter"
                value={driverText}
                placeholder="Filter Driver"
                onChange={handleDriverTextChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {driverText && (
                        <IconButton
                          onClick={() => handleDriverTextChange(null)}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: "8px" }}>
              <Paper
                style={{
                  overflow: "auto",
                  maxHeight: "calc(100vh - 615px)",
                  width: "100%",
                }}
              >
                <List>
                  {filteredDrivers
                    .filter((d) => d.tripsschedule_count > 0)
                    .map((item, idx) => (
                      <ListItemButton
                        key={item.id}
                        selected={currentDriver?.id === item.id}
                        onClick={(event) => handleDriverChange(event, idx)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={item.avatarUrl}
                            alt={`Avatar for ${item.fullName}`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.fullName}
                          secondary={
                            <>
                              {item.tripsschedule_count > 0 ? (
                                <Typography
                                  variant="caption"
                                  className={classes.smallText}
                                >
                                  <Brightness1Icon
                                    color="secondary"
                                    className={classes.iconActive}
                                  />
                                  Active
                                </Typography>
                              ) : (
                                <Typography
                                  variant="caption"
                                  className={classes.smallText}
                                >
                                  <Brightness1Icon
                                    color="secondary"
                                    className={classes.iconOffline}
                                  />
                                  Offline
                                </Typography>
                              )}

                              <Typography
                                variant="caption"
                                className={classes.smallText}
                              >
                                # Trips: {item.tripsschedule_count}
                              </Typography>
                            </>
                          }
                        />
                      </ListItemButton>
                    ))}

                  {filteredDrivers.filter((d) => d.tripsschedule_count > 0)
                    ?.length == 0 && (
                    <ListItem>No Active Drivers found</ListItem>
                  )}
                </List>
              </Paper>
            </Grid>
          </Stack>
        </Grid>
        <Grid container item>
          <Paper sx={{ overflow: "hidden", width: "100%" }}>
            <TableContainer sx={{ height: "220px" }}>
              {isLoading ? (
                <LinearProgress />
              ) : (
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ width: column.width }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trips
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                            selected={isSelected(row.code)}
                            onClick={() => handleRowClick(row.id)}
                            style={{
                              cursor: "pointer",
                              backgroundColor: isSelected(row.id)
                                ? "#e0f7fa"
                                : "inherit",
                            }}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              if (
                                column.id == "status" &&
                                row.gpsStatus != null
                              ) {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {row.gpsStatus.replaceAll("_", " ")}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={trips.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default FleetActivity;
