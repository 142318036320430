import { Grid } from "@mui/material";
import { StyledHeader } from "../../../styled";

const UserManagementHeader = () => {
    return (
        <Grid container>
            <Grid item md={12}>
                <StyledHeader>
                    User Management
                </StyledHeader>
            </Grid>
        </Grid>  
    );
}

export default UserManagementHeader;