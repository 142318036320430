import axios from "axios";
const config = require("../config");

const BASE_URL = `${config.BASE_URL}`;

const handleErrors = (response) => {
  if (response.status < 200 && response.status > 299) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return response.data;
};

const getHeaders = () => {
  let _user = { active: false, name: "" };
  if (localStorage.getItem("session")) {
    _user = JSON.parse(localStorage.getItem("session"));
  }
  const headers = {
    "Content-Type": "application/json",
    "x-auth-key": _user.key,
  };

  return headers;
};

const getFleetActivities = async (fleetActRequest) => {
  try {
    const response = await axios.post(
      BASE_URL + "/scheduler/fleetactivity",
      fleetActRequest,
      {
        headers: getHeaders(),
      }
    );
    return handleErrors(response);
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

const getFleetDiversWithSummay = async (fleetActRequest) => {
  try {
    const response = await axios.post(
      BASE_URL + "/scheduler/fleetactivity/summary",
      fleetActRequest,
      {
        headers: getHeaders(),
      }
    );
    return handleErrors(response);
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

const getDriversLiveLocation = async (request) => {
  try {
    const response = await axios.post(
      BASE_URL + "/scheduler/fleetactivity/livelocation",
      request,
      {
        headers: getHeaders(),
      }
    );
    return handleErrors(response);
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

const getGPSDateForInvoice = async (request) => {
  try {
    const response = await axios.post(
      BASE_URL + "/scheduler/fleetactivitygps",
      request,
      {
        headers: getHeaders(),
      }
    );
    return handleErrors(response);
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

export { getFleetActivities, getDriversLiveLocation, getGPSDateForInvoice, getFleetDiversWithSummay };
