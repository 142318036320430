import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Typography } from "@mui/material";
import React, {useState, useEffect, useContext, useRef} from "react";
import { StyledButton, 
    StyledFormControl, 
    StyledFrame, 
    StyledInputText, 
    StyledMap, 

} from "../../../styled";

import PinDrop from '@mui/icons-material/PinDrop'
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";



const config = require("../../../config")
const staticURL = config.BASE_URL;

function AddressDetailView({values, setValues}){
    const [showMap, setShowMap] = useState(false);
    const addressref = useRef(null);
    const changeRef =(value)=>{addressref.current = value}
    
    function GoogleMapComponent({address, lat, lng}){
        return(
            <StyledMap 
            address={address}
            lat={lat}
            lng={lng}
            changeRef={changeRef}/>
        )
    }

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        
        setValues({
            ...values,
            [name]:value
        })
    }

    function handleSelectCoordinate(){
        if(addressref != null){
            setValues({
                ...values,
                ["lat"]:addressref.current.lat,
                ["lng"]:addressref.current.lng,
            })
        }
    }
    function handleClose(){
        setShowMap(false);
    }

    function openMap(){
        !showMap?setShowMap(true):false;
    }

    return(
        <StyledFrame>
            <Grid container>
            <Grid item md={12} style={{marginTop: '22px', marginBottom: '10px'}}>DISPATCH ADDRESS</Grid>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={10}>
                            <StyledFormControl>
                                <StyledFormInputLabel
                                    primary={true}>
                                    Address
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    name="address"
                                    value={values.address} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={2} style={{
                                display:"flex",
                                alignItems:"end",
                            }} >
                            <IconButton onClick={()=>openMap()}>
                                {
                                    showMap?
                                    <div>
                                        <Dialog  onClose={handleClose} open={showMap}>
                                        <DialogContent style={{width:"500px", height:"500px"}}>
                                            <GoogleMapComponent
                                            address={values.address}
                                            lat={(values.lng != null && values.lat != null ? values.lat : '')}
                                            lng={(values.lng != null && values.lat != null ? values.lng : '')}/>
                                        </DialogContent>
                                        <DialogActions>
                                            <StyledButton variant="contained" onClick={(e)=>{handleSelectCoordinate()}}>
                                                Select coordinates
                                            </StyledButton>
                                            <StyledButton variant="outlined" onClick={(e)=>{handleClose()}}>
                                                Close Map
                                            </StyledButton>
                                        </DialogActions>
                                    </Dialog>  
                                    </div>
                                      :<PinDrop fontSize="large" style={{color:"green"}}/>
                                }
                                
                            </IconButton>
                        </Grid>   
                        <Grid item md={4}>
                            <StyledFormControl>
                            <StyledFormInputLabel
                                    primary={false}>
                                    Lattitude
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    name="lat"
                                    disabled={true}
                                    value={values.lat} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={4}>
                            <StyledFormControl>
                            <StyledFormInputLabel
                                    primary={false}>
                                    Longtitude
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    name="lng"
                                    disabled={true}
                                    value={values.lng} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>   
                    </Grid>
                </Grid>
            </Grid>
        </StyledFrame>
    )
}
export default AddressDetailView;