import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { StyledInputText } from "../../../styled";
import DriverTable from "./DriverTable";




const DriverSearch = ({driverList, setFilteredDrivers}) => {
  const [query, setQuery] = useState("");
  

  useEffect(()=>{
    setFilteredDrivers(driverList.filter((driver) =>
      driver.firstName.toLowerCase().includes(query.toLowerCase())
    ));
  },[query])

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };



  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div>
        <StyledInputText
          id="searchDriver"
          name="searchDriver"
          placeholder="Search"
          style={{borderRadius:"1rem"}}
          onChange={handleSearch}
        />
        <SearchIcon 
        style={{
          position:"relative",
          marginBottom:"-.4rem",
          marginLeft:"-1.5rem"
        }}/>
      </div>
    </div>
  );
};

export default DriverSearch;
