import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid, Switch } from '@mui/material';

const NotificationView = ({ open, onClose, notificationData }) => {
  const [content, setContent] = useState({});
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setContent(notificationData ? notificationData.content : {});
    setChecked(false);
  }, [notificationData]);

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth >
      <DialogTitle style={{ backgroundColor: '#f5fafe', color: '#033d5f!important', fontWeight: 'bold' }}>Dispatch Notification</DialogTitle>
      <DialogContent>
      <Grid container>
        <Grid item xs={4} style={{marginTop: 10, marginBottom: 10}}>
          <div>Driver</div>
        </Grid>
        <Grid item xs={8} style={{marginTop: 10, marginBottom: 10}}>
          <div>{notificationData?.receiverName}</div>
        </Grid>
        <Grid item xs={4} style={{marginTop: 10, marginBottom: 10}}>
          Customer Name
        </Grid>
        <Grid item xs={8} style={{marginTop: 10, marginBottom: 10}}>
          <div>{notificationData?.customerName}</div>
        </Grid>
        <Grid item xs={4}>
          Message
        </Grid>
        <Grid item xs={8} style={{ border: '1px solid #ceeafd' }}>
          <TextField
            id="outlined-basic"
            placeholder="Message"
            value={notificationData?.message}
            onChange={handleContentChange}
            fullWidth
            required
            multiline
            rows={10}
          />
        </Grid>
      </Grid>
        
        
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationView;
