import { React, useState, useEffect, useCallback, useMemo } from "react";
import { Box, FormControl, Grid, Typography } from "@mui/material";

import moment from "moment";

import {
  StyledButton,
  StyledDatePicker,
  StyledFormControl,
  StyledMenuItem,
  StyledSelect,
} from "../../../styled";
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";

const formElems = {
  vehiclenameAndLicense: '',
  maintainBy: '',
  maintenanceType: '',
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
};

function SearchBy({ setSearch, setSearchParams, drivers, vehicles }) {
  const [values, setValues] = useState(formElems);

  const maintenanceTypes = [
    { id: 1, name: "Weekly" },
    { id: 2, name: "Daily" },
  ]

  useEffect(() => {
    setSearchParams(values);
    setSearch(true);
  }, []);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  }

  function handleDates(name, value) {
    setValues({
      ...values,
      [name]: value,
    });
  }

  function search() {
    setSearchParams(values);
    setSearch(true);
  }
  return (
    <Grid container p={0}>
      <Grid item md={12} sm={12} p={0}>
        <form>
          <Grid container justifyContent="space-between" direction="row" p={0}>
            <Grid item md={3} lg={2} sm={null} xs={null} p={0}>
              <StyledFormControl variant="standard">
                <StyledFormInputLabel primary={false}>
                  Vehicle Name
                </StyledFormInputLabel>
                <StyledSelect
                  name="vehiclenameAndLicense"
                  value={values.vehiclenameAndLicense}
                  defaultValue={values.vehiclenameAndLicense}
                  onChange={(e) => handleInputChange(e)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {vehicles?.map((c, idx) => {
                    return (
                      <StyledMenuItem key={idx} value={c.id}>
                        {c.vehiclenameAndLicense}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </StyledFormControl>
            </Grid>
            <Grid item md={3} lg={2} sm={null} xs={null} p={0}>
              <StyledFormControl variant="standard">
                <StyledFormInputLabel primary={false}>
                Maintenance By
                </StyledFormInputLabel>
                <StyledSelect
                  name="maintainBy"
                  value={values.maintainBy}
                  defaultValue={values.maintainBy}
                  onChange={(e) => handleInputChange(e)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {drivers?.map((c, idx) => {
                    return (
                      <StyledMenuItem key={idx} value={c.id}>
                        {c.fullName}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </StyledFormControl>
            </Grid>
            {/* <Grid item md={3} lg={2} sm={null} xs={null} p={0}>
              <StyledFormControl variant="standard">
                <StyledFormInputLabel primary={false}>
                Maintenance Type
                </StyledFormInputLabel>
                <StyledSelect
                  name="maintenanceType"
                  value={values.maintenanceType}
                  defaultValue={values.maintenanceType}
                  onChange={(e) => handleInputChange(e)}
                >
                  {maintenanceTypes?.map((c, idx) => {
                    return (
                      <StyledMenuItem key={idx} value={c.id}>
                        {c.name}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </StyledFormControl>
            </Grid>             */}
            <Grid item md={3} lg={2} sm={null} xs={null} p={0}>
              <StyledFormControl>
                <Typography
                  sx={{
                    fontWeight: 800,
                  }}
                >
                  From
                </Typography>
                <StyledDatePicker
                  type="date"
                  id="dateFrom"
                  name="dateFrom"
                  value={
                    values.dateFrom
                      ? values.dateFrom
                      : moment(new Date()).format("YYYY-MM-DD")
                  }
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={3} lg={2} sm={null} xs={null} p={0}>
              <StyledFormControl>
                <Typography
                  sx={{
                    fontWeight: 800,
                  }}
                >
                  To
                </Typography>
                <StyledDatePicker
                  type="date"
                  id="dateTo"
                  name="dateTo"
                  value={
                    values.dateTo
                      ? values.dateTo
                      : moment(new Date()).format("YYYY-MM-DD")
                  }
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item md={3} lg={2} sm={null} xs={null} p={0}>
              <StyledFormControl
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    marginTop: "16px",
                    width: "100%",
                    textAlign: "left",
                    justifyContent: "center",
                  }}
                >
                  <StyledButton
                    variant={"contained"}
                    onClick={(e) => {
                      search();
                    }}
                    theme
                  >
                    Search
                  </StyledButton>
                </div>
              </StyledFormControl>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default SearchBy;
