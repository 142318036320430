import React from "react";
import { StyledButton, StyledHeader, StyledIconWrapper, StyledWrapper } from "../../../styled";
import { Grid, IconButton } from "@mui/material";
import { AddCircleOutline, ListAltOutlined, Padding } from "@mui/icons-material";

const FleetActivityHeader = ({setAction,  action}) => {
  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <StyledHeader>
          Fleet Activities Preview
        </StyledHeader>
      </Grid>
    </Grid>  
  );
};

export default FleetActivityHeader;
