import React, { Component } from "react";

import axios from "axios";
import { BASE_URL } from "../../../config";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Button from "@mui/material/Button";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CustomRadioGroup from "../../components/CustomRadioGroup";
import InvoiceDialog from "../../components/InvoiceDialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Card, Autocomplete, TextField, Alert } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import LinearProgress from "@mui/material/LinearProgress";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "@mui/material/Modal";
import SearchIcon from "@mui/icons-material/Search";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { styled } from "@mui/material/styles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

import dayjs, { Dayjs } from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import RosterDisplayOne from "./rosterDisplayOne.js";
import RosterDisplayTwo from "./rosterDisplayTwo.js";
import Search from "./search";
import { AcUnit, AlignHorizontalLeft, ArrowRight, East, LibraryAdd, TrendingFlatIcon } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CardRoot = styled(Card)(() => ({
  height: "100%",
  padding: "20px 24px",
  borderRadius: 25,
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  boxShadow: "none",
  // boxShadow: "rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important",
}));

class TripSearch extends Component {
  constructor(props) {
    super(props);
    // let url = new URL(window.location.href);

    this.state = {
      searchForm: {
        searchstring: "Pulling today's data from mas system ...  ",
        resultsDisplay: "display1",
        searchFromDOB: "",
        searchToDOB: "",
        searchName: "",
        searchCINNumber: "",
        searchInvoiceNumber: "",
        searchServiceEndsFrom: "",
        searchTransportType: null,
        searchServiceEndsTo: "",
        searchCounty: null,
        searchServiceStartsFrom: moment().format("MM/DD/YYYY"),
        searchServiceStartsTo: "",
        page: 0,
        per_page: 0,
        total: 0,
        total_pages: 0,
        freshFetch: true,
        fetching: true,
        excelExport: false,
        pdfExport: false,
        sort: { field: "pickupDate", order: "ASC" },
        tripStatus: [],
      },
      tablerows: { data: [], page: 0, per_page: 1, total: 71, total_pages: 7 },
      selectedInvoiceOpen: false,
      selectedInvoice: {},
      tripSortByList: [],
      countries: [],
      countyList: [],
      tripStatusList: [],
      pASubmissionResultsList: [],
      correctionList: [],
      exportStatusList: [],
      transportTypeList: [],
      transportProviderList: [],

      modleProgress: true,
      openSearch: true,
      searchstring: "Pulling today's data from mas system ...  ",
      user: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.setSearchForm = this.setSearchForm.bind(this);
    this.setSearchFormAutoComplete = this.setSearchFormAutoComplete.bind(this);
    this.setSearchFormDate = this.setSearchFormDate.bind(this);
    this.setSearchFormCustom = this.setSearchFormCustom.bind(this);
    this.fetchResults = this.fetchResults.bind(this);

    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchTripRoster = this.fetchTripRoster.bind(this);
    this.setSelectedInvoiceOpen = this.setSelectedInvoiceOpen.bind(this);
    this.getSelectedInvoice = this.getSelectedInvoice.bind(this);
    this.excelExport = this.excelExport.bind(this);
    this.pdfExport = this.pdfExport.bind(this);
    this.setSearchOrder = this.setSearchOrder.bind(this);
    this.resetSearchForm = this.resetSearchForm.bind(this);
    this.viewRosterPdf = this.viewRosterPdf.bind(this);
    this.updateSchedule = this.updateSchedule.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  updateState(o, f = () => {}) {
    this.setState(o, f);
  }

  setSelectedInvoiceOpen(selectedState) {
    this.setState({ selectedInvoiceOpen: selectedState });
  }
  handleChangePage(event, newPage) {
    let searchForm = this.state.searchForm;
    searchForm.page = newPage - 1 ;
    this.setState({ searchForm: searchForm }, this.fetchResults);
  }

  handleChangeRowsPerPage(event) {
    let searchForm = this.state.searchForm;
    searchForm.per_page = parseInt(event.target.value, 10);
    //searchForm.excelExport = true;

    this.setState({ searchForm: searchForm }, this.fetchResults);
  }

  getSelectedInvoice() {
    return this.state.selectedInvoice;
  }
  excelExport() {
    let searchForm = this.state.searchForm;

    searchForm.excelExport = true;

    this.setState({ searchForm: searchForm }, this.fetchResults);
  }
  pdfExport() {
    let searchForm = this.state.searchForm;

    searchForm.pdfExport = true;

    this.setState({ searchForm: searchForm }, this.fetchResults);
  }

  async initSearchParams() {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }
    this.setState({ user: user });
    const api = axios.create({
      /*baseURL: `https://reqres.in/api`*/
      baseURL: BASE_URL,
      headers: {
        "x-auth-key": user.key,
      },
    });
    try {
      const metaResp = await api.get("/refdata/all");
      const countryList = await metaResp.data;
      const searchForm = this.state.searchForm;

      searchForm.tripStatus = countryList.tripStatusList.rows;
      //searchForm.searchstring = "Pulling today's data from mas system ...  ";

      await this.setState({
        countries: countryList.countryList.rows,
        countyList: countryList.countyList.rows,
        tripStatusList: countryList.tripStatusList.rows,
        pASubmissionResultsList: countryList.pASubmissionResultsList.rows,
        correctionList: countryList.correctionList.rows,
        exportStatusList: countryList.exportStatusList.rows,
        transportTypeList: countryList.transportTypeList.rows,
        transportProviderList: countryList.transportProviderList.rows,
        tripSortByList: countryList.tripSortByList.rows,
        modleProgress: false,
        searchForm: searchForm,
      });

      this.fetchResults(true);
    } catch (err) {
      console.log(err);
      toast.error(
        "Error fetching metadata , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  resetSearchForm() {
    const searchForm = {
      resultsDisplay: "display1",
      searchFromDOB: "",
      searchToDOB: "",
      searchName: "",
      searchCINNumber: "",
      searchInvoiceNumber: "",
      searchServiceEndsFrom: "",
      searchTransportType: null,
      searchServiceEndsTo: "",
      searchCounty: null,
      searchServiceStartsFrom: moment().format("MM/DD/YYYY"),
      searchServiceStartsTo: "",
      page: 0,
      per_page: 0,
      total: 0,
      total_pages: 0,
      freshFetch: true,
      fetching: true,
      excelExport: false,
      pdfExport: false,
      sort: { field: "pickupDate", order: "ASC" },
    };
    this.setState({ searchForm: searchForm }, this.fetchResults);
    this.initSearchParams();
  }

  componentDidMount() {
    this.initSearchParams();
  }

  async viewRosterPdf(invoice) {
    this.setState({ modleProgress: true });
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-auth-key": this.state.user.key,
      };

      const response = await axios.post(
        BASE_URL + "/Scheduler/viewRosterPdf",
        { invoice },
        {
          headers: headers,
        }
      );
      this.setState({ modleProgress: false });
      if (response.data.pdfURL) {
        window.open(response.data.pdfURL);
      }
    } catch (e) {
      toast.error("Error exporting pdf . Please try again ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({ modleProgress: false });
    }
  }

  async fetchTripRoster(invoice) {
    this.setState({ modleProgress: true });

    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": this.state.user.key,
    };
    try {
      const response = await axios.post(
        BASE_URL + "/Scheduler/viewRoster",
        { invoice },
        {
          headers: headers,
        }
      );

      this.setState(
        { selectedInvoice: response.data.triproster.tripRoster },
        () => {
          this.setState({ modleProgress: false });
          this.setSelectedInvoiceOpen(true);
        }
      );
    } catch (e) {
      this.setState({ modleProgress: false });
      toast.error(
        "Error fetching data , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }
  handleChange(event) {
    let id = event.target.id;
    this.setState({ [id]: event.target.value });
  }

  setSearchForm(event) {
    let id = event.target.id;
    let searchForm = this.state.searchForm;
    searchForm[id] = event.target.value;

    this.setState({ searchForm: searchForm });
  }

  setSearchOrder(option, order) {
    let sort = this.state.searchForm.sort;
    sort.field = option;
    sort.order = order;

    let searchForm = this.state.searchForm;
    searchForm.sort = sort;

    this.setState({ searchForm: searchForm }, this.fetchResults);
  }
  setSearchFormDate(id, value) {
    let searchForm = this.state.searchForm;
    searchForm[id] = value;

    this.setState({ searchForm: searchForm });
  }
  setSearchFormCustom(id, value) {
    let searchForm = this.state.searchForm;
    searchForm[id] = value;

    this.setState({ searchForm: searchForm });
  }

  setSearchFormAutoComplete(id, value) {
    let searchForm = this.state.searchForm;
    searchForm[id] = value;
    this.setState({ searchForm: searchForm });
  }

  async updateSchedule() {
    //  this.setState({ modleProgress : true });
    let searchForm = this.state.searchForm;
    searchForm.fetching = true;
    this.setState({ searchForm: searchForm });

    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": this.state.user.key,
    };

    var searchServiceStartsFrom = moment(
      this.state.searchForm.searchServiceStartsFrom,
      "MM/DD/YYYY"
    ).startOf("day"); // moment().format("MM/DD/YYYY")
    var searchServiceStartsTo = moment(
      this.state.searchForm.searchServiceStartsTo,
      "MM/DD/YYYY"
    ).endOf("day");

    //Difference in number of days
    var dayCount = parseInt(
      Math.ceil(
        moment
          .duration(searchServiceStartsTo.diff(searchServiceStartsFrom))
          .asDays()
      )
    );
    console.log(dayCount);
    if (dayCount > 4) {
      console.log(dayCount + " is greater than xx ");

      toast.error(
        "You have selected  " +
          dayCount +
          " days , Please max 4 days for live update  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      let searchForm = this.state.searchForm;
      searchForm.fetching = false;
      this.setState({ searchForm: searchForm });

      console.log(dayCount + " is greater than xx ");
    } else {
      try {
        const response = await axios.post(
          BASE_URL + "/Scheduler/updateSchedule",
          this.state.searchForm,
          {
            headers: headers,
          }
        );
        // set the state of the user
        //this.setState({ modleProgress : false });
        let searchForm = this.state.searchForm;
        searchForm.page = response.data.pagedData.page;
        searchForm.per_page = response.data.pagedData.per_page;
        searchForm.total = response.data.pagedData.total;
        searchForm.total_pages = response.data.pagedData.total_pages;
        searchForm.fetching = false;
        searchForm.excelExport = false;
        searchForm.pdfExport = false;

        if (response.data.fileName) {
          window.open(response.data.fileName);
        }

        if (response.data.pdfFileName) {
          window.open(response.data.pdfFileName);
        }

        this.setState({ searchForm: searchForm });
        this.setState({ tablerows: response.data.pagedData });
        // store the user in localStorage
      } catch (error) {
        console.log("error Print");
        if (error.response) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Error ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  async fetchResults(firstTime = false) {
    //  this.setState({ modleProgress : true });
    let searchForm = this.state.searchForm;

    searchForm.fetching = true;
    await this.setState({ searchForm: searchForm });
    if (!firstTime)
      await this.setState({ searchstring: "Fetching data .. Please wait ! " });

    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": this.state.user.key,
    };

    try {
      const response = await axios.post(
        BASE_URL + "/Scheduler/view",
        this.state.searchForm,
        {
          headers: headers,
        }
      );

      if (this.state.searchForm.pdfExport && response.data.pdfFileName || this.state.searchForm.excelExport &&  response.data.fileName) {
        
        try {
          const fileFomat = this.state.searchForm.pdfExport ? 'pdf' : 'xlsx';

          const fileParts = fileFomat == 'pdf' ? response.data.pdfFileName.split('/') : response.data.fileName.split('/')

          const responseFileData = await axios.get(
            BASE_URL + `/Scheduler/download/${fileFomat}/${fileFomat == 'pdf' ? response.data.pdfFileName : response.data.fileName}`,
            {
              responseType: "arraybuffer",
            }
          );
    
          const blob = new Blob([responseFileData.data], { type: (fileFomat == 'pdf' ?  'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' )});
    
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileParts[fileParts.length -1]
    
          document.body.appendChild(link);
          link.click();
    
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error downloading PDF:", error);
        }
      }

      // set the state of the user
      //this.setState({ modleProgress : false });
      let searchForm = this.state.searchForm;
      searchForm.page = response.data.pagedData.page;
      searchForm.per_page = response.data.pagedData.per_page;
      searchForm.total = response.data.pagedData.total;
      searchForm.total_pages = response.data.pagedData.total_pages;
      searchForm.fetching = false;
      searchForm.excelExport = false;
      searchForm.freshFetch = false;
      searchForm.pdfExport = false;

      this.setState({ searchstring: response.data.searchStr });
      this.setState({ searchForm: searchForm });
      this.setState({ tablerows: response.data.pagedData });
      // store the user in localStorage

      /*toast.success("Welcome ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });*/
    } catch (error) {
      let searchForm = this.state.searchForm;
      this.setState({ searchForm: searchForm });
      this.setState({ searchstring: "Error fetching data " });
      searchForm.fetching = false;
      console.log("error Print", error);
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Error ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Grid container>
          <Grid item xs={12} md={8} lg={8} className="screen-sub-title">
            MAS trip view
          </Grid>
          <Grid item xs={12}>
            <CardRoot elevation={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Accordion
                    defaultExpanded={true}
                    expanded={this.state.openSearch}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={() => {
                        this.setState((prevState) => ({
                          openSearch: !prevState.openSearch,
                        }));
                      }}
                    >
                      <Typography>MAS Trips - Search Criteria</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Search
                        tripSortByList={this.state.tripSortByList}
                        searchForm={this.state.searchForm}
                        countyList={this.state.countyList}
                        transportTypeList={this.state.transportTypeList}
                        searchstring={this.state.searchstring}
                        fetching={this.state.fetching}
                        setState={this.updateState}
                        resetSearchForm={this.resetSearchForm}
                        setSearchOrder={this.setSearchOrder}
                        setSearchFormDate={this.setSearchFormDate}
                        setSearchForm={this.setSearchForm}
                        setSearchFormAutoComplete={
                          this.setSearchFormAutoComplete
                        }
                        setSearchFormCustom={this.setSearchFormCustom}
                        fetchResults={this.fetchResults}
                        pdfExport={this.pdfExport}
                        excelExport={this.excelExport}
                        updateSchedule={this.updateSchedule}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={!this.state.searchForm.fetching}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Search Results</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                          display="flex"
                        >
                          {" "}
                          <CustomRadioGroup
                            idPrefix={"resultsDisplay"}
                            onChange={this.setSearchFormCustom}
                            options={[
                              { id: "display1", value: "Display Format V01" },
                              { id: "display2", value: "Display Format V02" },
                            ]}
                            default={"display1"}
                          />
                        </Grid>
                      </Grid>
                      {this.state.searchForm.fetching ? (
                        <LinearProgress />
                      ) : (
                        <>
                          <TableContainer component={Paper}>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                xl={6}
                                display="flex"
                              >
                                <Pagination
                                  page={this.state.searchForm.page}
                                  count={this.state.searchForm.total_pages}
                                  onChange={this.handleChangePage}
                                  color="primary"
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                xl={6}
                                sx={{ textAlign: "right" }}
                                display="flex"
                              >
                                <TablePagination
                                  component="div"
                                  count={this.state.searchForm.total}
                                  page={this.state.searchForm.page}
                                  onPageChange={this.handleChangePage}
                                  rowsPerPage={this.state.searchForm.per_page}
                                  onRowsPerPageChange={
                                    this.handleChangeRowsPerPage
                                  }
                                />
                              </Grid>
                            </Grid>
                            {this.state.searchForm.resultsDisplay ===
                            "display1" ? <></> : (
                            <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                              <LocalTaxiIcon sx={{marginRight: '10px', color: '#148cd3'}}/>
                              <Typography sx={{marginRight: '15px', color: '#033d5f'}}>Single Trips</Typography>
                              <SyncAltIcon sx={{marginRight: '10px', color: '#148cd3'}}/>
                              <Typography sx={{marginRight: '15px', color: '#033d5f'}}>Round Trip</Typography>
                              <AllInclusiveIcon sx={{marginRight: '10px', color: '#148cd3'}}/>
                              <Typography sx={{marginRight: '15px', color: '#033d5f'}}>Standing Order</Typography>
                              <AlignHorizontalLeft sx={{marginRight: '10px', color: '#148cd3'}}/>
                              <Typography sx={{marginRight: '15px', color: '#033d5f'}}>Two Legs</Typography>
                              <East sx={{marginRight: '10px', color: '#148cd3'}}/>
                              <Typography sx={{marginRight: '15px', color: '#033d5f'}}>One-Way</Typography>
                              <LibraryAdd sx={{marginRight: '10px', color: '#148cd3'}}/>
                              <Typography sx={{marginRight: '15px', color: '#033d5f'}}>New</Typography>
                              <AcUnit sx={{marginRight: '10px', color: '#148cd3'}}/>
                              <Typography sx={{marginRight: '15px', color: '#033d5f'}}>Changed</Typography>
                            </div>) }
                            {this.state.searchForm.resultsDisplay ===
                            "display1" ? (
                              <RosterDisplayOne
                                tablerows={this.state.tablerows}
                                setSearchOrder={this.setSearchOrder}
                                fetchTripRoster={this.fetchTripRoster}
                                viewRosterPdf={this.viewRosterPdf}
                                user={this.state.user}
                              />
                            ) : (
                              <RosterDisplayTwo
                                tablerows={this.state.tablerows}
                                setSearchOrder={this.setSearchOrder}
                                fetchTripRoster={this.fetchTripRoster}
                                viewRosterPdf={this.viewRosterPdf}
                              />
                            )}
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                xl={6}
                                display="flex"
                              >
                                <Pagination
                                  page={this.state.searchForm.page}
                                  count={this.state.searchForm.total_pages}
                                  onChange={this.handleChangePage}
                                  color="primary"
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                xl={6}
                                sx={{ textAlign: "right" }}
                                display="flex"
                              >
                                <TablePagination
                                  component="div"
                                  count={this.state.searchForm.total}
                                  page={this.state.searchForm.page}
                                  onPageChange={this.handleChangePage}
                                  rowsPerPage={this.state.searchForm.per_page}
                                  onRowsPerPageChange={
                                    this.handleChangeRowsPerPage
                                  }
                                />
                              </Grid>
                            </Grid>
                          </TableContainer>{" "}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <InvoiceDialog
                    selectedInvoiceOpen={this.state.selectedInvoiceOpen}
                    getSelectedInvoice={this.getSelectedInvoice}
                    setSelectedInvoiceOpen={this.setSelectedInvoiceOpen}
                  />
                </Grid>
              </Grid>
            </CardRoot>
          </Grid>
        </Grid>

        <Modal
          open={this.state.modleProgress}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CircularProgress /> Loading ...
          </Box>
        </Modal>
      </>
    );
  }
}

export default TripSearch;
