import React, { useState, useEffect, useContext } from "react";
import DriverManagementHeader from "./DriverManagementHeader";
import DriverFilter from "./DriverFilter";
import DriverSearch from "./DriverSearch";
import RefdataContext from "../../../context/RefdataContext";
import CreateEditDriver from "./CreateEditDriver";

import {mandotoryDriverSetupFieldsValidation} from "../../../utils/validators"

import {
  createDriver, 
  deleteDriver, 
  getDriverList, 
  getDriverDetails, 
  updateDriver, 
  uploadProfilePictures, 
  uploadSignaturePictures 
} from "../../../api/DriverManagementApi"
import { ToastContainer, toast } from "react-toastify";
import { Alert, Grid, IconButton, Snackbar, Stack } from "@mui/material";
import DriverTable from "./DriverTable";
import { StyledIconWrapper } from "../../../styled";
import { AddCircleOutline, ListAltOutlined } from "@mui/icons-material";


const DriverManagement = () => {
  const [filter, setFilter] = useState("all");
  const [showDriverList, setShowDriverList] = useState(true);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [allDrivers, setAllDrivers] = useState([]);
  const [driver, setDrivers] = useState(null);
  const [action, setAction] = useState({"action":"list","driverId":null});
  const [reload, setReload] = useState(true);
  const [user, setUser] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [errors, setErrors] = useState(undefined);
  
  useEffect(()=>{
      let user = { active: false, name: "" };
      if (localStorage.getItem("session")) {
        user = JSON.parse(localStorage.getItem("session"));
      }
      setUser(user);
  },[])
  

  useEffect(()=>{
    const loadDrivers = async () =>{
      if(reload){
        if(!user){
          if (localStorage.getItem("session")) {
            var user = JSON.parse(localStorage.getItem("session"));
          }
        }
        var driverList = await getDriverList(user);
        let _dkDrivers = driverList.data.map((d)=>{
          return {
            ...d,
          ["fullName"]: `${d.firstName} ${d.lastName}`
          }
        })
        setAllDrivers(_dkDrivers);
        setFilteredDrivers(_dkDrivers); 
        setReload(false);
      } 
    }
    loadDrivers();
  },[reload])

  useEffect(async ()=>{
    if(action.driverId != null){
      let driver =   await getDriverDetails(action.driverId);
      if(driver){
        setDrivers(driver)
      }
      console.log(driver);
    }
    action.action == "list"? setShowDriverList(true): setShowDriverList(false);
  },[action.action])


  const handleCloseSnack=()=>{
    setOpenSnack(false)
  }

  const handleSave  = async (driver, profilePictureData, action) =>{
    console.log(driver)
    let errors = mandotoryDriverSetupFieldsValidation(driver);
      if(errors.length > 0) {
        setErrors(errors);
        setOpenSnack(true);
        return;
      } else  {
        try{
          let response = {}
          if(action == "edit")
            {
                response = await updateDriver(driver);
                await saveProfilePicturedata(driver.id, profilePictureData)
            }
          else if (action == "create")
            {
              response = await createDriver(driver);
              await saveProfilePicturedata(response.id, profilePictureData)
            }
          else if (action == "delete")
            response = await deleteDriver(driver);
          toast.success(`Saved successfully`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReload(true);
          setTimeout(()=>{
            setAction({
              "driverId":null,
              "action":"list"
            })
          },2000);
        } catch (err){
          console.log(err);
          toast.error(`[Error] ${err.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      }
    }
  }

  const handleFilterChange = (filterType) => {
    if (filterType === "all") {
      setFilteredDrivers(allDrivers);
    } else if (filterType === "assigned") {
      let drivers = allDrivers.filter((driver) => driver.vehicle_id);
      setFilteredDrivers(drivers);
    } else if (filterType === "unassigned") {
      const drivers = allDrivers.filter(
        (driver) => !driver.vehicle_id
      );
      setFilteredDrivers(drivers);
    } else if (filterType === "active") {
      const drivers = allDrivers.filter(
        (driver) => driver.active
      );
      setFilteredDrivers(drivers);
    } else if (filterType === "inactive") {
      const drivers = allDrivers.filter(
        (driver) => !driver.active
      );
      setFilteredDrivers(drivers);
    }
  };

  const saveProfilePicturedata = async(id, data) => {
    return new Promise(async (resolve, reject) =>{
      try{
        
        if(data.profilePictureContent != null){
          const formData1 = new FormData();
          formData1.append("id",id);
          formData1.append("profilePicture",data.profilePicture);
          formData1.append("profilePictureContent",data.profilePictureContent);
          let response = await uploadProfilePictures(formData1);
          console.log(response);
        }

        
        if(data.signatureListContent!= null && data.signatureListContent.length > 0){
          
          const formData = new FormData();
          formData.append("id",id);
          for (let i = 1; i<= 4; i++){
            try{
              formData.append(`signatureListContent-${i}`,data.signatureListContent[i-1]?data.signatureListContent[i-1]:undefined);
            }catch (err){
              console.log(err);
              formData.append(`signatureListContent-${i}`,null);
            }
          }            
          
          formData.append("signatureList",data.signatureList.join(","));

          // for (const key of formData.keys()) {
          //   console.log("KEY -> ",key,"VAL ->", formData.get(key) );
          // }

          
          let response = await uploadSignaturePictures(formData);
          // console.log(response);
        }
        resolve(true);
      } catch (err) {
        console.log(err);
        reject(err);
      }
      
    })
  }


  const showHideDriverList = () =>{
    if(action.action != "list"){
      setAction({
        "driverId":null,
        "action":"list"
      })
    }    
    else
      {
        setAction({
          "driverId":null,
          "action":"create"
        })
        setDrivers(null);
        
      }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{vertical:"top",horizontal:"right"}}>
      <Alert severity="error" onClose={handleCloseSnack} >{
          errors?.map((e)=>{
          return(
            <li>
              {e}
            </li>
          )
        })
        }
        </Alert>
        </Snackbar>
      </Stack>  
      <ToastContainer/>
      <DriverManagementHeader 
      setShowDriverList={setShowDriverList}
      action={action}
      setAction={setAction}/>
      {
        showDriverList?
          <Grid container>
            <Grid item md={6}>
              <DriverFilter 
                onFilterChange={handleFilterChange} 
                driverList={allDrivers} 
                setFilteredDrivers={setFilteredDrivers}
                setAction={setAction}
                filter={filter}/>
            </Grid>
            <Grid item md={6} style={{textAlign:"end"}}>
              <IconButton style={{padding:0}} variant={"contained"} theme onClick={()=>{showHideDriverList()}}>
                <StyledIconWrapper style={{padding:0}} >
                  {
                    action.action== "list"?
                      <AddCircleOutline fontSize="large" />
                    :
                      <ListAltOutlined fontSize="large" />
                  }
                  </StyledIconWrapper>
                </IconButton>
            </Grid>
            <Grid item md={12} style={{marginTop:"1.5rem"}}>
              <DriverTable DKDrivers={filteredDrivers} setAction={setAction}/>  
            </Grid>
          </Grid>
        :<CreateEditDriver 
          setShowDriverList={setShowDriverList} 
          showDriverList={showDriverList}
          handleSave={handleSave}
          driverDetails={driver}
          action={action}
        />
      } 
      {/* <DriverTable/> */}
    </div>
  );
};

export default DriverManagement;
