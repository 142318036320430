import React, { useEffect, useState } from 'react';
import { Close, FastForward } from '@mui/icons-material';
import { FormHelperText, Checkbox, Dialog, AppBar, Toolbar, Typography, IconButton, DialogContent, DialogActions, FormControlLabel, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { StyledButton } from '../../../styled';
import { ToastContainer, toast } from 'react-toastify';
import {
  StyledFormControl,
  StyledInputText,
  StyledMenuItem,
  StyledSelect,
} from "../../../styled";
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";

import {
  updateUser,
    createUser
  } from "../../../api/UserManagementApi"

var md5 = require('md5');

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  buttonContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  dialogContainer: {
    margin: theme.spacing(2)
  },
  customHelperTextError: {
    color: 'red',
    marginLeft: '4px'
  },
  customHelperTextErrorNone: {
    color: '#f0f0f500',
    marginLeft: '4px'
  },
}));

const AddEditUser = ({ open, onClose, userLevels, user }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel1');
  const [values, setValues] = useState({});
  const action = {action: 'edit'}

  const [formValues, setFormValues] = useState({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    password: '',
    userName: '',
    userlevel: 3,
    active: true
  });

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    mobilePhone: null,
    password: null,
    userName: null,
  });

  useEffect(() => {
    if(user) {      
      setFormValues((prevState) => ({
        ...prevState,
        ['id']: user.id,
        ['firstName']: user.firstName,
        ['lastName']: user.lastName,
        ['email']: user.email,
        ['mobilePhone']: formatPhoneNumber(user.mobilePhone),
        ['password']: user.password,
        ['userName']: user.userName,
        ['userlevel']: user.userlevel,
        ['active']: user.active
      }))
    }
  }, [user])

  const handleSave = async() => {
    let newErrors = {};
    let valid = true;

    if (formValues.firstName.trim() === '') {
      newErrors.firstName = 'First Name is required';
      valid = false;
    }

    if (formValues.lastName.trim() === '') {
      newErrors.lastName = 'Last Name is required';
      valid = false;
    }

    if (formValues.mobilePhone.trim() === '') {
      newErrors.mobilePhone = 'Mobile Phone is required';
      valid = false;
    }

    if (formValues.password.trim() === '') {
      newErrors.password = 'Password is required';
      valid = false;
    }

    if (formValues.userName.trim() === '') {
      newErrors.userName = 'Username is required';
      valid = false;
    }

    if (formValues.email.trim() === '') {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }


    if(!valid) {
      setErrors(newErrors);
      return
    }
    
    let request = {...formValues}

    if(!/^[a-fA-F0-9]{32}$/.test(formValues.password)) {
      request = {...formValues, password: md5(formValues.password)}
    }

    try {      
      if(request.id) {
        await updateUser(request)
      } else {
        await createUser(request)
      }
      
      toast.success((request.id ? "Updated ! " : "Created ! ") , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      onClose();
    } catch (error) {
      toast.error((request.id ? "Update failed. Please try again later" : "Create failed. Please try again later"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "mobilePhone") {
      value = formatPhoneNumber(value);
    }

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors({
      ...errors,
      [name]: null,
    });
  }

  const handleChangeDropDown = (e) => { 
    let name = e.target.name;
    let value = e.target.value;
    
    setFormValues((prev) => ({
        ...prev,
        [name]:value
    }))

    setErrors({
      ...errors,
      [name]: null,
    });
  }

  const handleSelect = (e) => {
      let name = e.target.name;
      let value = e.target.checked ? true : false;
      
      setFormValues((prev) => ({
          ...prev,
          [name]:value
      }))

      setErrors({
        ...errors,
        [name]: null,
      });
  }

  const formatPhoneNumber = (phoneNumber) => {

	  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
	  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    
	  if (match) {
	    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	  }
	  return phoneNumber;
	};


  return (
    <>
      <ToastContainer />
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" height="xl" className={classes.dialogContainer}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h3" className={classes.title}>
              {user?.id == null ? "New User" : "Edit User"}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container>
              <Grid item md={12}>
                  {/*  */}
                  <Grid container>
                    <Grid item md={6}>
                      <StyledFormControl>
                        <StyledFormInputLabel primary={true}>First Name</StyledFormInputLabel>
                        <StyledInputText
                          name="firstName"
                          value={formValues.firstName}
                          onChange={(e) => handleChange(e)} 
                        />
                        <FormHelperText className={Boolean(errors.firstName) ? classes.customHelperTextError : classes.customHelperTextErrorNone}>{Boolean(errors.firstName) ? errors.firstName : '.'}</FormHelperText>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl>
                        <StyledFormInputLabel primary={true}>Last Name</StyledFormInputLabel>
                        <StyledInputText
                          name="lastName"
                          value={formValues.lastName}
                          onChange={(e) => handleChange(e)} 
                        />
                        <FormHelperText className={Boolean(errors.lastName) ? classes.customHelperTextError : classes.customHelperTextErrorNone}>{Boolean(errors.lastName) ? errors.lastName : '.'}</FormHelperText>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl>
                        <StyledFormInputLabel primary={true}>Email</StyledFormInputLabel>
                        <StyledInputText
                          name="email"
                          value={formValues.email}
                          onChange={(e) => handleChange(e)} 
                        />
                        <FormHelperText className={Boolean(errors.email) ? classes.customHelperTextError : classes.customHelperTextErrorNone}>{Boolean(errors.email) ? errors.email : '.'}</FormHelperText>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl variant="standard">
                        <StyledFormInputLabel primary={true}>
                          Mobile No
                        </StyledFormInputLabel>
                        <StyledInputText
                          name="mobilePhone"
                          value={formValues.mobilePhone}
                          onChange={(e) => handleChange(e)} 
                        />
                        <FormHelperText className={Boolean(errors.mobilePhone) ? classes.customHelperTextError : classes.customHelperTextErrorNone}>{Boolean(errors.mobilePhone) ? errors.mobilePhone : '.'}</FormHelperText>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl variant="standard">
                        <StyledFormInputLabel primary={true}>
                          Username
                        </StyledFormInputLabel>
                        <StyledInputText
                          name="userName"
                          value={formValues.userName}
                          onChange={(e) => handleChange(e)} 
                        />
                        <FormHelperText className={Boolean(errors.userName) ? classes.customHelperTextError : classes.customHelperTextErrorNone}>{Boolean(errors.userName) ? errors.userName : '.'}</FormHelperText>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl variant="standard">
                        <StyledFormInputLabel primary={true}>
                          Password
                        </StyledFormInputLabel>
                        <StyledInputText
                          name="password"
                          type="password"
                          value={formValues.password}
                          onChange={(e) => handleChange(e)} 
                        />
                        <FormHelperText className={Boolean(errors.password) ? classes.customHelperTextError : classes.customHelperTextErrorNone}>{Boolean(errors.password) ? errors.password : '.'}</FormHelperText>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl variant="standard" sx={{paddingBottom: '12px'}}>
                        <StyledFormInputLabel primary={true}>
                          User Level
                        </StyledFormInputLabel>
                        <StyledSelect 
                            name="userlevel"
                            value={formValues.userlevel} 
                            onChange={e=>handleChangeDropDown(e)}>
                            { 
                                userLevels.map(item => {
                                    return (<StyledMenuItem key={item.id} value={item.id}>{item.name}</StyledMenuItem>)
                                })
                            }    
                        </StyledSelect>
                      </StyledFormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledFormControl variant="standard">
                        <StyledFormInputLabel primary={true}>
                          Status
                        </StyledFormInputLabel>
                        <FormControlLabel  control={
                          <Checkbox 
                              name="active"
                              checked={formValues.active === true ? true : false}
                              onChange={e=>handleSelect(e)}
                          />} label={formValues.active === true ? 'Active' : 'Inactive'} />
                      </StyledFormControl>
                    </Grid>
                  </Grid>
                  {/*  */}
              </Grid>
          </Grid>
          </DialogContent>
          <DialogActions>
              <StyledButton variant="contained" onClick={(e)=>{handleSave()}}>
                  Save
              </StyledButton>
              <StyledButton variant="outlined" onClick={(e)=>{onClose()}}>
                  Close
              </StyledButton>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditUser;
