
const axios  = require('axios');
const config = require("../config")

const BASE_URL = `${config.BASE_URL}`;

async function getUserList(user, page, rowsPerPage, filter){
    return new Promise(async (resolve, reject) =>{
        try{
        let response = await axios.get(`${BASE_URL}/user/all?massession=${user.key}`,
           {headers: {
            "x-auth-key": user.key,
          },
          params: {page: page, size: rowsPerPage, title: filter}},)
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

async function getUserLevels(user){
    return new Promise(async (resolve, reject) =>{
        try{
        let response = await axios.get(`${BASE_URL}/userlevel/all`,
           {headers: {
            "x-auth-key": user.key,
          },
          params: {}})
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

async function createUserLevel(detail){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.post(`${BASE_URL}/userlevel`, detail);
            if (response.status === 201 || response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

async function updateUserLevel(detail){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.put(`${BASE_URL}/userlevel/${detail.id}`, detail, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

async function deleteUserLevel(detail){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.delete(`${BASE_URL}/userlevel/${detail.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}


export {getUserList, getUserLevels, createUserLevel, updateUserLevel, deleteUserLevel}