import { useEffect, useState } from "react";
import UserLevelManagementHeader from "./UserLevelManagementHeader";
import UserLevelTable from "./UserLevelTable";
import UserLevelFilter from "./UserLevelFilter"
import { Grid, IconButton } from "@mui/material";
import { StyledIconWrapper } from "../../../styled";
import { AddCircleOutline } from "@mui/icons-material";

import {
  getUserList,
    getUserLevels,
    deleteUserLevel
  } from "../../../api/UserLevelManagementApi"
import AddUserLevel from "./AddUserLevel";
import UserLevelDeleteConfirmationPopup from "./UserLevelDeleteConfirmationPopup";
import { toast } from 'react-toastify';

const UserLevelManagement = () => {

    const [filteredUserLevels, setFilteredUserLevels] = useState([]);
    const [action, setAction] = useState({"action":"list","item":null});
    const [queryFilter, setQueryFilter] = useState(null);
    
    const [reload, setReload] = useState(true);
    const [openAddUserLevel, setOpenAddUserLevel] = useState(false);
    const [currentUserLevel, setCurrentUserLevel] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const handleCloseAddUserLevel = () => {
      setOpenAddUserLevel(false);
      setReload(true);
    };

    const loadUserLevels = async (page, rowsPerPage, filter) =>{
      if(reload || pageChanged){
        if(!user){
          if (localStorage.getItem("session")) {
            var user = JSON.parse(localStorage.getItem("session"));
          }
        }
        
        var userLevels = await getUserLevels(user);

        setFilteredUserLevels(userLevels.data);
        setReload(false);

        setTotalRows(userLevels.total);
        setTotalPages(userLevels.total_pages);
      } 
    }

    useEffect(()=>{        
      loadUserLevels(0, 10, null);
    },[reload])

    const reloadData = (page, rowsPerPage, filter) => {
        setPageChanged(true)
        loadUserLevels(page, rowsPerPage, filter);
    }

    useEffect(() => {
      if(action.action === 'edit') {
        showHideUserView(action.item)
      } else if(action.action === 'delete') {
        setCurrentUserLevel(action.item);
        setShowDeleteConfirm(true)
      }
    }, [action])

    const showHideUserView = (currentUserLevel) =>{

      if(currentUserLevel != null) {
        setCurrentUserLevel(currentUserLevel);
      } else {
        let newUser = {
          firstName: '',
          lastName: '',
          email: '',
          mobilePhone: '',
          password: '',
          userName: '',
          userlevel: 3,
          active: true
        }
  
        setCurrentUserLevel(newUser);
      }
      setOpenAddUserLevel(true);
    }

    const handleDeleteConfirm = async() => {
      try {        
        await deleteUserLevel(currentUserLevel)
        
        toast.success("User deleted" , {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
        setShowDeleteConfirm(false)
        setReload(true);
      } catch (error) {
        console.log('[Error] : ', error);
        toast.error(("User deletion failed. Please try again later"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    const handleDeleteClose = () => {
      setShowDeleteConfirm(false)
    }

    return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >          
          <UserLevelManagementHeader />
          <Grid container>
            <Grid item md={6}>
              <UserLevelFilter setFilter={setQueryFilter} />
            </Grid>
            <Grid item md={6} style={{textAlign:"end"}}>
              <IconButton style={{padding:0}} variant={"contained"} theme onClick={()=>{showHideUserView(null)}}>
                <StyledIconWrapper style={{padding:0}} >
                  <AddCircleOutline fontSize="large" />
                </StyledIconWrapper>
              </IconButton>
            </Grid>
            <Grid item md={12} style={{marginTop:"1.5rem"}}>
              <UserLevelTable userLevels={filteredUserLevels} setAction={setAction} reloadData={reloadData} totalPages={totalPages} filter={queryFilter}/>  
            </Grid>
          </Grid> 
          <AddUserLevel open={openAddUserLevel} onClose={handleCloseAddUserLevel} userLevel={currentUserLevel}/>
          <UserLevelDeleteConfirmationPopup open={showDeleteConfirm} handleConfirm={handleDeleteConfirm} handleClose={handleDeleteClose}/>
        </div>
      );
}

export default UserLevelManagement;