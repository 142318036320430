import { Grid } from "@mui/material";
import { StyledHeader } from "../../../styled";

const CustomerManagementHeader = () => {
    return (
        <Grid container>
            <Grid item md={12}>
                <StyledHeader>
                    Customer Management
                </StyledHeader>
            </Grid>
        </Grid>  
    );
}

export default CustomerManagementHeader;