import { Checkbox,  FormControlLabel, Grid, Typography } from "@mui/material";
import React, {useState, useEffect, useContext} from "react";
import { 
    StyledFormControl, 
    StyledFrame, 
    StyledMenuItem, 
    StyledSelect 
} from "../../../styled";


import RefdataContext from "../../../context/RefdataContext";

function SpecialConfigsView({values, setValues}) {

    const {countyList} = useContext(RefdataContext)
    const [counties, setCounties] = useState(countyList);
    useEffect(()=>{
        if(counties)
        setCounties(countyList)
    },[counties])

    useEffect(()=>{
console.log("SEND GPS -> ", values.sendGPS)    },[values.sendGPS])
console.log("Dispatch Only -> ", values.dispatchonly ,[values.dispatchonly])

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setValues({
            ...values,
            [name]:value
        })
    }

    function handleSelect(e) {
        let name = e.target.name;
        let value = e.target.checked ? true :false;

        console.log(name, value)
        setValues({
            ...values,
            [name]:value
        })
    }

    return (
        <StyledFrame>
            <Grid container>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <StyledFormControl variant="standard">
                                <Typography  sx={{
                                fontWeight:800
                                }}>Counties</Typography>
                                <StyledSelect 
                                        name="counties"
                                        multiple
                                        value={values.counties} 
                                        defaultValue={values.counties}
                                        onChange={e=>handleChange(e)}>
                                        { 
                                            counties?.map((c, idx) => {
                                                return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                            })
                                        }    
                                    </StyledSelect>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={6} style={{display:"flex", alignItems:"end"}}>
                            <StyledFormControl>
                                <FormControlLabel  control={
                                    <Checkbox 
                                        name="sendGPS"
                                        checked={values.sendGPS == "1"?true:false} 
                                        defaultValue={values.sendGPS == "1"?true:false}
                                        onChange={e=>handleSelect(e)}
                                    />} label="GPS Breadcrumbs" />
                            </StyledFormControl>
                            <StyledFormControl>
                                <FormControlLabel  control={
                                    <Checkbox 
                                        name="dispatchonly"
                                        checked={values.dispatchonly == "1"?true:false} 
                                        defaultValue={values.dispatchonly == "1"?true:false}
                                        onChange={e=>handleSelect(e)}
                                    />} label="Dispatch" />
                            </StyledFormControl>
                        </Grid>      
                    </Grid>
                </Grid>
            </Grid>
        </StyledFrame>
    ) 
}

export default SpecialConfigsView;