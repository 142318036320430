import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import AddressViewTableColumn from './AddressViewTableColumn';
import { TableCell, TableRow, Typography } from '@mui/material';

function AddressViewPopup({ open, onClose, row, masAddressInfo }) {
  const columns = [
    { label: 'Provider' },
    { label: 'Address' }
  ];

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Typography variant="h6" style={{ marginBottom: '25px', marginTop: '30px' }}>
          <span style={{ color: '#1976d2', fontWeight: 'bold', marginRight: '10px' }}>Name </span>
          <span style={{ fontWeight: 'bold'}}>{row && row.fullName}</span>
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="address table">
            <AddressViewTableColumn columns={columns} />
            <TableBody>
              <TableRow>
                <TableCell>MAS</TableCell>
                <TableCell>{masAddressInfo && (masAddressInfo.address + (masAddressInfo.address2 ? ' ,' + masAddressInfo.address2 : '') + ' ,' + masAddressInfo.city + ' ,' + masAddressInfo.state + ' ,' + masAddressInfo.zip )}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default AddressViewPopup;
