
import React, { Component } from "react"; 

import axios from "axios";
import { BASE_URL } from "../../../config";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import moment from "moment";


import Button from "@mui/material/Button";


import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CustomRadioGroup from "../../components/CustomRadioGroup";
import InvoiceDialog from "../../components/InvoiceDialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Card , Autocomplete, TextField, Alert, Divider } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import LinearProgress from "@mui/material/LinearProgress";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "@mui/material/Modal";
import SearchIcon from "@mui/icons-material/Search";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { styled } from "@mui/material/styles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

import dayjs, { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
function RosterDisplayOne({
	tablerows,
	setSearchOrder,
fetchTripRoster,
viewRosterPdf}){
	
	const formatPhoneNumber = (phoneNumber) => {
	  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
	  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	  if (match) {
	    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	  }
	  return phoneNumber;
	};
	
	return    <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                              className="trip-results-data-table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell className="trip-results-head-cell">
                                    Type{" "}
                                  </TableCell>
                                  <TableCell className="trip-results-head-cell">
                                    Action{" "}
                                  </TableCell>
                                  <TableCell className="trip-results-head-cell">
                                    <Grid container direction="row">
                                      <Grid item xs={8}>
                                        Invoice
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div class="sort-wrapper">
                                          <div
                                            class="sort-wrapper-up"
                                            title="InvoiceNumber ascending"
                                            onClick={() => {
                                              setSearchOrder(
                                                "invoiceNumber",
                                                "ASC"
                                              );
                                            }}
                                          >
                                            <ArrowDropUpIcon />{" "}
                                          </div>
                                          <div
                                            class="sort-wrapper-down"
                                            title="InvoiceNumber descending"
                                            onClick={() => {
                                              setSearchOrder(
                                                "invoiceNumber",
                                                "DESC"
                                              );
                                            }}
                                          >
                                            {" "}
                                            <ArrowDropDownIcon />{" "}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={8}>
                                        PA#
                                      </Grid>
                                      <Grid item xs={4}></Grid>
                                      <Grid item xs={8}>
                                        SO Exp Date
                                      </Grid>
                                      <Grid item xs={4}></Grid>
                                    </Grid>

                                    
                                  </TableCell>
                                  <TableCell className="trip-results-head-cell">
                                    <Grid container direction="row">
                                      <Grid item xs={8}>
                                        Caller Info
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div class="sort-wrapper">
                                          <div
                                            class="sort-wrapper-up"
                                            onClick={() => {
                                              setSearchOrder(
                                                "callerName",
                                                "ASC"
                                              );
                                            }}
                                          >
                                            <ArrowDropUpIcon />{" "}
                                          </div>
                                          <div
                                            class="sort-wrapper-down"
                                            onClick={() => {
                                              setSearchOrder(
                                                "callerName",
                                                "DESC"
                                              );
                                            }}
                                          >
                                            {" "}
                                            <ArrowDropDownIcon />{" "}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={8}>
                                        Status
                                      </Grid>
                                      <Grid item xs={4}></Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell className="trip-results-head-cell">
                                    <Grid container direction="row">
                                      <Grid item xs={8}>
                                        Trip Date
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div class="sort-wrapper">
                                          <div
                                            class="sort-wrapper-up"
                                            onClick={() => {
                                              setSearchOrder(
                                                "pickupDate",
                                                "ASC"
                                              );
                                            }}
                                          >
                                            <ArrowDropUpIcon />{" "}
                                          </div>
                                          <div
                                            class="sort-wrapper-down"
                                            onClick={() => {
                                              setSearchOrder(
                                                "pickupDate",
                                                "DESC"
                                              );
                                            }}
                                          >
                                            {" "}
                                            <ArrowDropDownIcon />{" "}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={8}>
                                        Mode
                                      </Grid>
                                      <Grid item xs={4}></Grid>
                                      <Grid item xs={8}>
                                        Last Updated
                                      </Grid>
                                      <Grid item xs={4}></Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell className="trip-results-head-cell">
                                    {/*  */}
                                    <Grid container direction="row">
                                      <Grid item xs={8}>
                                        Enrollee Driver
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div class="sort-wrapper">
                                          <div
                                            class="sort-wrapper-up"
                                            onClick={() => {
                                              setSearchOrder(
                                                "firstName",
                                                "ASC"
                                              );
                                            }}
                                          >
                                            <ArrowDropUpIcon />{" "}
                                          </div>
                                          <div
                                            class="sort-wrapper-down"
                                            onClick={() => {
                                              setSearchOrder(
                                                "firstName",
                                                "DESC"
                                              );
                                            }}
                                          >
                                            {" "}
                                            <ArrowDropDownIcon />{" "}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={8}>
                                        Vehicle
                                      </Grid>
                                      <Grid item xs={4}></Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell className="trip-results-head-cell">
                                    Trip Legs
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tablerows.data.map((row) => (
                                  <>
                                  {row.triplegs.map((leg, legIndex) => (
                                    <>
                                    {legIndex == 0 ? 
                                      <TableRow key={row.name}>
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        >
                                          {row.triplegs.length > 1 ? (
                                            <SyncAltIcon />
                                          ) : (
                                            <>
                                              <div></div>
                                            </>
                                          )}
                                          {row.isStandingOrderTrip === 1 ? (
                                            <AllInclusiveIcon />
                                          ) : (
                                            <>
                                              <div></div>
                                            </>
                                          )}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        >
                                          <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                              fetchTripRoster(
                                                row.invoiceNumber
                                              );
                                            }}
                                          >
                                            View
                                          </Button>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        >
                                          <Grid container direction="column" spacing={1}>
                                            <Grid item sx={{ textAlign: 'left' }}>{row.invoiceNumber}</Grid>
                                            <Divider variant="middle" />
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              {(row.exAcpriorAuthorizationNumber !=null && row.exAcpriorAuthorizationNumber !== "" ) ? ( row.exAcpriorAuthorizationNumber ) : "" }
                                            </Grid>
                                            <Divider variant="middle" />
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              {(row.standingOrderEndDate != null && row.standingOrderEndDate !== "" ) ? ( moment( row.standingOrderEndDate).format("MM/DD/YYYY")  ) : "" }
                                            </Grid>
                                            {row.exAcattested === 1 && <Divider variant="middle" />}
                                            <Grid item sx={{ textAlign: 'left' }}>
                                            {(row.exAcattested === 1 &&  ( row.exAcpriorAuthorizationNumber  === '' ||  row.exAcpriorAuthorizationNumber == null ) ? <><Alert severity="warning">PA#</Alert></> : "" )}
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        >
                                          <Grid container direction="column" spacing={1}>
                                            <Grid item sx={{ textAlign: 'left' }}>{row.status}</Grid>
                                            <Divider variant="middle" />
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              {row.callerName}
                                            </Grid>
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              {formatPhoneNumber(row.callerPhone)}
                                            </Grid>
                                          </Grid>
                                          
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        >
                                          <Grid container direction="column" spacing={1}>
                                            <Grid item sx={{ textAlign: 'left' }}>{moment(row.pickupDate).format("MM/DD/YYYY")}</Grid>
                                            <Divider variant="middle" />
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              {row.serviceType}
                                            </Grid>
                                            <Divider variant="middle" />
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              {moment(row.lastModified).format("MM/DD/YYYY")}
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        >                                          
                                          <Grid container direction="column" spacing={1}>
                                            <Grid item sx={{ textAlign: 'left', fontWeight: 'bold' }}>{row.firstName} {row.lastName}</Grid>
                                            <Grid item sx={{ textAlign: 'left' }}>{moment(row.DOB).format("MM/DD/YYYY")}</Grid>
                                            <Grid item sx={{ textAlign: 'left' }}>{row.medicaidNumber}</Grid>
                                            <Grid item sx={{ textAlign: 'left' }}>{formatPhoneNumber(row.customer.contactnumber)}</Grid>
                                            
                                            {leg.driverName && 
                                            <>
                                              <Divider variant="middle" />
                                              <Grid item sx={{ textAlign: 'left' }}>
                                                {leg.driverName}
                                              </Grid>
                                              <Divider variant="middle" />
                                              <Grid item sx={{ textAlign: 'left' }}>
                                                {leg.vehicleId}
                                              </Grid>
                                            </>
                                            }
                                            
                                          </Grid>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          rowSpan="1"
                                          className="trip-results-data-cell"
                                        >

                                          <Grid container direction="column" spacing={1}>
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              <span style={{fontWeight: 'bold'}}>Pickup: </span>
                                                      {moment(
                                                        row.pickupTime,
                                                        "HH:mm"
                                                      ).format("hh:mm A")}{" "}
                                                      &nbsp;
                                                      {leg.pickup.address} &nbsp;
                                                      {leg.pickup.address2}
                                                      <br />
                                                      {leg.pickup.city} &nbsp;
                                                      {leg.pickup.state} &nbsp; ,
                                                      {leg.pickup.zip}{" "}
                                            </Grid>
                                            
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              <span style={{fontWeight: 'bold'}}>DropOff: </span>
                                                      {moment(
                                                        row.dropoffTime,
                                                        "HH:mm"
                                                      ).format("hh:mm A")}{" "}
                                                      &nbsp;
                                                      {leg.dropoff.address} &nbsp;
                                                      {leg.dropoff.address2}{" "}
                                                      <br />
                                                      {leg.dropoff.city} &nbsp;
                                                      {leg.dropoff.state} &nbsp; ,
                                                      {leg.dropoff.zip}{" "}
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                      </TableRow>
                                      : 
                                      // 
                                      <TableRow key={row.name}>
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        />
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        />
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        />
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        />
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        />
                                        <TableCell
                                          align="right"
                                          className="trip-results-data-cell"
                                        >
                                          {leg.driverName && 
                                          <Grid container direction="column" spacing={1}>
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              {leg.driverName}
                                            </Grid>
                                            <Divider variant="middle" />
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              {leg.vehicleId}
                                            </Grid>
                                          </Grid>
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          rowSpan="1"
                                          className="trip-results-data-cell"
                                        >
                                          <Grid container direction="column" spacing={1}>
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              <span style={{fontWeight: 'bold'}}>Pickup: </span>
                                                      {moment(
                                                        row.pickupTime,
                                                        "HH:mm"
                                                      ).format("hh:mm A")}{" "}
                                                      &nbsp;
                                                      {leg.pickup.address} &nbsp;
                                                      {leg.pickup.address2} &nbsp;
                                                      <br />
                                                      {leg.pickup.city} &nbsp;
                                                      {leg.pickup.state} &nbsp; ,
                                                      {leg.pickup.zip}{" "}
                                            </Grid>
                                            
                                            <Grid item sx={{ textAlign: 'left' }}>
                                              <span style={{fontWeight: 'bold'}}>DropOff: </span>
                                                      {moment(
                                                        row.dropoffTime,
                                                        "HH:mm"
                                                      ).format("hh:mm A")}{" "}
                                                      &nbsp;
                                                      {leg.dropoff.address} &nbsp;
                                                      {leg.dropoff.address2}{" "}
                                                      <br />
                                                      {leg.dropoff.city} &nbsp;
                                                      {leg.dropoff.state} &nbsp; ,
                                                      {leg.dropoff.zip}{" "}
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                      </TableRow>
                                      // 
                                      }
                                    </>
                                  ))}
                                    {/* <TableRow key={row.name}>
                                      <TableCell align="right">
                                        &nbsp;
                                      </TableCell>
                                      <TableCell align="right">
                                        &nbsp;
                                      </TableCell>
                                      <TableCell align="right">
                                        {" "}
                                        &nbsp;{" "}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className="trip-results-data-cell"
                                      >
                                        {row.callerName} <br />{" "}
                                        { formatPhoneNumber(row.callerPhone)}{" "}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className="trip-results-data-cell"
                                      >
                                        {row.serviceType}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className="trip-results-data-cell"
                                      >
                                        {formatPhoneNumber(row.recipientPhone)}
                                      </TableCell>
                                    </TableRow>{" "} */}
                                    <TableRow>
                                      {" "}
                                      <TableCell
                                        align="right"
                                        colSpan="7"
                                        className="trip-results-space-cell"
                                      >
                                        &nbsp;
                                      </TableCell>
                                    </TableRow>{" "}
                                  </>
                                ))}
                              </TableBody>
                            </Table>  ;
}
export default RosterDisplayOne ; 