import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid, Switch } from '@mui/material';

const DispatchNotification = ({ open, onClose, onConfirm, onCancel, notificationData, onUpdateContent }) => {
  const [content, setContent] = useState({});
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setContent(notificationData ? notificationData.content : {});
    setChecked(false);
  }, [notificationData]);

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ backgroundColor: '#f5fafe', color: '#033d5f!important', fontWeight: 'bold' }}>Dispatch Notification</DialogTitle>
      <DialogContent>
      <Grid container>
        <Grid item xs={4} style={{marginTop: 10, marginBottom: 10}}>
          <div>Driver</div>
        </Grid>
        <Grid item xs={8} style={{marginTop: 10, marginBottom: 10}}>
          <div style={{ color: notificationData.driverInfo ? 'inherit' : 'red' }}>{notificationData.driverInfo ? notificationData.driverInfo.firstName + ' ' + notificationData.driverInfo.license : 'Please select a driver'}</div>
        </Grid>
        <Grid item xs={4} style={{marginTop: 10, marginBottom: 10}}>
          Enrollee Name
        </Grid>
        <Grid item xs={8} style={{marginTop: 10, marginBottom: 10}}>
          <div>{notificationData.customerName}</div>
        </Grid>
        <Grid item xs={4} style={{marginTop: 10, marginBottom: 10}}>
          Contact Number
        </Grid>
        <Grid item xs={8} style={{marginTop: 10, marginBottom: 10}}>
          <div>{notificationData.customerContact}</div>
        </Grid>
        <Grid item xs={4} style={{marginTop: 10, marginBottom: 10}}>
          Pickup Address and time
        </Grid>
        <Grid item xs={8} style={{marginTop: 10, marginBottom: 10}}>
          <div>{notificationData.pickaddress} - {notificationData.pickupDate} {notificationData.actPickUp}</div>
        </Grid>
        <Grid item xs={4} style={{marginTop: 10, marginBottom: 10}}>
          Drop-off Address
        </Grid>
        <Grid item xs={8} style={{marginTop: 10, marginBottom: 10}}>
          <div >{notificationData.dropoffAddress}</div>
        </Grid>
        <Grid item xs={4} style={{marginTop: 10, marginBottom: 10}}>
          Return
        </Grid>
        <Grid item xs={8} style={{marginTop: 10, marginBottom: 10}}>
          <div>
            <Switch checked={checked} onChange={handleChange} />
          </div>
        </Grid>
        <Grid item xs={4}>
          Comments
        </Grid>
        <Grid item xs={8} style={{ border: '1px solid #ceeafd' }}>
          <TextField
            id="outlined-basic"
            placeholder="Message"
            value={content}
            onChange={handleContentChange}
            fullWidth
            required
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
        
        
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {onConfirm({comment: content, returning: checked, driverId: notificationData.driverInfo.id, masTripId: notificationData.id, invoiceNumber: notificationData.invoiceNumber, tripLegSequence: notificationData.tripLegSequence, pickupDate: notificationData.pickupDate, actPickUp: notificationData.actPickUp})}} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DispatchNotification;
