import axios from "axios";
const config = require("../config");

const BASE_URL = `${config.BASE_URL}`;

const handleErrors = (response) => {
  if (response.status < 200 && response.status > 299) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return response.data;
};

const getHeaders = () => {
  let _user = { active: false, name: "" };
  if (localStorage.getItem("session")) {
    _user = JSON.parse(localStorage.getItem("session"));
  }
  const headers = {
    "Content-Type": "application/json",
    "x-auth-key": _user.key,
  };

  return headers;
};

const filterMaintenanceRecords = async (filter) => {
  try {
    const response = await axios.post(
      BASE_URL + "/maintenance-management/filter",
      filter,
      {
        headers: getHeaders(),
      }
    );
    return handleErrors(response);
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

const getMaintenanceData = async (id) => {
  try {
    const response = await axios.get(
      BASE_URL + `/maintenance-management/maintenance-details`,
      {
        params: {id: id},
        headers: getHeaders(),
      }
    );
    return handleErrors(response);
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

const updateMaintenanceDataResolved = async (id, status) => {
  try {
    const response = await axios.put(
      BASE_URL + `/maintenance-management/maintenance-details/resolve/${id}`, {resolved: status},
      {
        headers: getHeaders(),
      }
    );
    return handleErrors(response);
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

export { filterMaintenanceRecords, getMaintenanceData, updateMaintenanceDataResolved };
