import React, { useState, useEffect } from "react";
import "fontsource-roboto";
import { BASE_URL } from "../../config";

import { forwardRef } from "react";
import MaterialTable from "material-table";
import axios from "axios";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select
} from "@mui/material";
import {
  ArrowDownward,
  ChevronLeft,
  ChevronRight,
  Clear,
  Download,
  FileDownloadDone,
  FirstPage,
  LastPage,
  Search,
  Visibility,
} from "@mui/icons-material";
import { StyleSheet } from "@react-pdf/renderer";
import { DateRangePicker, DateRange } from "mui-daterange-picker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { StyledHeader } from "../../styled";
import { makeStyles } from "@mui/styles";

const api = axios.create({
  baseURL: BASE_URL,
});

const gridIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const styles = StyleSheet.create({
  loaderStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
});

const useStyles = makeStyles((theme) => ({
  datePreview: {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  inputContainer: {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  downloadAllBtn: {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
}));

function DriverTimeSheet() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [driverId, setDriverId] = useState(-1);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState();
  const [openViewPDF, setOpenViewPDF] = useState(false);
  const [viewPDF, setViewPDF] = useState();
  const [hideDownload, setHideDownload] = useState(true);

  const toggle = () => setOpen(!open);

  const closeView = () => {
    setOpenViewPDF(false);
  };

  let user = { active: false, name: "" };
  if (localStorage.getItem("session")) {
    user = JSON.parse(localStorage.getItem("session"));
  }

  const api = axios.create({
    baseURL: BASE_URL,
    headers: {
      "x-auth-key": user.key,
    },
  });

  var columns = [
    { title: "id", field: "id", hidden: true },
    {
      field: "driver",
      title: "Driver",
      render: (rowData) =>
        `${rowData.firstName} - ${rowData.vehicletype} ${rowData.vehiclename} - ${rowData.license}`,
    },
    { title: "Date", field: "tripDate" },
    {
      title: "Action",
      field: "action",
      render: (rowData) =>
        rowData && (
          <>
            <IconButton
              style={{ padding: 12 }}
              onClick={() => view(rowData)}
              title="View Time Sheet"
            >
              <Visibility />
            </IconButton>
            <IconButton
              style={{ padding: 12 }}
              onClick={() => download(rowData)}
              title="Download"
            >
              <Download />
            </IconButton>
          </>
        ),
    },
  ];

  useEffect(() => {
    initScreen();
  }, []);

  const initScreen = async () => {
    setHideDownload(true);
    setPageLoading(true);
    loadAllDrivers();
    const today = moment(new Date()).format("YYYY-MM-DD");
    setDateRange({ startDate: today, endDate: today });
    await loadDriverInfo(today, today);
  };

  const changeDateFilter = (range) => {
    setPageLoading(true);
    toggle();
    setDateRange(range);
    loadDriverInfo(
      moment(range.startDate).format("YYYY-MM-DD"),
      moment(range.endDate).format("YYYY-MM-DD"),
      driverId
    );
  };

  const changeDriverFilter = (value) => {
    setPageLoading(true);
    setDriverId(value);
    loadDriverInfo(
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      value
    );
  };

  async function loadDriverInfo(from, to, driverId) {
    setHideDownload(true);
    const result = await api.get(
      "/timesheet/load?fromDate=" +
        from +
        "&toDate=" +
        to +
        (driverId != null && driverId != -1 ? "&driverId=" + driverId : "")
    );
    setData(result.data);
    if(result.data.length>0){
      setHideDownload(false);
    }
    setPageLoading(false);
  }

  async function loadAllDrivers() {
    const result = await api.get("driveruser/all");
    setDrivers(result.data.data);
  }

  const downloadAll = () => {
    setPageLoading(true);
    api
      .post(
        "/timesheet/download/all",
        {
          fromDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
          toDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
          driverId: driverId,
        },
        { responseType: "blob" }
      )
      .then(function (response) {
        console.log(response.headers);
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "TimeSheets.zip"); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        setPageLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setPageLoading(false);
      });
  };

  const view = (data) => {
    setPageLoading(true);
    api
      .post("/timesheet/download", {
        fromDate: moment(data.tripDate).format("YYYY-MM-DD"),
        toDate: moment(data.tripDate).format("YYYY-MM-DD"),
        driverId: data.id,
      })
      .then(function (response) {
        if (response.data.pdfURL) {
          setViewPDF(response.data.pdfURL);
          setOpenViewPDF(true);
        }

        setPageLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setPageLoading(false);
      });
  };

  const download = (data) => {
    setPageLoading(true);
    api
      .post("/timesheet/download", {
        fromDate: moment(data.tripDate).format("YYYY-MM-DD"),
        toDate: moment(data.tripDate).format("YYYY-MM-DD"),
        driverId: data.id,
      })
      .then(function (response) {
        if (response.data.pdfURL) {
          window.open(response.data.pdfURL);
        }

        setPageLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setPageLoading(false);
      });
  };

  return (
    <div className="App">
      <Grid container p={0}>
        <Grid item md={12} sm={12} xs={12} >
          {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
        </Grid>
        <Grid item md={12} sm={12} xs={12} >
          <StyledHeader style={{textAlign: 'left'}}>
            Driver Time Sheet
          </StyledHeader>
        </Grid>
        <Grid container p={1}>
          <Grid container lg={6} md={12} xs={12} className={classes.inputContainer}>
            <Grid container lg={2} md={1} xs={12} style={{marginTop: '16px', minWidth: '170px'}} className={classes.datePreview}>
              {moment(dateRange.startDate).format("MM/DD/YYYY")} -{" "}
              {moment(dateRange.endDate).format("MM/DD/YYYY")} &nbsp;
            </Grid>              
            <Grid item lg={2} md={2} xs={12} style={{marginTop: '10px', minWidth: '170px'}}>
              <Button
                variant="outlined"
                startIcon={<CalendarMonthIcon />}
                onClick={toggle}
                className="date-range-picker-button"
              >
                Date Range
              </Button>
              <DateRangePicker
                wrapperClassName="main-dashboard-date-range-wrapper"
                open={open}
                toggle={toggle}
                onChange={(range) => changeDateFilter(range)}
              />
            </Grid>
            <Grid item lg={2} md={2} xs={12}>
              <Select
                  labelId="driver-label"
                  id="driver-label-select"
                  style={{ width: 200 }}
                  value={selectedDriver}
                  onChange={(event) => {
                    changeDriverFilter(event.target.value);
                  }}
                  label="Driver"
                >
                  <MenuItem value="-1" selected={true}>
                    All
                  </MenuItem>
                  {drivers ? (
                    drivers.map((driver) => {
                      return (
                        <MenuItem value={driver.id}>
                          {driver.firstName + " " + driver.licencse}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value="-1" selected={true}>
                      All
                    </MenuItem>
                  )}
              </Select>
            </Grid>               
          </Grid>
          <Grid container lg={6} md={12} xs={12} style={{marginTop: '10px'}} className={classes.downloadAllBtn}>
            <Button
                disabled={hideDownload}
                variant="outlined"
                startIcon={<FileDownloadDone />}
                onClick={downloadAll}
                className="date-range-picker-button"
              >
                Download All
              </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <div>
            {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
          </div>
          
          <MaterialTable
            title=""
            columns={columns}
            icons={gridIcons}
            data={data}
            options={{
              showTitle: false,
              search: false,
              searchFieldStyle: {
                display: "none",
              },
              rowStyle: {
                fontSize: 12,
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={openViewPDF}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <button onClick={closeView} style={{ float: "right" }}>
            Close
          </button>
          <iframe
            title="PDF Viewer"
            src={viewPDF}
            width="100%"
            height="6500px"
          />
        </Box>
      </Modal>

      <Modal
        open={pageLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.loaderStyle}>
          <CircularProgress /> Loading ...
        </Box>
      </Modal>
    </div>
  );
}

export default DriverTimeSheet;
