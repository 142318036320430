import React, { useEffect, useState } from 'react';
import { Close, ExpandMore } from '@mui/icons-material';
import { Dialog, AppBar, Toolbar, Typography, IconButton, DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { StyledButton } from '../../../styled';
import GeneralDetails from './GeneralDetails';
import MedicaidInformation from './MedicaidInformation';
import MedicaidAddressDetails from './MedicaidAddressDetails';
import AddressDetailView from './AddressDetailView';
import MedicaidSpecialTiming from './MedicaidSpecialTiming';
import {
  createClientCustomer,
  updateClientCustomer
} from "../../../api/CustomerManagementApi"
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  buttonContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  dialogContainer: {
    margin: theme.spacing(2)
  }
}));

const AddEditCustomer = ({ open, onClose, customer }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel1');
  const [values, setValues] = useState({});
  const action = {action: 'edit'}

  useEffect(() => {
    setValues(customer)
  }, [customer])

  const handleSave = async() => {
    
    const request = {...values, long: values.lng}

    try {
      console.log("Value to be saved:", values);
      
      if(request.id) {
        const response = await updateClientCustomer(request)
        console.log('[response] : ', response);
      } else {
        const response = await createClientCustomer(request)
        console.log('[response] : ', response);
      }
      
      toast.success((request.id ? "Updated ! " : "Created ! ") , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      onClose();
    } catch (error) {
      console.log('[Error] : ', error);
      toast.error((request.id ? "Update failed. Please try again later" : "Create failed. Please try again later"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <ToastContainer />
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" height="xl" className={classes.dialogContainer}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h3" className={classes.title}>
              {customer?.id == null ? "New Customer" : "Edit Customer"}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{height: '95vh'}}>
          <Grid container>
              <Grid item md={12}>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{
                    paddingBottom:2
                    }}
                    disableGutters={true}
                    >
                      <AccordionSummary 
                          expandIcon={<ExpandMore />}
                          aria-controls="panel3a-content"
                          id="panel1a-header"
                          
                      >
                      <Typography>Medicaid Information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MedicaidInformation values={values}
                              setValues={setValues}
                              action={action.action}/>
                      </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{
                    paddingBottom:2
                    }}
                    disableGutters={true}
                    >
                      <AccordionSummary 
                          expandIcon={<ExpandMore />}
                          aria-controls="panel3a-content"
                          id="panel1a-header"
                          
                      >
                      <Typography>General Information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <GeneralDetails 
                              values={values}
                              setValues={setValues}
                              action={action.action}
                          />
                      </AccordionDetails>
                  </Accordion>
                  
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{
                    paddingBottom:2
                    }}
                    disableGutters={true}>
                    <AccordionSummary 
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                            <Typography>Address Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>                      
                        <MedicaidAddressDetails 
                            values={values}
                            setValues={setValues}
                            action={action.action}
                        />
                        <AddressDetailView 
                            values={values}
                            setValues={setValues}
                            action={action.action}
                        />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{
                    paddingBottom:2
                    }}
                    disableGutters={true}
                    >
                      <AccordionSummary 
                          expandIcon={<ExpandMore />}
                          aria-controls="panel3a-content"
                          id="panel1a-header"
                          
                      >
                      <Typography>Medicaid Special Timing</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MedicaidSpecialTiming values={values}
                            setValues={setValues}
                            action={action.action}/>
                      </AccordionDetails>
                  </Accordion>
              </Grid>
          </Grid>
          </DialogContent>
          <DialogActions>
              <StyledButton variant="contained" onClick={(e)=>{handleSave()}}>
                  Save
              </StyledButton>
              <StyledButton variant="outlined" onClick={(e)=>{onClose()}}>
                  Close
              </StyledButton>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditCustomer;
