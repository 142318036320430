import  {Grid, Paper}  from "@mui/material";
import React, {useEffect, useState} from "react";
import { StyledFileupload, StyledSectionHeader } from "../../../styled";
import { styled } from "@mui/material/styles";

const config = require("../../../config")
const staticURL = config.BASE_URL;

// console.log(config)


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    display:"flex", 
    justifyContent:"space-between", 
    alignItems:"end",
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor:theme.palette.background.frame,
    width:"100%",
    marginBottom:theme.spacing(3),
  }));


const initProfileContent = {
    profilePicture: "",
    signatureList: ["","","",""],
    profilePictureContent: null,
    signatureListContent: [],
}  

function ImageUploadView({driverDetails, action, values, setValues}) {
    // const [values, setValues] = useState(initProfileContent)
    
    useEffect(()=>{
        if(driverDetails != null){
            setValues({    
                ...values,
                profilePicture: values.profilePicture?`${values.profilePicture}`:"",
                profilePictureContent: null,
            });
            // console.log(values, driverDetails.profilePicture);
        }
        
    },[action.action, action.driverid])

    function uploadProfileImage(e) {
        if(e.target.files.length > 0){
            setValues({    
                ...values,
                profilePicture: URL.createObjectURL(e.target.files[0]),
                profilePictureContent: e.target.files[0]
            });
        }
    }

    return (
        <Grid container style={{paddingRight:20}}>
            <Grid item md={12}>
                <div>
                     <StyledSectionHeader>
                        Profile picture
                     </StyledSectionHeader>
                </div> 
                <Item>
                   <div>
                    <img src={`${values.profilePicture}`}
                    onError={(e) => {
                        if(e.target.onerror == null){
                          (e.target.src = "/404.png")
                        }}}
                         width={100} height={100} ></img>
                   </div> 
                    <div>
                        <StyledFileupload 
                            multiple={false} 
                            labeltext={"Select a file"} 
                            uploadFunction={uploadProfileImage} 
                        />
                        <div>
                            JPG, GIF, or PNG max size 3 mb.
                        </div>
                    </div>
                </Item>
            </Grid>
        </Grid>
    )
}

function SignatureView({driverDetails, action, values, setValues}) {
    useEffect(()=>{
        if(values != null){
            setValues({    
                ...values,
                signatureList: values.signatureListContent.length!=0?values.signatureListContent.map((mp=>{
                    // console.log(`${staticURL}/${mp}`);
                    return(`${staticURL}/${mp}`)
                })):["","","",""],
                signatureListContent: [],
            });
        }
    },[action.action, action.driverid])


    
    
    function uploadSignatureImage(e, id) {
        if(e.target.files.length > 0){
            let signatures = values.signatureList;
            let signaturesContent = values.signatureListContent;
            signatures[id-1] = URL.createObjectURL(e.target.files[0])
            signaturesContent[id-1] = e.target.files[0]
            setValues({    
                ...values,
                signatureList:signatures,
                signatureListContent: signaturesContent
            });
        }
    }
    return (
        <Grid container style={{paddingRight:20}}>
            <Grid item md={12}>
                <div>
                     <StyledSectionHeader>
                        Profile Signature
                     </StyledSectionHeader>
                </div> 
                <Item style={{flexDirection:"row",  justifyContent:"space-between", textAlign:"center"}}>
                    
                    {
                        values.signatureList?.map((ps, index) => {
                            return (
                                <div key={index}> 
                                    <div>
                                        <img src={`${ps}`}
                                        onError={(e) =>{
                                            if(e.target.onerror == null){
                                                (e.target.src = "/404.png")
                                        }}}
                                        width={100} height={100}></img>
                                    </div> 
                                    <StyledFileupload 
                                        multiple={false} 
                                        labeltext={`Signature ${(index + 1)}`} 
                                        id={`${index + 1}`}
                                        uploadFunction={uploadSignatureImage} />
                                </div>
                            )
                        })
                    }
                </Item>
            </Grid>
        </Grid>
    )
}

function DriverProfileView ({driverDetails, action, values, setValues}) {
    return (
        <Grid container style={{
            marginLeft:20
        }}>
            <Grid item md={12}>
                <ImageUploadView 
                driverDetails={driverDetails}
                action={action}
                values={values}
                setValues={setValues}
                />
            </Grid>
            <Grid item md={12}>
                <SignatureView 
                driverDetails={driverDetails}
                action={action}
                values={values}
                setValues={setValues}
                />
            </Grid>
        </Grid>
        
    )
}

export default DriverProfileView;