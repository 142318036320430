import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Chip } from "@mui/material";
import DriverSearch from "./DriverSearch";

const preventDefault = (event) => event.preventDefault();

export default function DriverFilter({ onFilterChange , filter, driverList, setAction, setFilteredDrivers }) {
  const [filterSelected, setFilterSelected] = React.useState("all")

  const handleFilterChange = (filterType) => {
    onFilterChange(filterType);
    setFilterSelected(filterType)
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",
        gap: "20px",
      }}
      onClick={preventDefault}
    >
      <Chip
        href="#"
        underline="hover"
        variant={filterSelected=="all"?"filled":"outlined"}
        color={filterSelected=="all"?"primary":"default"}
        label="All"
        style={{minWidth:"70px", fontWeight:"bold"}}
        onClick={() => handleFilterChange("all")}
      />
        
      <Chip
        href="#"
        underline="hover"
        variant={filterSelected=="assigned"?"filled":"outlined"}
        color={filterSelected=="assigned"?"primary":"default"}
        style={{fontWeight:"bold"}}
        label="Asigned"
        onClick={() => handleFilterChange("assigned")}
      />
      <Chip
        href="#"
        underline="hover"
        variant={filterSelected=="unassigned"?"filled":"outlined"}
        color={filterSelected=="unassigned"?"primary":"default"}
        style={{fontWeight:"bold"}}
        label="Unassigned"
        onClick={() => handleFilterChange("unassigned")}
      />
      <Chip
        href="#"
        underline="hover"
        variant={filterSelected=="active"?"filled":"outlined"}
        color={filterSelected=="active"?"primary":"default"}
        style={{fontWeight:"bold"}}
        label="Active"
        onClick={() => handleFilterChange("active")}
      />
      <Chip
        href="#"
        underline="hover"
        variant={filterSelected=="inactive"?"filled":"outlined"}
        color={filterSelected=="inactive"?"primary":"default"}
        style={{fontWeight:"bold"}}
        label="Inactive"
        onClick={() => handleFilterChange("inactive")}
      />
      <DriverSearch filter={filter}  driverList={driverList} setFilteredDrivers={setFilteredDrivers}/>
    </Box>
  );
}
