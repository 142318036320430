import React from "react";
import { StyledButton, StyledHeader, StyledIconWrapper, StyledWrapper } from "../../../styled";
import { Grid, IconButton } from "@mui/material";
import { AddCircleOutline, ListAltOutlined, Padding } from "@mui/icons-material";

const DriverManagementHeader = ({setAction,  action}) => {

  const showHideDriverList = () =>{
    if(action.action != "list"){
      setAction({
        "driverId":null,
        "action":"list"
      })
    }    
    else
      {
        setAction({
          "driverId":null,
          "action":"create"
        })
      }
  }
  
  return (
    <Grid container>
      <Grid item md={12}>
        <StyledHeader>
          Driver Management 
        </StyledHeader>
      </Grid>
      {/* <Grid item md={12} style={{textAlign:"end"}}>
      <IconButton style={{padding:0}} variant={"contained"} theme onClick={()=>{showHideDriverList()}}>
       <StyledIconWrapper style={{padding:0}} >
        {
           action.action!= "list"?
            <ListAltOutlined fontSize="large" />
            : null
        }
        </StyledIconWrapper>
      </IconButton>
      </Grid> */}
    </Grid>  
  );
};

export default DriverManagementHeader;
