import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import moment from "moment";
import MaterialTable from "material-table";

import Button from "@mui/material/Button";

import "react-toastify/dist/ReactToastify.css";


import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import LinearProgress from "@mui/material/LinearProgress";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Divider from "@mui/material/Divider";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { styled } from "@mui/material/styles";
import { withStyles } from '@mui/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const styles = (theme) => ({
  dialog: {
    width: '100%',
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
});

class InvoiceDialog extends Component {
  constructor(props) {
    super(props);
    let url = new URL(window.location.href);

    this.state = {};

    this.getAge = this.getAge.bind(this);
    this.tripLegStatus = this.tripLegStatus.bind(this);
    this.isCancelledFilter = this.isCancelledFilter.bind(this);
  }
  componentDidMount() {
    console.log(this.props.getSelectedInvoice());
  }

  getAge(dateString) {
    var ageInMilliseconds = new Date() - new Date(dateString);
    return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
  }

  tripLegStatus(trip) {
    let stat = "";
    if (parseInt(trip.vendorAttestation) == 1) {
      stat = "Leg Active";
    } else if (parseInt(trip.vendorAttestation) == 2) {
      stat = "Leg Deleted";
    } else {
      if (
        trip.GPS.noshowTimestamp.length > 0 ||
        parseInt(trip.vendorAttestation) == 3 ||
        parseInt(trip.existingActions.attested) == 3
      ) {
        stat = "Leg No Show";
      } else {
        if (this.isCancelledFilter(trip.status)) {
          stat = "Leg Deleted";
        } else {
          stat = "Leg Active";
        }
      }
    }
    return stat;
  }
  isCancelledFilter(what) {
    let tf = false;
    what = what.toLowerCase();
    let arr = ["cancel"];
    if (what.indexOf(arr[0]) > -1) {
      tf = true;
    }
    return tf;
  }
  render() {
    const { classes, open, onClose, children } = this.props;
    let selectedInvoice = this.props.getSelectedInvoice();

    return (
      <>
        {selectedInvoice.trips ? (
          <>
            <Dialog
              className={classes.dialog}
              fullWidth={true}
              open={this.props.selectedInvoiceOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.props.setSelectedInvoiceOpen}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"View Trip"}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="trip-popup-bold"> 
                    Medical Provider NPI:{" "}
                    {selectedInvoice.trips[0].medProviderNPI}
                  </Grid>
                  <Grid item xs={12} className="trip-popup-bold">
                    Medicaid Number: {selectedInvoice.trips[0].medicaidNumber}
                  </Grid>
                  <Grid item xs={12} className="trip-popup-bold">
                    Invoice number: {selectedInvoice.trips[0].invoiceNumber}
                  </Grid>
                  <Grid item xs={12} className="trip-popup-bold">
                    This trip has {selectedInvoice.trips.length} legs.
                  </Grid>
                  <Grid item xs={12} className="trip-popup-allcaps">
                    PLEASE TAKE NOTICE: FILING FALSE STATEMENTS IS A CRIME
                  </Grid>
                  <Grid item xs={12} className="trip-popup-normal" >
                    If you attest to a trip that did not take place, you are
                    filing a false statement and committing a crime.
                  </Grid>
                  <Grid item xs={12} className="trip-popup-bold">
                    Anyone filing a false statement will be prosecuted to the
                    fullest extent of the law.
                  </Grid>
                  <Grid item xs={12} className="trip-popup-normal">
                    Trip for: {selectedInvoice.trips[0].firstName}{" "}
                    {selectedInvoice.trips[0].lastName}{" "}
                    {selectedInvoice.trips[0].recipientPhone} - Called in by:{" "}
                    {selectedInvoice.trips[0].callerName}{" "}
                    {selectedInvoice.trips[0].callerPhone} - TRANSPORT TYPE:{" "}
                    {selectedInvoice.trips[0].transportType} - PROC CODE:{" "}
                    {selectedInvoice.trips[0].primaryProcedureCode} , MOD:{" "}
                    {selectedInvoice.trips[0].primaryProcedureCodeModifier}
                  </Grid>
                  <Grid item xs={12}>
                    Age: {this.getAge(selectedInvoice.trips[0].DOB)} - Total
                    Trip: ${" "}
                    {parseFloat(
                      selectedInvoice.trips[0].totalTripCost,
                      10
                    ).toFixed(2)}
                  </Grid>
                  <Divider variant="middle" />
                  {selectedInvoice.trips.map((row) => (
                    <>
                      <Grid item xs={12}>
                        <Table className="trip-results-leg-table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Pick-up Date </TableCell>
                              <TableCell>Pick-up Time </TableCell>
                              <TableCell>Drop-off Date </TableCell>
                              <TableCell>Drop-off Time </TableCell>
                              <TableCell>Pick-up </TableCell>
                              <TableCell>Drop-off</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{row.pickupDate}</TableCell>
                              <TableCell>
                                {moment(row.pickupTime, "HHmm").format(
                                  "hh:mm A"
                                )}{" "}
                              </TableCell>
                              <TableCell>{row.dropoffDate} </TableCell>
                              <TableCell>
                                {moment(row.dropoffTime, "HHmm").format(
                                  "hh:mm A"
                                )}{" "}
                              </TableCell>
                              <TableCell>
                                {row.pickupAddress.address}{" "}
                                {row.pickupAddress.address2}{" "}
                                {row.pickupAddress.city}{" "}
                                {row.pickupAddress.state}{" "}
                                {row.pickupAddress.zip}{" "}
                              </TableCell>
                              <TableCell>
                                {row.dropoffAddress.address}{" "}
                                {row.dropoffAddress.address2}{" "}
                                {row.dropoffAddress.city}{" "}
                                {row.dropoffAddress.state}{" "}
                                {row.dropoffAddress.zip}{" "}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      {row.secondary_services ? (
                        <>
                          <Grid item xs={12}>
                            <Table className="trip-results-leg-table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Secondary Service </TableCell>
                                  <TableCell>Proc Code </TableCell>
                                  <TableCell>Proc Code Mod </TableCell>
                                  <TableCell>
                                    Units or Mileage For ALL of Today's Legs{" "}
                                  </TableCell>
                                  <TableCell>Pick-up Date </TableCell>
                                  <TableCell colSpan={2}>
                                    Trip From/To
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.secondary_services.map((srow) => (
                                  <>
                                    <TableRow>
                                      <TableCell>{srow.service_name} </TableCell>
                                      <TableCell>{srow.service_procedure_code} </TableCell>
                                      <TableCell>{srow.service_procedure_code_modifier} </TableCell>
                                      <TableCell>
                                      {srow.service_quantity}
                                      </TableCell>
                                      <TableCell>{row.pickupDate}</TableCell>
                                      <TableCell >  {row.pickupAddress.address} </TableCell>
                                      <TableCell > {row.dropoffAddress.address}{" "} </TableCell>
                                    </TableRow>{" "}
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      <Grid item xs={12} className="trip-popup-bold">
                        {" "}
                        Status: {this.tripLegStatus(row)}{" "}
                      </Grid>
                      <Divider variant="middle" />
                    </>
                  ))}
                     <Grid item xs={2} className="trip-popup-bold">
                     Additional Rider(s) : {selectedInvoice.trips[0].instructions.additionalRiders?"YES":"NO"}
                  </Grid>
                  <Grid item xs={2} className="trip-popup-bold">
                  Car Seats : {selectedInvoice.trips[0].instructions.carSeats?"YES":"NO"}
                  </Grid>
                  <Grid item xs={2} className="trip-popup-bold">
                  Service Animal : {selectedInvoice.trips[0].instructions.serviceAnimal?"YES":"NO"}
                  </Grid>
                </Grid>
                
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.props.setSelectedInvoiceOpen(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                   
let c= document.getElementsByClassName("invoice-dialog")[0].innerHTML;
                   var win = window.open();

    var isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = navigator.userAgent.indexOf("Safari") > -1;
    win.document.write(c);
    win.focus();
    if (!isChrome && !isSafari) {
      setTimeout(function () {
        win.print();
        win.close();
        setTimeout(function () {
          win.close();
        }, 0);
      }, 100);
    } else {
      setTimeout(function () {
        win.focus();
        win.print();
        setTimeout(function () {
          win.close();
        }, 500);
      }, 500);
    }
                  }}
                >
                  Print
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withStyles(styles)(InvoiceDialog);
