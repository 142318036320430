import {
  React,
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useRef,
} from "react";
import {
  Box,
  ClickAwayListener,
  Grid,
  LinearProgress,
  Button,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  SvgIcon,
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import CloseIcon from "@mui/icons-material/Close";
import { attestationList } from "./Attestations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { darken, lighten, styled } from "@mui/material/styles";

import AttestationConfirmationDialog from "./components/Dialog/AttestationConfirmationDialog";
import AttestInvoiceStatusDialog from "./components/Dialog/AttestInvoiceStatusDialog";
import { StyledAvatar } from "../../styled";
import Slide from "@mui/material/Slide";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../config";
import { getGPSDateForInvoice } from "../../api/FleetActivityApi";
import { computeLength } from "@googlemaps/js-api-loader";
import Slider from "@mui/material/Slider";

import CarMarkerIcon from "../../assets/icons/car.png";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const momentTZ = require("moment-timezone");

const datagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 2 },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#f5fafe",
    color: "#033d5f",
    fontWeight: "bold !important",
    fontSize: 14,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold !important",
    color: "#033d5f !important",
    overflow: "visible",
    lineHeight: "1.43rem",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-cell": {
    paddingTop: 3,
    paddingBottom: 3,
  },
};

const useStyle = makeStyles({
  root: {
    "& .wrapHeader .MuiDataGrid-colCellTitle": {
      overflow: "visible",
      lineHeight: "1.43rem",
      whiteSpace: "normal",
    },
  },
  red: {
    backgroundColor: "red",
  },
});

const getBackgroundColor = (color, mode) => {
  return mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
};
const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const AttestationDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .planner-view-grid--O-0": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.light,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.light,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.light,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.light,
          theme.palette.mode
        ),
      },
    },
  },
  "& .planner-view-grid--O-1": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
    },
  },

  "& .planner-view-grid--O-3": {
    backgroundColor: getBackgroundColor(
      theme.palette.secondary.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <GridToolbarExport
        csvOptions={{
          allColumns: true,
          fileName: `${moment(new Date()).format(
            "YYYY-MM-DD HH:mm:ss"
          )}_mas_roster`,
        }}
      />
    </GridToolbarContainer>
  );
}

const AttestInvoiceView = (props) => {
  const classes = useStyle();

  const [showUpateConfimation, setShowUpateConfimation] = useState(false);
  const [showAttestionStatus, setShowAttestationStatus] = useState(false);
  const [attest, setAttest] = useState(false);
  const [attestationData, setAttestationData] = useState({});
  const [attestationEnvironment, setAttestationEnvironment] = useState("Prod");
  const [attestationResponse, setAttestationResponse] = useState({});
  const [rows, setRows] = useState([]);
  const [masDrivers, setMasDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [sendBreadcrumbs, setSendBreadcrumbs] = useState(false);
  const [selectedIds, setSelectedIds] = useState({});
  const [messageType, setMessageType] = useState("attest & invoice");
  const [reload, setReload] = useState(true);

  const [showRoute, setShowRoute] = useState(false);
  const [data, setData] = useState({});
  const [mapZoom, setMapZoom] = useState(6);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [gpsTripPath, setGpsTripPath] = useState([]);
  const [breadcrumbsPath, setBreadcrumbsPath] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);
  const [actualChecked, setActualChecked] = useState(false);
  const [syntheticChecked, setSyntheticChecked] = useState(false);
  const [gpsWaypoints, setGpsWaypoints] = useState([]);
  const [breadcrumbsWaypoints, setBreadcrumbsWaypoints] = useState([]);

  const [actualStartPoint, setActualStartPoint] = useState(null);
  const [actualPickupPoint, setActualPickupPoint] = useState(null);
  const [actualEndPoint, setActualEndPoint] = useState(null);

  const [syntheticStartPoint, setSyntheticStartPoint] = useState(null);
  const [syntheticPickupPoint, setSyntheticPickupPoint] = useState(null);
  const [syntheticEndPoint, setSyntheticEndPoint] = useState(null);

  const [gpsEnabled, setGPSEnabled] = useState(null);
  const [gpsMode, setGPSMode] = useState(null);
  const [actualGPSAvailable, setActualGPSAvailable] = useState(false);
  const [syntheticMovingMarker, setSyntheticMovingMarker] = useState(null);
  const [gpsMovingMarker, setGpsMovingMarker] = useState(null);

  const [breadCrumbsAnimationPath, setBreadCrumbsAnimationPath] =
    useState(null);
  const [breadCrumbsSpeed, setBreadCrumbsSpeed] = useState(10);
  const breadCrumbsSpeedRef = useRef(breadCrumbsSpeed);
  const breadCrumbsAnimationRef = useRef(null);
  const breadCrumbsStartTimeRef = useRef(null);

  const [gpsAnimationPath, setGPSAnimationPath] = useState(null);
  const [gpsSpeed, setGPSSpeed] = useState(10);
  const gpsSpeedRef = useRef(gpsSpeed);
  const gpsAnimationRef = useRef(null);
  const gpsStartTimeRef = useRef(null);

  const mapRef = useRef();

  useEffect(() => {
    gpsSpeedRef.current = gpsSpeed;
  }, [gpsSpeed]);

  useEffect(() => {
    breadCrumbsSpeedRef.current = breadCrumbsSpeed;
  }, [breadCrumbsSpeed]);

  useEffect(() => {
    async function initSendBreadcrumbs() {
      if (sendBreadcrumbs) {
        try {
          const response = await attestationList.sendBreadcrumbs(
            attestationEnvironment,
            attestationData.type,
            attestationData.invoiceNumbers,
            attestationData.tripDate,
            attestationData.drivers
          );

          if (response.status == 200) {
            let invoices = [];
            for (let inv of attestationData.invoiceNumbers) {
              invoices.push(inv.invoiceNumber);
            }
            setAttestationResponse({
              ...attestationResponse,
              breadcrumbs: response.data,
              timtDelta: new Date().getTime(),
            });
            setShowAttestationStatus(true);
          }
        } catch (error) {
          console.log(error);
          toast.error(`[Error] ${error.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      setSendBreadcrumbs(false);
    }
    initSendBreadcrumbs();
  }, [sendBreadcrumbs]);

  const handleActualChange = (event) => {
    setActualChecked(event.target.checked);
  };

  const handleSyntheticChange = (event) => {
    setSyntheticChecked(event.target.checked);
  };

  useEffect(() => {
    let _portalConfig = {};
    if (localStorage.getItem("portalConfig")) {
      _portalConfig = JSON.parse(localStorage.getItem("portalConfig"));
    }

    const gpsModeConfig =
      _portalConfig.find((config) => config.keyName === "gpsMode")?.value ||
      null;
    const gpsEnabledConfig =
      _portalConfig.find((config) => config.keyName === "GPS")?.value || null;

    setGPSMode(gpsModeConfig);
    setGPSEnabled(gpsEnabledConfig);

    async function getMasDrivers() {
      await attestationList
        .fetchMasDrivers()
        .then((res) => {
          setMasDrivers(res);
        })
        .catch((error) => {
          setMasDrivers([]);
        });
    }
    getMasDrivers();
  }, []);

  useEffect(() => {
    console.log(props, reload);
    setReload(props.searchAttrs.reload);
  }, [props]);

  useEffect(() => {
    if (reload) {
      if (Object.keys(props.searchAttrs).length === 0) {
        attestInvoiceData({
          tripDate: moment(new Date()).format("YYYY-MM-DD"),
        });
      } else {
        attestInvoiceData({
          tripDate: props.searchAttrs.tripDate,
          invoiceNo: props.searchAttrs.invoiceNo,
        });
      }
      setReload(false);
    }
  }, [reload]);

  const attestInvoiceData = useCallback(async (searchAttrs) => {
    setRows([]);
    setLoading(true);
    let _invoiceNumbers = [];
    let _rows = [];
    let invoice = {};
    let invoiceNumber = null;
    let prevInvoiceNumber = null;
    await attestationList
      .getAttestInvoiceData(searchAttrs)
      .then((res) => {
        res.forEach((rec) => {
          invoiceNumber = rec.invoiceNumber;
          if (invoiceNumber != prevInvoiceNumber) {
            if (invoice.invoiceNumber) {
              _rows.push(invoice);
              invoice = {};
            }

            invoice.invoiceNumber = rec.invoiceNumber;
            invoice.combinewith = "";
            invoice.tripDate = rec.tripDate;

            invoice.pickup_dropoff_address = [];
            invoice.pickuplatlng_dropofflatlng = [];
            invoice.mas_pickup_dropoff_time = [];
            invoice.act_pickup_dropoff_time = [];
            invoice.driverName = [];
            invoice.vehicle = [];
            invoice.id = rec.id;
          }
          // create a check to know if enable or dissable attestation / no show
          {
            const dropOffDateTime = moment(`${moment(rec.tripDate).format(
              "MM-DD-YYYY"
            )} 
                                              ${
                                                rec.dropoff_time != null
                                                  ? moment(
                                                      rec.dropoff_time
                                                    ).format("HH:mm:ss")
                                                  : rec.masDropoffTime.length !=
                                                    0
                                                  ? moment(
                                                      rec.masDropoffTime,
                                                      "HHmm"
                                                    ).format("HH:mm:ss")
                                                  : moment(
                                                      rec.masPickupTime,
                                                      "HHmm"
                                                    )
                                                      .add(2, "hours")
                                                      .format("HH:mm:ss")
                                              }`);
            // const currDateTime = moment(momentTZ.tz(moment(), 'America/New_York')).format('DD/MM/YYYY HH:mm');
            const currDateTime = moment();
            // console.log(dropOffDateTime, currDateTime, rec);
            rec.diff = currDateTime.diff(dropOffDateTime, "minutes");
          }
          rec.pickup_dropoff_address = `${rec.pickaddress}|||${rec.dropoffAddress}`;
          rec.mas_pickup_dropoff_time = `${rec.masPickupTime}|||${rec.masDropoffTime}`;
          rec.act_pickup_dropoff_time = `${
            rec.pickup_time != null
              ? moment(rec.pickup_time).format("HH:mm:ss")
              : "-"
          }
                                          |||${
                                            rec.dropoff_time != null
                                              ? moment(rec.dropoff_time).format(
                                                  "HH:mm:ss"
                                                )
                                              : "-"
                                          }`;
          rec.tripDate = moment(rec.tripDate).format("MM-DD-YYYY");
          rec.combinewith = "";
          rec.scheduled = rec.pickup_time == null ? false : true;
          rec.driverName = rec.driverName;
          rec.driverNameFormatted =
            rec.driverName || rec.driverName != null
              ? rec.driverName.replaceAll(" ", "").toLowerCase()
              : rec.driverName;
          invoice.pickup_dropoff_address.push(rec.pickup_dropoff_address);
          invoice.mas_pickup_dropoff_time.push(rec.mas_pickup_dropoff_time);
          invoice.act_pickup_dropoff_time.push(rec.act_pickup_dropoff_time);
          invoice.driverName.push(rec.driverName);
          invoice.vehicle.push(rec.vehicle);
          prevInvoiceNumber = rec.invoiceNumber;
        });
        // setInvoiceNumbers(_invoiceNumbers)
        // console.log(res)
        setRows([...res]);
      })
      .catch((error) => {
        setRows([]);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const columns = useMemo(() => {
    const cols = [
      {
        headerName: "Actions",
        field: "actions",
        type: "actions",
        width: 100,
        height: 100,
        renderCell: (params) => {
          if (gpsMode === "A") {
            params.row.gpsMode = "Actual";
          } else if (gpsMode === "S") {
            params.row.gpsMode = "Synthetic";
          }

          const status = params.row.status;
          const stat_arr = ["Eligible", "Cancelled/Combined"];
          console.log("params.row.roster_status", params.row.roster_status);
          return (
            <div
              style={{
                paddingLeft: 5,
                paddingTop: 4,
                paddingBottom: 4,
                width: 100,
                display: "flex",
              }}
            >
              <div style={{ marginLeft: 5, width: 30 }}>
                {stat_arr.includes(status) && (
                  <IconButton
                    variant="contained"
                    size="medium"
                    color={params.row?.attested > 0 ? "white" : "primary"}
                    style={{ width: 10 }}
                    tabIndex={params.hasFocus ? 0 : -1}
                    disabled={
                      params.row.diff < 0 || params.row.driverName == null
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      handleAttestInvoice(
                        "Attest",
                        params.row.invoiceNumber,
                        params.row.triplegId,
                        params.row.tripDate,
                        params.row.gpsCount,
                        params.row.gpsMode
                      );
                    }}
                  >
                    {/* <Check /> */}
                    <StyledAvatar
                      enabled={
                        params.row.diff < 0 ||
                        params.row.driverName == null ||
                        params.row.roster_status?.includes("Blocked")
                          ? false
                          : true
                      }
                      success={params.row.attested == 1}
                      alt="Attest"
                    >
                      A
                    </StyledAvatar>
                  </IconButton>
                )}
              </div>
              <div style={{ width: 30 }}>
                <IconButton
                  variant="contained"
                  size="medium"
                  color={params.row?.attested > 0 ? "white" : "primary"}
                  style={{ width: 10 }}
                  tabIndex={params.hasFocus ? 0 : -1}
                  onClick={(e) => {
                    handleAttestInvoice(
                      "Cancel",
                      params.row.invoiceNumber,
                      params.row.triplegId,
                      params.row.tripDate,
                      params.row.gpsCount,
                      params.row.gpsMode
                    );
                  }}
                >
                  {/* <Cancel /> */}
                  <StyledAvatar
                    success={params.row.status == "Cancelled"}
                    enabled={
                      params.row.roster_status?.includes("Blocked")
                        ? false
                        : true
                    }
                    alt="Cancel"
                  >
                    C
                  </StyledAvatar>
                </IconButton>
              </div>
              <div style={{ width: 30 }}>
                <IconButton
                  variant="contained"
                  size="medium"
                  color={params.row?.attested > 0 ? "white" : "primary"}
                  style={{ width: 10 }}
                  tabIndex={params.hasFocus ? 0 : -1}
                  disabled={
                    !params.row.scheduled ||
                    params.row.driverName == null ||
                    (params.row.attested == 1 && params.row.driverMatch == 0)
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    handleAttestInvoice(
                      "NoShow",
                      params.row.invoiceNumber,
                      params.row.triplegId,
                      params.row.tripDate,
                      params.row.gpsCount,
                      params.row.gpsMode
                    );
                  }}
                >
                  {/* <NoShow /> */}
                  <StyledAvatar
                    success={
                      params.row.status == "Cancelled" &&
                      params.row.tripLegStatus == "2"
                    }
                    enabled={
                      !params.row.scheduled ||
                      (params.row.attested == 1 &&
                        params.row.driverMatch == 0) ||
                      params.row.roster_status?.includes("Blocked")
                        ? false
                        : true
                    }
                    alt="No Show"
                  >
                    N
                  </StyledAvatar>
                </IconButton>
              </div>
              <div style={{ width: 30 }}>
                {stat_arr.includes(status) && (
                  <IconButton
                    variant="contained"
                    size="medium"
                    color={params.row?.attested > 0 ? "white" : "primary"}
                    style={{ width: 10 }}
                    tabIndex={params.hasFocus ? 0 : -1}
                    disabled={
                      !params.row.scheduled ||
                      (params.row.attested == 1 && params.row.driverMatch == 0)
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      handleAttestInvoice(
                        "Invoice",
                        params.row.invoiceNumber,
                        params.row.triplegId,
                        params.row.tripDate,
                        params.row.gpsCount,
                        params.row.gpsMode
                      );
                    }}
                  >
                    {/* <Check /> */}
                    <StyledAvatar
                      success={
                        params.row.mas_status != null &&
                        params.row.mas_status == "Invoice"
                      }
                      enabled={
                        !params.row.scheduled ||
                        (params.row.attested == 1 &&
                          params.row.driverMatch == 0) ||
                        params.row.roster_status?.includes("Blocked")
                          ? false
                          : true
                      }
                      alt="Invoice"
                    >
                      I
                    </StyledAvatar>
                  </IconButton>
                )}
              </div>
            </div>
          );
        },
      },
      {
        field: "invoiceNumber",
        headerName: "Invoice Number / PA ",
        width: 120,
        editable: false,
        sortable: true,
        renderCell: (params) => {
          let invoiceNumber = params.row.invoiceNumber;
          let PAnumber = params.row.PAnumber;
          return (
            <div style={{ paddingTop: 4, paddingBottom: 4, borderBottom: 1 }}>
              <div style={{ marginBottom: 5 }}>{invoiceNumber} /</div>
              <div>{PAnumber}</div>
            </div>
          );
        },
      },
      {
        field: "triplegId",
        headerName: "LegId",
        type: "number",
        width: 120,
        editable: false,
        sortable: true,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "tripDate",
        headerName: "Date",
        type: "number",
        width: 120,
        editable: false,
        sortable: true,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "customerName",
        headerName: "Customer",
        width: 150,
        editable: false,
        sortable: true,
      },
      {
        headerName: "Pickup / Dropoff Address",
        field: "pickup_dropoff_address",
        sortable: true,
        editable: false,
        width: 320,
        renderCell: (params) => {
          const parts = params.row.pickup_dropoff_address.split("|||");
          return (
            <div style={{ paddingTop: 4, paddingBottom: 4, borderBottom: 1 }}>
              <div style={{ marginBottom: 5 }}>
                <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                {parts[0]}
              </div>
              <div>
                <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                {parts.length > 0 ? parts[1] : ""}
              </div>
            </div>
          );
        },
      },
      {
        field: "mas_pickup_dropoff_time",
        headerName: "MAS Time",
        sortable: true,
        editable: false,
        width: 150,
        renderCell: (params) => {
          const parts = params.row.mas_pickup_dropoff_time.split("|||");
          return (
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
              <div style={{ marginBottom: 5 }}>
                <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                {parts[0]}
              </div>
              <div>
                <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                {parts.length > 0 ? parts[1] : ""}
              </div>
            </div>
          );
        },
      },
      {
        field: "act_pickup_dropoff_time",
        headerName: "Act. Time",
        sortable: true,
        editable: false,
        width: 150,
        renderCell: (params) => {
          const parts = params.row.act_pickup_dropoff_time.split("|||");
          return (
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
              <div style={{ marginBottom: 5 }}>
                <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                {parts[0]}
              </div>
              <div>
                <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                {parts.length > 0 ? parts[1] : ""}
              </div>
            </div>
          );
        },
      },
      {
        field: "driverNameFormatted",
        headerName: "Driver / Vehicle",
        sortable: true,
        editable: false,
        width: 200,
        type: "singleSelect",
        renderCell: (params) => {
          return (
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
              <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                {params.row.driverName} &nbsp;-&nbsp;{params.row.vehicle}&nbsp;
              </div>
              {params.row.attested == 1 && params.row.driverMatch == 0 ? (
                <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                  <strong style={{ color: "#033d5f" }}>Attested: </strong>{" "}
                  {params.row.attestedDriver} &nbsp;-&nbsp;
                  {params.row.attestedVehicle}&nbsp;
                </div>
              ) : null}
            </div>
          );
        },
        valueOptions: [
          ...new Set(
            masDrivers.map((option) =>
              option.firstname.replaceAll(" ", "").toLowerCase()
            )
          ),
        ],
      },
    ];

    if (gpsEnabled == 1) {
      if (gpsMode == "H") {
        cols.push({
          field: "gpsMode",
          headerName: "GPS Mode",
          sortable: false,
          editable: false,
          width: 75,
          align: "center",
          renderCell: (params) => {
            if (params.row.gpsCount == 0) {
              params.row.gpsMode = "Synthetic";
            }

            const handleToggleChange = (event, val) => {
              params.row.gpsMode = val;
              params.api.updateRows([params.row]);
            };

            return (
              <ToggleButtonGroup
                exclusive
                aria-label="Platform"
                value={params.row.gpsMode} // Bind value directly for controlled component
                onChange={handleToggleChange}
              >
                {params.row?.gpsCount > 0 && (
                  <ToggleButton
                    value="Actual"
                    selected={params.row.gpsMode == "Actual"}
                    sx={{
                      backgroundColor: "#baf9e9",
                      color: "black",
                      "&.Mui-selected": {
                        backgroundColor: "#1b614f",
                        color: "white",
                      },
                      "&:hover": {
                        backgroundColor: "#46a18a",
                        color: "white",
                      },
                    }}
                  >
                    A
                  </ToggleButton>
                )}

                <ToggleButton
                  value="Synthetic"
                  selected={params.row.gpsMode == "Synthetic"}
                  sx={{
                    backgroundColor: "#efa8a5",
                    color: "black",
                    "&.Mui-selected": {
                      backgroundColor: "#91201b",
                      color: "white",
                    },
                    "&:hover": {
                      backgroundColor: "#bd443f",
                      color: "white",
                    },
                  }}
                >
                  S
                </ToggleButton>
              </ToggleButtonGroup>
            );
          },
        });
      }

      cols.push({
        field: "btnPolyline",
        headerName: "Route",
        sortable: false,
        editable: false,
        width: 75,
        align: "center",
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="polyline"
              color="success"
              disabled={gpsMode == "A" && params.row.gpsCount}
              onClick={(e) => {
                openRoute(params.row);
              }}
            >
              <MapIcon />
            </IconButton>
          );
        },
      });
    }

    cols.push(
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        editable: false,
        width: 120,
        type: "action",
      },
      {
        headerName: "Select",
        type: "actions",
        width: 100,
        height: 100,
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
      }
    );

    return cols;
  }, [gpsMode, masDrivers]);

  const processRowUpdate = useCallback(
    (newRow) => {
      const index = rows.findIndex((row) => row.id === newRow.id);
      const tempRows = [...rows];
      tempRows[index] = newRow;
      setRows(tempRows);

      return newRow;
    },
    [rows, masDrivers]
  );

  const handleMultiAttestInvoice = async (type) => {
    return new Promise(async (resolve, reject) => {
      let invoiceNumbers = [];
      let tripDate = null;
      let drivers = new Set();
      let gpsError = false;
      for (let id of selectedIds) {
        let row = rows.find((r) => r.id === id);

        if (!row.gpsMode) {
          gpsError = true;
        }

        if (row) {
          invoiceNumbers.push({
            invoiceNumber: row.invoiceNumber,
            triplegId: row.triplegId,
            gpsAvailable: row.gpsCount > 0,
            gpsMode: row.gpsMode,
          });
          tripDate = row.tripDate;
          drivers.add(row.driver_id);
        }
      }

      if (gpsError && type === "Invoice") {
        toast.error(`Please select the GPS mode for all selected invoices.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return null;
      }
      // console.log([...drivers].join(','))
      try {
        setAttestationData({
          type: type,
          invoiceNumbers: invoiceNumbers,
          tripDate: tripDate,
          drivers: drivers.size == 0 ? null : [...drivers].join(","),
        });
        setMessageType(`${type} and invoice `);
        handleShowUpateConfimation();
      } catch (error) {
        toast.error(`[Error] ${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      resolve(true);
    });
  };

  const handleAttestInvoice = async (
    type,
    invoiceNumber,
    triplegId,
    tripDate,
    gpsCount,
    gpsMode
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        let invoiceNumbers = [
          {
            invoiceNumber: invoiceNumber,
            triplegId: triplegId,
            gpsAvailable: gpsCount > 0,
            gpsMode: gpsMode,
          },
        ];

        if (type === "Invoice" && gpsMode == null) {
          toast.error(`Please select the GPS mode.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return null;
        }

        setAttestationData({
          type: type,
          invoiceNumbers: invoiceNumbers,
          tripDate: tripDate,
          drivers: null,
        });
        setMessageType(`${type} `);
        handleShowUpateConfimation();
        // await attestInvoice(type, invoiceNumbers, tripDate, drivers)
      } catch (error) {
        // console.log(error)
        toast.error(`[Error] ${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const handleShowUpateConfimation = () => {
    setAttestationResponse({});
    setShowUpateConfimation(true);
  };

  const handleCloseConfimation = (actionType) => {
    setShowUpateConfimation(false);
    if (actionType == "YES") {
      setShowAttestationStatus(true);
    } else {
      setShowAttestationStatus(false);
    }
  };

  const handleProcessRowUpdateError = useCallback((error) => {
    toast.error(`[Error] ${error.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  const openRoute = async (invoiceData) => {
    setActualStartPoint(null);
    setActualPickupPoint(null);
    setActualEndPoint(null);
    setGpsWaypoints([]);

    setSyntheticStartPoint(null);
    setSyntheticPickupPoint(null);
    setSyntheticEndPoint(null);
    setBreadcrumbsWaypoints([]);

    setData(invoiceData);
    setActualChecked(true);
    setSyntheticChecked(true);
    //setIsMapLoaded(false);
    setShowRoute(true);
    setActualGPSAvailable(invoiceData.gpsCount > 0);

    setTimeout(() => {
      loadRouteDetails(invoiceData);
    }, 500);
  };

  useEffect(() => {
    calculateMapCenter();
  }, [
    gpsTripPath,
    breadcrumbsPath,
    syntheticChecked,
    actualChecked,
    isMapLoaded,
  ]);

  const loadRouteDetails = async (invoiceData) => {
    try {
      const gpsData = await getGPSDateForInvoice({
        invoice: invoiceData.invoiceNumber,
      });

      const gpsLegData = gpsData.filter(
        (item) => item.triplegid === Number(invoiceData.triplegId)
      );
      const gpsRoutePath = gpsLegData.map((point) => ({
        lat: Number(point.latitude),
        lng: Number(point.longitude),
        milestone: point.status,
      }));

      setGpsTripPath(gpsRoutePath);
    } catch (error) {
      setGpsTripPath([]);
    }

    try {
      const breadcrumbs = await attestationList.loadBreadcrumbs(
        invoiceData.invoiceNumber,
        invoiceData.triplegId
      );

      const breadcrumbRoutePath = breadcrumbs.map((point) => ({
        lat: Number(point.lat),
        lng: Number(point.lng),
        milestone: point.milestone,
      }));
      setBreadcrumbsPath(breadcrumbRoutePath);
    } catch (error) {
      setBreadcrumbsPath([]);
    }
  };

  const calculateBoundingBox = (paths) => {
    let minLat = paths[0].lat;
    let maxLat = paths[0].lat;
    let minLng = paths[0].lng;
    let maxLng = paths[0].lng;

    paths.forEach((point) => {
      minLat = Math.min(minLat, point.lat);
      maxLat = Math.max(maxLat, point.lat);
      minLng = Math.min(minLng, point.lng);
      maxLng = Math.max(maxLng, point.lng);
    });

    return { minLat, maxLat, minLng, maxLng };
  };

  const calculateCenter = ({ minLat, maxLat, minLng, maxLng }) => {
    const latCenter = (minLat + maxLat) / 2;
    const lngCenter = (minLng + maxLng) / 2;
    return { lat: latCenter, lng: lngCenter };
  };

  const fitMapToBounds = (path, center) => {
    if (window.google && window.google.maps && mapRef && mapRef.current) {
      const directionsService = new window.google.maps.DirectionsService();

      if (gpsTripPath && gpsTripPath.length > 0) {
        const gpswaypoints = gpsTripPath.map((point) => ({
          location: new window.google.maps.LatLng(point.lat, point.lng),
          stopover: false,
        }));

        directionsService.route(
          {
            origin: gpswaypoints[0].location,
            destination: gpswaypoints[gpswaypoints.length - 1].location,
            waypoints: gpswaypoints.slice(1, -1),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              const route = result.routes[0].overview_path.map((point) => ({
                lat: point.lat(),
                lng: point.lng(),
              }));

              setGPSAnimationPath(route);
              startGPSAnimation(route);
            } else {
              console.error(`Directions request failed due to ${status}`);
            }

            const startMilestones = gpsTripPath.filter(
              (item) => item.milestone === "Dispatching_Leg"
            );
            const pickupMilestones = gpsTripPath.filter(
              (item) => item.milestone === "Arriving_At_Pickup"
            );
            const endMilestones = gpsTripPath.filter(
              (item) => item.milestone === "Completing_Leg"
            );

            setActualStartPoint(startMilestones[0]);
            setActualPickupPoint(pickupMilestones[0]);
            setActualEndPoint(endMilestones[0]);
          }
        );
      }

      if (breadcrumbsPath && breadcrumbsPath.length > 0) {
        const breadcrumbswaypoints = breadcrumbsPath.map((point) => ({
          location: new window.google.maps.LatLng(point.lat, point.lng),
          stopover: false,
        }));

        directionsService.route(
          {
            origin: breadcrumbswaypoints[0].location,
            destination:
              breadcrumbswaypoints[breadcrumbswaypoints.length - 1].location,
            waypoints: breadcrumbswaypoints.slice(1, -1),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              const route = result.routes[0].overview_path.map((point) => ({
                lat: point.lat(),
                lng: point.lng(),
              }));

              setBreadCrumbsAnimationPath(route);
              startBreadcrumbsAnimation(route);
            } else {
              console.error(`Directions request failed due to ${status}`);
            }

            const startMilestones = breadcrumbsPath.filter(
              (item) => item.milestone === "Dispatching_Leg"
            );
            const pickupMilestones = breadcrumbsPath.filter(
              (item) => item.milestone === "Arriving_At_Pickup"
            );
            const endMilestones = breadcrumbsPath.filter(
              (item) => item.milestone === "Completing_Leg"
            );

            setSyntheticStartPoint(startMilestones[0]);
            setSyntheticPickupPoint(pickupMilestones[0]);
            setSyntheticEndPoint(endMilestones[0]);
          }
        );
      }

      mapRef.current.panTo(center);

      const bounds = new window.google.maps.LatLngBounds();
      path.forEach((coord) => bounds.extend(coord));

      mapRef.current.fitBounds(bounds);
    }
  };

  const startGPSAnimation = (path) => {
    setGpsWaypoints([]);

    if (path && path.length > 0) {
      const totalPoints = path.length;

      const animate = (currentTime) => {
        if (!gpsStartTimeRef.current) gpsStartTimeRef.current = currentTime;
        const elapsedTime = currentTime - gpsStartTimeRef.current;

        const totalDuration = gpsSpeedRef.current * 1000;
        const currentIndex = Math.min(
          Math.floor((elapsedTime / totalDuration) * totalPoints),
          totalPoints - 1
        );

        setGpsWaypoints((prevPath) => {
          const newPath = path.slice(0, currentIndex + 1);
          return newPath;
        });

        setGpsMovingMarker(path[currentIndex]);

        if (currentIndex < totalPoints - 1) {
          gpsAnimationRef.current = requestAnimationFrame(animate);
        } else {
          cancelAnimationFrame(gpsAnimationRef.current);
          gpsStartTimeRef.current = null;
          setGpsMovingMarker(null);
        }
      };

      gpsAnimationRef.current = requestAnimationFrame(animate);
    }
  };

  const startBreadcrumbsAnimation = (path) => {
    setBreadcrumbsWaypoints([]);

    if (path && path.length > 0) {
      const totalPoints = path.length;

      const animate = (currentTime) => {
        if (!breadCrumbsStartTimeRef.current)
          breadCrumbsStartTimeRef.current = currentTime;
        const elapsedTime = currentTime - breadCrumbsStartTimeRef.current;

        const totalDuration = breadCrumbsSpeedRef.current * 1000;
        const currentIndex = Math.min(
          Math.floor((elapsedTime / totalDuration) * totalPoints),
          totalPoints - 1
        );

        setBreadcrumbsWaypoints((prevPath) => {
          const newPath = path.slice(0, currentIndex + 1);
          return newPath;
        });

        setSyntheticMovingMarker(path[currentIndex]);

        if (currentIndex < totalPoints - 1) {
          breadCrumbsAnimationRef.current = requestAnimationFrame(animate);
        } else {
          cancelAnimationFrame(breadCrumbsAnimationRef.current);
          breadCrumbsStartTimeRef.current = null;
          setSyntheticMovingMarker(null);
        }
      };

      breadCrumbsAnimationRef.current = requestAnimationFrame(animate);
    }
  };

  const calculateMapCenter = () => {
    let combinedPaths = [];
    if (
      actualChecked &&
      gpsMode != "S" &&
      gpsTripPath &&
      gpsTripPath.length > 0
    ) {
      combinedPaths = combinedPaths.concat(gpsTripPath);
    }

    if (
      syntheticChecked &&
      gpsMode != "A" &&
      breadcrumbsPath &&
      breadcrumbsPath.length > 0
    ) {
      combinedPaths = combinedPaths.concat(breadcrumbsPath);
    }

    if (isMapLoaded && combinedPaths.length > 0) {
      const bounds = calculateBoundingBox(combinedPaths);
      const center = calculateCenter(bounds);

      setMapCenter(center);

      fitMapToBounds(combinedPaths, center);
    }
  };

  const handleCloseRoute = () => {
    setShowRoute(false);
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleMapLoad = () => {
    setIsMapLoaded(true);
  };

  return (
    <>
      <Grid container>
        <ToastContainer />
        {showUpateConfimation && (
          <AttestationConfirmationDialog
            showUpateConfimation={showUpateConfimation}
            handleCloseConfimation={handleCloseConfimation}
            messageType={messageType}
          />
        )}
        {showAttestionStatus && (
          <AttestInvoiceStatusDialog
            showAttestionStatus={showAttestionStatus}
            setShowAttestationStatus={setShowAttestationStatus}
            attestationResponse={attestationResponse}
            attestationData={attestationData}
            setAttestationEnvironment={setAttestationEnvironment}
            setAttest={setAttest}
            setSendBreadcrumbs={setSendBreadcrumbs}
            setAttestationData={setAttestationData}
            setReload={setReload}
            gpsMode={gpsMode}
          />
        )}
        <Grid container>
          <Box sx={{ width: "100%" }}>
            <ClickAwayListener onClickAway={() => setRowSelectionModel([])}>
              <AttestationDataGrid
                rowHeight={50}
                rows={rows}
                columns={columns}
                sx={datagridSx}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                  setSelectedIds(new Set(newRowSelectionModel));
                }}
                rowSelectionModel={rowSelectionModel}
                slots={{
                  loadingOverlay: LinearProgress,
                  toolbar: CustomToolbar,
                }}
                loading={loading}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                pageSizeOptions={[25]}
                autoHeight
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                getRowClassName={(params) =>
                  `planner-view-grid--O-${
                    params.row?.attested == 1 && params.row?.driverMatch == 0
                      ? 0
                      : 2
                  }`
                }
                getRowHeight={() => "auto"}
                checkboxSelection
                disableRowSelectionOnClick
                disableColumnSelector
                // We check here if the trips leg is blocked.
                isRowSelectable={(params) => {
                  return params.row?.diff < 0 ||
                    params.row?.driverName == null ||
                    params.row.roster_status?.includes("Blocked")
                    ? false
                    : true;
                }}
              />
            </ClickAwayListener>
          </Box>
        </Grid>
        {rows?.length > 0 && (
          <Grid item sm={12} className={"attestations_container_button"}>
            <Button
              variant="contained"
              onClick={() => {
                handleMultiAttestInvoice("Attest");
              }}
            >
              Attest Selected
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleMultiAttestInvoice("Invoice");
              }}
            >
              Invoice Selected
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleMultiAttestInvoice("Cancel");
              }}
            >
              Cancel Selected
            </Button>
          </Grid>
        )}
      </Grid>

      <Dialog
        fullScreen
        open={showRoute}
        onClose={handleCloseRoute}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseRoute}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2 }} variant="h6" component="div">
              Customer - {data.customerName}
            </Typography>
            <Typography sx={{ ml: 2 }} variant="h6" component="div">
              Pickup - {data.pickaddress}{" "}
              {data.pickup_time != null && `(${data.pickup_time})`}
            </Typography>
            <Typography sx={{ ml: 2 }} variant="h6" component="div">
              Dropoff - {data.dropoffAddress}{" "}
              {data.dropoff_time != null && `(${data.dropoff_time})`}
            </Typography>
            <FormGroup sx={{ ml: 2 }} row>
              {actualGPSAvailable && gpsMode != "S" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actualChecked}
                      disabled={gpsMode == "A"}
                      onChange={handleActualChange}
                      sx={{
                        color: "#FF0000",
                        "&.Mui-checked": {
                          color: "#FF0000",
                        },
                      }}
                    />
                  }
                  label={gpsMode == "A" ? "Trip Route" : "Actual Route"}
                />
              )}
              {gpsMode != "A" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={syntheticChecked}
                      disabled={gpsMode == "S"}
                      onChange={handleSyntheticChange}
                      sx={{
                        color: "#34eb46",
                        "&.Mui-checked": {
                          color: "#34eb46",
                        },
                      }}
                    />
                  }
                  label={
                    gpsMode == "H"
                      ? "Synthetic Route"
                      : gpsMode == "S"
                      ? "Trip Route"
                      : "Expected Route"
                  }
                />
              )}
            </FormGroup>
          </Toolbar>
        </AppBar>
        <LoadScript googleMapsApiKey={GOOGLE_API_KEY} onLoad={handleMapLoad}>
          {isMapLoaded && (
            <GoogleMap
              mapContainerStyle={{
                position: "relative",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
              }}
              center={mapCenter}
              zoom={8}
              onLoad={onMapLoad}
              options={{ fullscreenControl: false }}
            >
              {actualStartPoint && window.google && (
                <Marker
                  visible={actualChecked && gpsMode != "S"}
                  position={gpsMovingMarker}
                  icon={{
                    url: CarMarkerIcon,
                    labelOrigin: new google.maps.Point(15, -10),
                    scaledSize: new window.google.maps.Size(30, 36),
                    anchor: new google.maps.Point(25, 25),
                    strokeWeight: 100,
                  }}
                />
              )}

              {actualStartPoint && window.google && (
                <Marker
                  visible={actualChecked && gpsMode != "S"}
                  position={actualStartPoint}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                    labelOrigin: new google.maps.Point(15, -10),
                  }}
                />
              )}

              {actualPickupPoint && window.google && (
                <Marker
                  visible={syntheticChecked && gpsMode != "A"}
                  position={actualPickupPoint}
                  label={"Pickup"}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
                    labelOrigin: new google.maps.Point(15, -10),
                  }}
                />
              )}

              {actualEndPoint && window.google && (
                <Marker
                  visible={actualChecked && gpsMode != "S"}
                  position={actualEndPoint}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                    labelOrigin: new google.maps.Point(15, -10),
                  }}
                />
              )}

              {syntheticStartPoint && window.google && (
                <Marker
                  visible={syntheticChecked && gpsMode != "A"}
                  position={syntheticMovingMarker}
                  icon={{
                    url: CarMarkerIcon,
                    labelOrigin: new google.maps.Point(15, -10),
                    scaledSize: new window.google.maps.Size(30, 36),
                    anchor: new google.maps.Point(25, 25),
                    strokeWeight: 100,
                  }}
                />
              )}

              {syntheticStartPoint && window.google && (
                <Marker
                  visible={syntheticChecked && gpsMode != "A"}
                  position={syntheticStartPoint}
                  label={"Dispatch"}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                    labelOrigin: new google.maps.Point(15, -10),
                    scaledSize: new window.google.maps.Size(30, 36),
                    strokeWeight: 100,
                  }}
                />
              )}
              {syntheticPickupPoint && window.google && (
                <Marker
                  visible={syntheticChecked && gpsMode != "A"}
                  position={syntheticPickupPoint}
                  label={"Pickup"}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
                    labelOrigin: new google.maps.Point(15, -10),
                  }}
                />
              )}
              {syntheticEndPoint && window.google && (
                <Marker
                  visible={syntheticChecked && gpsMode != "A"}
                  position={syntheticEndPoint}
                  label={"Dropoff"}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                    labelOrigin: new google.maps.Point(15, -10),
                  }}
                />
              )}
              <Polyline
                visible={actualChecked && gpsMode != "S"}
                path={gpsWaypoints}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 1.0,
                  strokeWeight: 8,
                }}
              />
              <Polyline
                visible={syntheticChecked && gpsMode != "A"}
                path={breadcrumbsWaypoints}
                options={{
                  strokeColor: "#34eb46",
                  strokeOpacity: 1.0,
                  strokeWeight: 8,
                }}
              />
            </GoogleMap>
          )}
        </LoadScript>
        {actualGPSAvailable && gpsMode != "S" && actualChecked && (
          <div
            style={{
              position: "absolute",
              top: 80,
              right: 10,
              zIndex: 10,
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography gutterBottom>
              {gpsMode == "A" ? "Trip Route" : "Actual Route"}
            </Typography>
            <Slider
              value={gpsSpeed}
              onChange={(e, newValue) => setGPSSpeed(newValue)}
              aria-labelledby="speed-slider"
              min={5}
              max={30}
              step={1}
              valueLabelDisplay="auto"
            />
            <Typography>Speed: {gpsSpeed} Sec</Typography>

            <button
              disabled={gpsMovingMarker}
              onClick={() => startGPSAnimation(gpsAnimationPath)}
            >
              Redraw
            </button>
          </div>
        )}

        {gpsMode != "A" && syntheticChecked && (
          <div
            style={{
              position: "absolute",
              top:
                actualGPSAvailable && gpsMode !== "S" && actualChecked
                  ? "240px"
                  : "80px",
              right: "10px",
              zIndex: 10,
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography gutterBottom>
              {gpsMode == "H"
                ? "Synthetic Route"
                : gpsMode == "S"
                ? "Trip Route"
                : "Expected Route"}
            </Typography>
            <Slider
              value={breadCrumbsSpeed}
              onChange={(e, newValue) => setBreadCrumbsSpeed(newValue)}
              aria-labelledby="speed-slider"
              min={5}
              max={30}
              step={1}
              valueLabelDisplay="auto"
            />
            <Typography>Speed: {breadCrumbsSpeed} Sec</Typography>

            <button
              disabled={syntheticMovingMarker}
              onClick={() =>
                startBreadcrumbsAnimation(breadCrumbsAnimationPath)
              }
            >
              Redraw
            </button>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AttestInvoiceView;
