import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  InfoWindow,
} from "@react-google-maps/api";
import CarMarkerIcon from "../../../assets/icons/marker-car-1.png";
import StartMarkerIcon from "../../../assets/icons/start-marker.png";
import EndMarkerIcon from "../../../assets/icons/end-marker.png";
import { GOOGLE_API_KEY } from "../../../config";
import { getDriversLiveLocation } from "../../../api/FleetActivityApi";

const FleetPreviewMap = ({
  currentDriver,
  previewInfo,
  selectedDate,
  allDrivers,
}) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [directions, setDirections] = useState({ routes: [] });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [liveMarkers, setLiveMarkers] = useState([]);

  // Map references
  const mapRef = useRef(null);
  const directionsRendererRef = useRef(null);

  // Drivers Live location
  const [data, setData] = useState(null);
  const [isActive, setIsActive] = useState(true);

  const [center, setCenter] = useState({
    lat: 40.758896,
    lng: -73.98513,
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  // useEffect(() => {
  //   if (mapRef.current && directionsRendererRef.current) {
  //     // Clear markers
  //     mapRef.current.setZoom(1); // Set a lower zoom level to clear markers
  //     mapRef.current.setZoom(15); // Set the desired zoom level

  //     // Clear directions
  //     directionsRendererRef.current.setDirections({ routes: [] });
  //   }
  // }, [markers, directions]);

  const onLoad = (map) => {
    mapRef.current = map;
  };

  const onDirectionsLoad = (directionsRenderer) => {
    directionsRendererRef.current = directionsRenderer;
  };

  const fetchLiveLocations = async (driver) => {
    try {
      const response = await getDriversLiveLocation({ date: selectedDate, driver: driver?.id });

      const markers = response.map((item) => {
        const driverInfo = allDrivers.find(
          (driver) => driver.id == item.driverid
        );

        return {
          id: item.id,
          position: { lat: Number(item.latitude), lng: Number(item.longitude) },
          title: driverInfo ? driverInfo.fullName : "Not found",
        };
      });

      setLiveMarkers(markers);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if(isActive && currentDriver != null && previewInfo.mainMarkers.length == 0) {
      fetchLiveLocations(currentDriver);
    }else if (isActive && currentDriver == null) {
      fetchLiveLocations(null);
    }

    const intervalId = setInterval(() => {
      if(isActive && currentDriver != null && previewInfo.mainMarkers.length == 0) {
        fetchLiveLocations(currentDriver);
      }else if (isActive && currentDriver == null) {
        fetchLiveLocations(null);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, currentDriver, allDrivers, selectedDate, previewInfo]);

  useEffect(() => {
    // Clearing data in the map
    setSelectedMarker(null);
    // setDirections({ routes: [] })
    if (mapRef.current && directionsRendererRef.current) {
      // Clear markers
      // mapRef.current.setZoom(1); // Set a lower zoom level to clear markers
      // mapRef.current.setZoom(15); // Set the desired zoom level
      // Clear directions
      // directionsRendererRef.current.setDirections({ routes: [] });
    }
  }, [currentDriver]);

  const waypoints =
    isMapLoaded &&
    previewInfo.markers?.map((marker) => ({
      location: new window.google.maps.LatLng(
        marker.position.lat,
        marker.position.lng
      ),
      stopover: true,
    }));

  const handleLoad = () => {
    setIsMapLoaded(true);
  };

  const directionsCallback = (response) => {
    if (directions.routes.length == 0) {
      if (previewInfo.mainMarkers.length > 0) {
        const delay = 1000; // Adjust the delay as needed
        const timeoutId = setTimeout(() => {
          setDirections(response);
        }, delay);
        return () => clearTimeout(timeoutId);
      } else {
        const timeoutId = setTimeout(() => {
          setDirections({ routes: [] });
        }, 500);
        return () => clearTimeout(timeoutId);
      }
    }
  };

  const handleActiveMarker = (marker) => {
    setSelectedMarker(marker);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  const handleMarkerMouseOut = () => {
    setSelectedMarker(null);
  };

  useEffect(() => {
    if (previewInfo.mainMarkers && previewInfo.mainMarkers.length > 0) {
      setCenter({
        lat: previewInfo.mainMarkers[0].position.lat,
        lng: previewInfo.mainMarkers[0].position.lng,
      });
    } else {
      const timeoutId = setTimeout(() => {
        setDirections({ routes: [] });
      }, 500);

      // Clean up the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }

    setDirections({ routes: [] });
  }, [previewInfo]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY} onLoad={handleLoad}>
      {isMapLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={6}
          onLoad={onLoad}
        >
          {/* Markers for Live Locations */}
          {(currentDriver == null || previewInfo.mainMarkers?.length == 0 )&&
            liveMarkers.map((marker) => (
              <Marker
                key={marker.id}
                position={marker.position}
                title={marker.title}
                icon={{
                  url: CarMarkerIcon,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                // onClick={() => handleActiveMarker(marker)}
                onMouseOver={() => handleActiveMarker(marker)}
              />
            ))}

          {currentDriver != null && previewInfo.mainMarkers?.length > 0 && (
            <>
              <Marker
                key={previewInfo.mainMarkers[0].id}
                position={previewInfo.mainMarkers[0].position}
                title={previewInfo.mainMarkers[0].title}
                icon={{
                  url: StartMarkerIcon,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                // onClick={() => handleActiveMarker(marker)}
                onMouseOver={() =>
                  handleActiveMarker(previewInfo.mainMarkers[0])
                }
              />

              <Marker
                key={previewInfo.mainMarkers[1].id}
                position={previewInfo.mainMarkers[1].position}
                title={previewInfo.mainMarkers[1].title}
                icon={{
                  url: ['Travelling With Enrolee', 'Travelling To Pickup'].includes(previewInfo.mainMarkers[1].status) ? CarMarkerIcon: EndMarkerIcon,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                // onClick={() => handleActiveMarker(marker)}
                onMouseOver={() =>
                  handleActiveMarker(previewInfo.mainMarkers[1])
                }
              />
            </>
          )}
          {/* Draw route using DirectionsService */}
          {currentDriver != null && previewInfo.markers?.length > 0 && (
            <DirectionsService
              options={{
                origin: previewInfo.markers[0].position,
                destination:
                  previewInfo.markers[previewInfo.markers.length - 1].position,
                waypoints: waypoints.slice(1, -1), // Exclude the origin and destination as waypoints
                travelMode: "DRIVING",
              }}
              callback={directionsCallback}
            />
          )}
          {/* Render the directions on the map */}
          {currentDriver != null && previewInfo.tripStatus == "ENDED" && (
            <DirectionsRenderer
              directions={directions}
              onLoad={onDirectionsLoad}
              options={{
                polylineOptions: {
                  strokeColor: "#3498db", // Blue
                  strokeOpacity: 1,
                  strokeWeight: 4,
                },
                suppressMarkers: true,
              }}
            />
          )}
          {currentDriver != null && previewInfo.tripStatus == "STARTED" && (
            <DirectionsRenderer
              directions={directions}
              onLoad={onDirectionsLoad}
              options={{
                polylineOptions: {
                  strokeColor: "#07bc0c", // Green
                  strokeOpacity: 1,
                  strokeWeight: 4,
                },
                suppressMarkers: true,
              }}
            />
          )}
          {currentDriver != null && previewInfo.tripStatus == "ASSIGNED" && (
            <DirectionsRenderer
              directions={directions}
              onLoad={onDirectionsLoad}
              options={{
                polylineOptions: {
                  strokeColor: "#e98011", // Orange
                  strokeOpacity: 1,
                  strokeWeight: 4,
                },
                suppressMarkers: true,
              }}
            />
          )}
          {currentDriver != null && previewInfo.tripStatus == "REJECTED" && (
            <DirectionsRenderer
              directions={directions}
              onLoad={onDirectionsLoad}
              options={{
                polylineOptions: {
                  strokeColor: "#e74c3c", // Red
                  strokeOpacity: 1,
                  strokeWeight: 4,
                },
                suppressMarkers: true,
              }}
            />
          )}

          {currentDriver != null && selectedMarker && (
            <InfoWindow
              position={selectedMarker.position}
              // onCloseClick={handleMarkerMouseOut}
              onCloseClick={handleInfoWindowClose}
            >
              <div>
                <h3>{selectedMarker.title}</h3>
                <p>{selectedMarker.address}</p>
                <p>
                  <strong>Pickup/Drop off:</strong> {selectedMarker.time}
                </p>
                <p>
                  <strong>Status:</strong> {selectedMarker.status}
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default FleetPreviewMap;
