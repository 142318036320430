import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import EditRounded from "@mui/icons-material/EditRounded"
import DeleteRounded from "@mui/icons-material/DeleteRounded"
import { StyledIconWrapper } from "../../../styled";

const columns = [
  { id: "firstName", label: "First name", minWidth: 120 },
  { id: "lastName", label: "Last name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 150 },
  { id: "mobilePhone", label: "Mobile Phone", minWidth: 100 },
  { id: "userName", label: "User name", minWidth: 100 },
  { id: "userleveldisplay", label: "User Level", minWidth: 100 },
  { id: "active", label: "Active", minWidth: 100 }
];

function UserTable({ users , setAction, reloadData, totalPages, filter}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    reloadData(page, rowsPerPage, filter);
  }, [page, rowsPerPage, filter])

  return (
    <>
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:"red"}}>
            <TableRow style={{fontWeight:"bold", }}>
            <TableCell align="center" style={{fontWeight:"bold" ,fontSize:"14px", color:"#033d5f", backgroundColor:"#f5fafe" }}>
                Action
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth,fontWeight:"bold",fontSize:"14px",color:"#033d5f", backgroundColor:"#f5fafe" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
                <TableCell key={row.id} align="center">
                  <div style={{display:"flex"}}>
                    
                    <IconButton 
                      size="small"
                      onClick={(e)=>setAction({'action':'edit','user':row})}>
                        <StyledIconWrapper style={{padding:0}}><EditRounded /> </StyledIconWrapper>
                    </IconButton>
                    
                    <IconButton  size="small"
                      onClick={(e)=>setAction({'action':'delete','user':row})}>
                        <StyledIconWrapper style={{padding:0}}><DeleteRounded  /></StyledIconWrapper> 
                    </IconButton>
                  </div>
                </TableCell>

                {columns.map((column) => {
                  const value = row[column.id];
                  const label = column.id;
                  return (
                    <TableCell key={column.id} align="left">
                      {label == "active" ? ( value == true ? "Yes" : "No" ) : value }
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalPages}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </>
  );
}

export default UserTable;
