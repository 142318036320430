import { React, useState, useEffect, useCallback, useContext } from "react";
import {
  Box,
  Grid,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {DispatchGrid, SearchBy, FilterBy} from "./components";
import { StyledFrame, StyledCircularProgress, StyledWrapper, StyledHeader } from "../../styled";

import RefdataContext from "../../context/RefdataContext";

import {getTripSchedules, createTripSchedules} from '../../api/DispatchApi'
import moment from "moment";

function Dispatch () {


    const {driverList} = useContext(RefdataContext)

    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    
    const [selectedIds, setSelectedIds] = useState({});
    const [search, setSearch] = useState(false);
    const [searchParams, setSearchParams] = useState([])
    const [filters, setFilters] = useState([]);
    const [fetching, setFetching] = useState([]);
    // const [loadingMessage, setLoadingMessage] = useState("Loading !!!")
    

    useEffect(()=>{
      setFilteredRows(applyFilters(rows.filter((r)=>{
        return(r.status === "Eligible")
      })));
    },[rows])


    useEffect(()=>{
      setFilteredRows(applyFilters(rows));
    },[filters])

    useEffect(()=>{

      async function getRows() {
          if(!search) {setFetching(false); return};
          let results = await getResults();
          if(results){
            let schedules = results.map((r)=>{
              return (
                {
                  ...r,
                  pickupDate:moment(r.pickupDate).format("YYYY-MM-DD"),
                  actPickUp:r.actPickUp,
                }
              )
            })
            schedules.sort((a,b) => a.groupKeyMASPickupTime.localeCompare(b.groupKeyMASPickupTime));
            setRows(schedules)
            setSearch(false)
        }
      }
      setFetching(true);
      getRows();
  },[search])

  async function saveRow(row){
    let driver = driverList.find((dr) => dr.firstName?.toLowerCase().replaceAll(" ","") == row.driverName?.toLowerCase().replaceAll(" ",""))
    let data  ={
      "id": row.id,
      "invoiceNumber": row.invoiceNumber,
      "tripLegSequence": row.tripLegSequence,
      "medicaidNumber": row.medicaidNumber,
      "driverName": row.self || !driver? '' : driver.firstName.replaceAll(" ",""),
      "driverId": row.self || !driver? 0 : driver.id,
      "vehicle": row.self || !driver? '' : driver.license,
      "pickupDate": row.pickupDate,
      "tripLegId": row.tripLegId,
      "pickaddress": row.pickaddress,
      "masPickup": row.masPickup,
      "scheduleorder": row.scheduleorder,
      "actPickUp": row.actPickUp,
      "dropoffAddress": row.dropoffAddress,
      "duration": row.duration,
      "self":row.self,
      "locked":row.locked,
    };
    // console.log("data ->", data);
    try{
      // setLoadingMessage("Saving...");
      setFetching(true);
      let response = await createTripSchedules(data);
      toast.success(`Saved successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      row.scheduled = true;
    } catch (error) {
      // console.log(error)
      toast.error(`[Error] ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setFetching(false);
  }

  function applyFilters(toFilter){
    if(filters.length == 0 ) return toFilter;
    let filtered = []

    Object.entries(filters).map(([key, val])=>{
      if(key.trim().replaceAll(" ","") == "county"){
        filtered = toFilter.filter((fr) =>{
          return(val == '' || val == 'All' || fr.county == val);
        })
      }
      if (key.trim().replaceAll(" ","") == "tripType") {
        filtered = filtered.filter((fr) =>{
          return(val == '' || val == 'All' || fr.status == val);
        })
      }
      if (key.trim().replaceAll(" ","") == "standingOrder") {
        filtered = filtered.filter((fr) =>{
          return(val == '' || val == 'Any' || fr.isStandingOrderTrip == (val=='Yes'?true:false));
        })
      }
    })
    
    return filtered
  }

  async function getResults (){
      // console.log("search - >", search)
      let response = null;
    try{
      if(searchParams.length != 0){
          response = await getTripSchedules(searchParams);
      }
      setFetching(false);
      return response;;
    } catch(err){
      console.log(err);
    }
  }
    return(
      <StyledWrapper>
        <Grid container>
          <ToastContainer />
          <Grid item md={12} xs={12} lg={12} p={0}>
            <StyledHeader>
              Trip Schedule
            </StyledHeader>
          </Grid>
          <Grid item md={12} xs={12} lg={12} p={0}>
            <br></br>
          </Grid> 
          <Grid item md={12} xs={12} lg={12} p={0}>
              <StyledFrame >
                <SearchBy 
                setSearch={setSearch} 
                setSearchParams={setSearchParams}/>
              </StyledFrame>
          </Grid>
          <Grid item md={12} xs={12} lg={12} p={0}>
            <br></br>
          </Grid> 
          <Grid item md={12} xs={12} lg={12}>
              <StyledFrame >
                <FilterBy 
                filteredRows={filteredRows}
                setFilters={setFilters}/>
              </StyledFrame>
          </Grid>
          <Grid item md={12} xs={12} lg={12} p={0}>
            <br></br>
          </Grid> 
          <Grid item md={12} xs={12} lg={12}>
              <div style={{ width: "100%", height: "80vh" }}>
                  <DispatchGrid
                      rows={filteredRows}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      setRows={setRows}
                      saveRow={saveRow}
                      loading={fetching}
                  />
              </div>
          </Grid>  
          </Grid>  
      </StyledWrapper>
    )
}

export default Dispatch;