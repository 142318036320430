import axios from 'axios';

import { BASE_URL } from "../config";

async function loadRefData(user) {
    return new Promise(async (resolve, reject) =>{
        let refData = null;
        try {
            const metaResp = await axios.get(BASE_URL + "/refdata/all", {
              headers: {
                "x-auth-key": user.key,
              },
            });
            refData = await metaResp.data;
            console.log(refData);
            if (refData)
                resolve(refData)
            else  resolve({status:false})   
        } catch(error) {
            console.error(error)
            reject(error);
        }
    })
  }

  export {loadRefData}