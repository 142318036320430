import moment from "moment";
import axios from "axios";

import { BASE_URL } from "../../config";

const FETCH_ATTESTATIONS = "/attestations/all";
const UPDATE_ATTESTATION = "/attestations/";
const ATTESTATION = "/attestations/";

class Attestations {
  constructor() {
    this.invoiceNo = null;
    this.PANumber = null;
    this.isSo = null;
    this.tripDate = null;
    this.patientName = null;
    this.CINNumber = null;
    this.patientContactNo = null;
    this.status = null;
    this.county = null;
    this.legs = [];
  }
}

class Legs {
  constructor() {
    this.MASDriver = null;
    this.actualDriver = null;
    this.vehicle = null;
    this.isSelf = false;

    this.MASpickUp = null;

    this.MASDispatchPickupTime = null;
    this.MASDispatchPickupGPS = [];

    this.MASStartPickUpTime = null;
    this.MASStartPickUpGPS = [];

    this.MASArrivePickupTime = null;
    this.MASArrivePickupGPS = [];

    this.MASArriveDropoffTime = null;
    this.MASArriveDropoffGPS = [];

    this.MASDropoff = null;
    this.MASDropoffTime = null;
    this.MASDropoffGPS = [];

    this.actualPickUp = null;
    this.actualDropoff = null;
    this.actualPickupTime = null;
    this.actualDropoffTime = null;
    this.actualGPS = [];
    this.id = null;
  }
}

class AttestationList {
  constructor() {
    this.attestations = [];
    this.filteredAttestations = [];
    this.notifyAll = null;
  }

  async loadAttestations(invoice, startDate, endDate) {
    console.log(startDate, endDate);

    if (startDate === undefined || endDate == undefined) {
      return [];
    }

    try {
      this.data = await this._fetchAttestations(
        `invoice=${invoice}&startDate=${startDate}&endDate=${endDate}`
      );
    } catch (err) {
      console.log("ERROR" - err);
    }

    this.attestations = this.data.map((data) => {
      let attestation = new Attestations();
      attestation.invoiceNo = data.invoice;
      attestation.isSo = data.so;
      attestation.CINNumber = data.patientCINNo;
      attestation.PANumber = data.paNumber;
      attestation.patientName = data.patientFirstName.concat(
        " ",
        data.patientLastName
      );
      attestation.patientContactNo = data.patientContactNo;
      attestation.tripDate = moment(data.tripDate).format("MM-DD-yyyy");
      attestation.status = data.status == "Eligible" ? "pending" : data.status;
      attestation.county = data.patientCounty;
      let legs = data.attestationlegs.map((leg) => {
        let _leg = new Legs();
        _leg.id = leg.id;
        _leg.actualDriver = leg.system_driver;
        _leg.actualPickUp = leg.system_pickup;
        _leg.actualPickUpTime = leg.system_pick_uptime;
        _leg.actualDropoff = leg.system_drop_off;
        _leg.actualDropoffTime = leg.system_drop_off_time;
        _leg.actualGPS = leg.system_pick_up_gps;

        _leg.MASDriver = leg.mas_driver;
        _leg.MASpickUp = leg.mas_pickup;

        _leg.MASDispatchPickupTime = leg.mas_pick_up_dispatch_time;
        _leg.MASDispatchPickupGPS = leg.mas_pick_up_dispatch_gps;

        _leg.MASStartPickUpTime = leg.mas_pick_uptime;
        _leg.MASStartPickUpGPS = leg.mas_pick_up_gps;

        _leg.MASArrivePickupTime = leg.mas_pick_up_arrive_time;
        _leg.MASArrivePickupGPS = leg.mas_pick_up_arrive_gps;

        _leg.MASArriveDropoffTime = leg.mas_drop_off_arrive_time;
        _leg.MASArriveDropoffGPS = leg.mas_drop_off_arrive_gps;

        _leg.MASDropoffTime = leg.mas_drop_off_time;
        _leg.MASDropoffGPS = leg.mas_drop_off_gps;

        _leg.MASpickUpTime = leg.mas_pick_uptime;

        _leg.MASDropoff = leg.mas_drop_off;
        _leg.MASDropoffTime = leg.mas_drop_off_time;

        _leg.isSelf = leg.system_driver == null ? true : false;
        _leg.vehicle = leg.system_vehicle;
        return _leg;
      });
      attestation.legs = legs;
      return attestation;
    });
    this.filteredAttestations = this.attestations;
    this.notifyAll({ filteredAttestations: this.filteredAttestations });
  }

  async loadRefData(user) {
    try {
      let _user = user;
      if (!user) {
        _user = { active: false, name: "" };
        if (localStorage.getItem("session")) {
          _user = JSON.parse(localStorage.getItem("session"));
        }
      }

      const metaResp = await axios.get(BASE_URL + "/refdata/all", {
        headers: {
          "x-auth-key": _user.key,
        },
      });
      const countryList = await metaResp.data;
      this.countyList = await countryList.countyList.rows;
    } catch (err) {
      console.log("ERROR ---- >", err);
    }
  }

  async update(invoiceNo, legId, data) {
    // Get the leg with invoice id and key
    // update the new values
    // Send to server
    // Update data list and filterlist
    this.attestations = this.attestations.map((attest) => {
      if (attest.invoiceNo == invoiceNo) {
        for (const leg of attest.legs) {
          if (leg.id == legId) {
            for (const key in leg) {
              if (Object.hasOwnProperty.call(leg, key)) {
                leg[key] = data[key] ? data[key] : leg[key];
              }
            }
            this._updateAttatation(invoiceNo, leg)
              .then((resp) => {
                console.log(resp);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
      return attest;
    });
  }

  async getAttestations() {
    return this.filteredAttestations;
  }

  registerNotifyAll = (notifyAll) => {
    this.notifyAll = notifyAll;
  };

  async _fetchAttestations(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${BASE_URL}${FETCH_ATTESTATIONS}?${params}`
        );
        if (response.status == 200) {
          resolve(response.data);
        } else {
          resolve({ message: "No data found !", status: response.status });
        }
      } catch (err) {
        console.log(
          "Error connecting to ",
          `${BASE_URL}${FETCH_ATTESTATIONS}?${params}`
        );
        console.log(err.message);
        reject(err);
      }
    });
  }

  async _updateAttatation(id, leg) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.put(
          `${BASE_URL}${UPDATE_ATTESTATION}${id}`,
          JSON.stringify(leg),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status == 200) {
          resolve(response.data);
        } else {
          resolve({
            message: "No attetstation with ID found !",
            status: response.status,
          });
        }
      } catch (err) {
        console.log("Error connecting to ", `${BASE_URL}${UPDATE_ATTESTATION}`);
        console.log(err.message);
        reject(err);
      }
    });
  }

  async fetchMasDrivers() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/driveruser/masdrivers/info`
        );
        if (response.status == 200) {
          resolve(response.data);
        } else {
          resolve({
            message: "Loading Mas Drivers failed !",
            status: response.status,
          });
        }
      } catch (err) {
        console.log(
          "Error connecting to ",
          `${BASE_URL}/driveruser/masdrivers/info`
        );
        console.log(err.message);
        reject(err);
      }
    });
  }

  async getAttestInvoiceData(params) {
    return new Promise(async (resolve, reject) => {
      try {
        let _user = { active: false, name: "" };
        if (localStorage.getItem("session")) {
          _user = JSON.parse(localStorage.getItem("session"));
        }

        const response = await axios.get(
          `${BASE_URL}${ATTESTATION}attestation-invoice`,
          {
            params: params,
            headers: {
              "x-auth-key": _user.key,
            },
          }
        );
        if (response.status == 200) {
          resolve(response.data);
        } else {
          resolve({
            message: "Loading Attetation Invoice Data failed !",
            status: response.status,
          });
        }
      } catch (err) {
        console.log(
          "Error connecting to ",
          `${BASE_URL}${ATTESTATION}attestation-invoice`
        );
        console.log(err.message);
        reject(err);
      }
    });
  }




  async attestInvoice(env, type, invoices, date, drivers = null) {
    return new Promise(async (resolve, reject) => {
      try {
        // const formData = new FormData();
        // formData.append("fileupload", file);

        let _user = { active: false, name: "" };
        if (localStorage.getItem("session")) {
          _user = JSON.parse(localStorage.getItem("session"));
        }
        const response = await axios.post(
          `${BASE_URL}${ATTESTATION}attestation-invoice`,
          {
            env: env,
            type: type,
            invoices: invoices,
            drivers: drivers,
            date: date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-key": _user.key,
            },
          }
        );
        if (response.status == 200) {
          resolve(response);
        } else {
          resolve({
            message: "Attetstation upload failed",
            status: response,
          });
        }
      } catch (err) {
        console.log("Error connecting to ", `${BASE_URL}${UPDATE_ATTESTATION}`);
        console.log(err.message);
        reject(err);
      }
    });
  }

  async sendBreadcrumbs(env, type, invoices, date, drivers = null) {
    return new Promise(async (resolve, reject) => {
      try {
        let _user = { active: false, name: "" };
        if (localStorage.getItem("session")) {
          _user = JSON.parse(localStorage.getItem("session"));
        }
        const response = await axios.post(
          `${BASE_URL}${ATTESTATION}attestation-breadcrumbs`,
          {
            env: env,
            type: "GPS",
            invoices: invoices,
            drivers: drivers,
            date: date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-key": _user.key,
            },
          }
        );
        if (response.status == 200) {
          resolve(response);
        } else {
          resolve({
            message: "Sending Breadcrumbs failed",
            status: response,
          });
        }

        resolve({
          message: "Sending Breadcrumbs failed",
          status: response,
        });
      } catch (err) {
        console.log("Error connecting to ", `${BASE_URL}${UPDATE_ATTESTATION}`);
        console.log(err.message);
        reject(err);
      }
    });
  }

  async loadBreadcrumbs(invoiceNumber,legId) {
    return new Promise(async (resolve, reject) => {

      try {
        let _user = { active: false, name: "" };
        if (localStorage.getItem("session")) {
          _user = JSON.parse(localStorage.getItem("session"));
        }

        const response = await axios.get(
          `${BASE_URL}${ATTESTATION}load-breadcrumbs?invoiceNumber=${invoiceNumber}&legId=${legId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-key": _user.key,
            },
          }
        );
        if (response.status == 200) {
          resolve(response.data);
        } else {
          resolve({ message: "No data found !", status: response.status });
        }
      } catch (err) {
        console.log(
          "Error connecting to ",
          `${BASE_URL}${ATTESTATION}load-breadcrumbs?invoiceNumber=${invoiceNumber}&legId=${legId}`,
        );
        console.log(err.message);
        reject(err);
      }
    });
  }
}
export const attestationList = new AttestationList();
