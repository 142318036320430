
const axios  = require('axios');
const config = require("../config")

const BASE_URL = `${config.BASE_URL}/clientcustomaddresses`;

async function getCustomerList(user, page, rowsPerPage, filter){
    return new Promise(async (resolve, reject) =>{
        try{
        let response = await axios.get(`${BASE_URL}/all`,
           {headers: {
            "x-auth-key": user.key,
          },
          params: {page: page, size: rowsPerPage, title: filter}},)
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

async function createClientCustomer(customerDetail){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.post(`${BASE_URL}`, customerDetail);
            if (response.status === 201 || response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

async function updateClientCustomer(customer){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.put(`${BASE_URL}/${customer.id}`, customer);
            if (response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

async function getMasAddressInfo(medicaidId){
    return new Promise(async (resolve, reject) =>{
        try{
        let response = await axios.get(`${BASE_URL}/masdetails`,
           {params: {medicaidId: medicaidId}})
        if (response.status === 200)
            resolve(await response.data);
        else
            reject(response.status)    
       } catch (err) {
        reject(err.message)    
       }
    })
}

async function deleteCustomer(customer){
    return new Promise(async (resolve, reject) =>{
        try{
            let response = await axios.delete(`${BASE_URL}/${customer.id}`);
            if (response.status === 200)
                resolve(await response.data);
            else
                reject(response.status)
        }catch (err){
            reject(err.message);
        }
    })
}

export {getCustomerList, createClientCustomer, updateClientCustomer, getMasAddressInfo, deleteCustomer}