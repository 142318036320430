import {Checkbox, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { 
    StyledDatePicker, 
    StyledFormControl, 
    StyledFrame, 
    StyledInputText,
    StyledMenuItem,
    StyledSelect, 

} from "../../../styled";
import moment from "moment";

import {formatPhoneNumber} from "../../../utils/formaters";
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";
import { makeStyles } from "@mui/styles";

const config = require("../../../config")
const staticURL = config.BASE_URL;

const selfDrive = [
    {"id":1, "name":"Yes"},
    {"id":0, "name":"No"},
]

const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        borderRadius: '8px',
        padding: theme.spacing(2),
        marginTop: '40px',
        marginLeft: '0px',
        color: '#033d5f',
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    itemHeader: {
        textAlign: 'center', 
        background: '#d1d0d0'
    },
    item: {
      // Ensure equal width for all items in the grid
      width: `${100 / 7}%`,
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  }));

function MedicaidSpecialTiming({values, setValues, action}){
    const classes = useStyles();

    function handleChange(e) {
        let name = e.target.name;
        let value = formatNumber(e.target.value);
        setValues({
            ...values,
            [name]:value
        })
    }

    function handleChangeDropDown(e) {
        let name = e.target.name;
        let value = e.target.value;
        setValues({
            ...values,
            [name]:value
        })
    }

    const formatNumber = useCallback((value) => {
        if(typeof value === 'number' && !isNaN(value)) {
            return value;
        } else if(value) {
            const onlyNumbers = value.replace(/^0+|[^0-9]/g, '');
            return parseInt(onlyNumbers, 10);
        }

        return null;
        
      }, [values]); 

    return(
        <StyledFrame>
            <Grid container>
                <Grid item md={6} style={{marginTop: '22px', marginBottom: '10px', fontWeight: '600'}}>
                    <span style={{color: '#033d5f'}}>Name :-</span> <span style={{color: 'black'}}>{values.fullName}</span></Grid>
                <Grid item md={12} style={{marginTop: '22px', marginBottom: '10px', textAlign: 'center'}}>GROUP SESSIONS</Grid>

                <Grid container spacing={2} columns={14} className={classes.container}>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Sunday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Monday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Tuesday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}> 
                        Wednesday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Thursday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Friday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Saturday
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledInputText 
                                name="SUN_WAIT"
                                value={formatNumber(values.SUN_WAIT)} 
                                onChange={e=>handleChange(e)} disabled={action === 'read-only' ? true : false} />
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledInputText 
                                name="MON_WAIT"
                                value={values.MON_WAIT} 
                                onChange={e=>handleChange(e)} disabled={action === 'read-only' ? true : false}/>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledInputText 
                                name="TUE_WAIT"
                                value={values.TUE_WAIT} 
                                onChange={e=>handleChange(e)} disabled={action === 'read-only' ? true : false}/>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}> 
                        <StyledFormControl>
                            <StyledInputText 
                                name="WED_WAIT"
                                value={values.WED_WAIT} 
                                onChange={e=>handleChange(e)} disabled={action === 'read-only' ? true : false}/>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledInputText 
                                name="THU_WAIT"
                                value={values.THU_WAIT} 
                                onChange={e=>handleChange(e)} disabled={action === 'read-only' ? true : false}/>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledInputText 
                                name="FRI_WAIT"
                                value={values.FRI_WAIT} 
                                onChange={e=>handleChange(e)} disabled={action === 'read-only' ? true : false}/>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledInputText 
                                name="SAT_WAIT"
                                value={values.SAT_WAIT} 
                                onChange={e=>handleChange(e)} disabled={action === 'read-only' ? true : false}/>
                        </StyledFormControl> 
                    </Grid>
                </Grid>

                <Grid item md={12} style={{marginTop: '50px', marginBottom: '10px', textAlign: 'center'}}>SELF DRIVERS</Grid>

                <Grid container spacing={2} columns={14} className={classes.container}>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Sunday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Monday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Tuesday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}> 
                        Wednesday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Thursday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Friday
                    </Grid>
                    <Grid item xs={2} className={classes.itemHeader}>
                        Saturday
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledSelect 
                                name="SUN_SELF"
                                value={values.SUN_SELF} 
                                defaultValue={values.SUN_SELF}
                                onChange={e=>handleChangeDropDown(e)} disabled={action === 'read-only' ? true : false}>
                                { 
                                    selfDrive.map((c, idx) => {
                                        return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                    })
                                }    
                            </StyledSelect>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledSelect 
                                name="MON_SELF"
                                value={values.MON_SELF} 
                                defaultValue={values.MON_SELF}
                                onChange={e=>handleChangeDropDown(e)} disabled={action === 'read-only' ? true : false}>
                                { 
                                    selfDrive.map((c, idx) => {
                                        return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                    })
                                }    
                            </StyledSelect>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledSelect 
                                name="TUE_SELF"
                                value={values.TUE_SELF} 
                                defaultValue={values.TUE_SELF}
                                onChange={e=>handleChangeDropDown(e)} disabled={action === 'read-only' ? true : false}>
                                { 
                                    selfDrive.map((c, idx) => {
                                        return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                    })
                                }    
                            </StyledSelect>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}> 
                        <StyledFormControl>
                            <StyledSelect 
                                name="WED_SELF"
                                value={values.WED_SELF} 
                                defaultValue={values.WED_SELF}
                                onChange={e=>handleChangeDropDown(e)} disabled={action === 'read-only' ? true : false}>
                                { 
                                    selfDrive.map((c, idx) => {
                                        return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                    })
                                }    
                            </StyledSelect>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledSelect 
                                name="THU_SELF"
                                value={values.THU_SELF} 
                                defaultValue={values.THU_SELF}
                                onChange={e=>handleChangeDropDown(e)} disabled={action === 'read-only' ? true : false} >
                                { 
                                    selfDrive.map((c, idx) => {
                                        return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                    })
                                }    
                            </StyledSelect>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledSelect 
                                name="FRI_SELF"
                                value={values.FRI_SELF} 
                                defaultValue={values.FRI_SELF}
                                onChange={e=>handleChangeDropDown(e)} disabled={action === 'read-only' ? true : false}>
                                { 
                                    selfDrive.map((c, idx) => {
                                        return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                    })
                                }    
                            </StyledSelect>
                        </StyledFormControl> 
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <StyledFormControl>
                            <StyledSelect 
                                name="SAT_SELF"
                                value={values.SAT_SELF} 
                                defaultValue={values.SAT_SELF}
                                onChange={e=>handleChangeDropDown(e)} disabled={action === 'read-only' ? true : false}>
                                { 
                                    selfDrive.map((c, idx) => {
                                        return (<StyledMenuItem key={idx} value={c.id}>{c.name}</StyledMenuItem>)
                                    })
                                }    
                            </StyledSelect>
                        </StyledFormControl> 
                    </Grid>
                </Grid>
            </Grid>
        </StyledFrame>
    )
}

export default MedicaidSpecialTiming;