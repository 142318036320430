import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { StyledButton, StyledDatePicker, StyledFormControl, StyledFrame, StyledHeader, StyledInputText } from "../../styled";





const SearchRoasterPlan = ({ handleOnSearch, uploadView }) => {
  const [source, setSource] = useState("mas");
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [tripDate, setTripDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const handleSearch = useCallback(() => {
    handleOnSearch({
      invoiceNo: invoiceNo,
      tripDate: tripDate,
      source: source,
      reload:true,
    });
  }, [invoiceNo, tripDate, source]);

  const handleInvoiceNoChange = useCallback((e) => {
    setInvoiceNo(e.target.value);
  });

  const handleSetTripDate = useCallback((e) => {
    setTripDate(e.target.value);
  });

  const handleOnSourceChange = (event) => {
    setSource(event.target.value);
  };

  return (
    <Grid container p={0}>
      <Grid item md={12} sm={12} p={0}>
        <StyledHeader>
          Schedule Roster
        </StyledHeader>
      </Grid>
      <Grid item md={12} xs={12} lg={12} p={0}>
        <br></br>
      </Grid>
      <StyledFrame>
      <Grid container>
        <Grid container md={12} xs={12} sm={12}  lg={7}>
          <Grid item md={7} xs={12} sm={12}  lg={6}>
            {/*  */}
            <Grid item xs={12} sm={12} sx={{ marginLeft: 1 , paddingBottom: 0.5}}>
              <StyledFormControl>
                <Typography  sx={{
                    fontWeight:800
                  }}>Invoice Number</Typography>
              </StyledFormControl>
            </Grid>
            <Grid item  xs={12} sm={12} sx={{ paddingTop: 1 }}>
              <StyledFormControl>
                  <StyledInputText 
                    id="invoiceNumber"
                    name="invoiceNumber"
                    value={invoiceNo ?? ""}
                    onChange={(e)=>{handleInvoiceNoChange(e)}}>
                  </StyledInputText>
              </StyledFormControl>
            </Grid>
            {/*  */}
          </Grid>
          <Grid item md={5} xs={12} sm={12}  lg={6}>
            <Grid item md={12} xs={12} sm={12}  lg={12}>
              {uploadView ? (
                  <Grid item sx={{ marginLeft: 1 , paddingBottom: 0.5}} >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={source}
                      onChange={handleOnSourceChange}
                    >
                      <FormControlLabel
                        value="mas"
                        control={<Radio />}
                        label="Trip Date"
                      />
                      <FormControlLabel
                        value="planned"
                        control={<Radio />}
                        label="Planned Date"
                      />
                    </RadioGroup>
                  </Grid>
                )
                :
                (<Grid item sx={{ marginLeft: 1 , paddingBottom: 0.5}} >
                    <StyledFormControl>
                      <Typography  sx={{
                        fontWeight:800
                      }}>
                        Planned Date
                      </Typography>
                  </StyledFormControl>
                </Grid>
                )}
            </Grid>
            <Grid container md={12} xs={12} sm={12}  lg={12}>
              <Grid item md={12} xs={12} sm={12}  lg={8}>
                <StyledFormControl>
                  <StyledDatePicker
                        type="date"
                        id="dateFrom"
                        name="dateFrom"
                        value={tripDate}
                        onChange={(e)=>{handleSetTripDate(e)}}
                      />
                </StyledFormControl>
              </Grid>
              <Grid
                item
                sm={3}
                xs={12}
                lg={4}
                sx={{ paddingBottom: 1 }}
              >
                <StyledButton
                  variant="contained"
                  onClick={(e) => {
                    handleSearch();
                  }}
                >
                  Search
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </StyledFrame>
    </Grid>
  );
};

export default SearchRoasterPlan;
