import axios from "axios";
import moment from "moment";
const config = require("../config");

const BASE_URL = `${config.BASE_URL}`;

const getHeaders = () => {
    let _user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      _user = JSON.parse(localStorage.getItem("session"));
    }
    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": _user.key,
    };
  
    return headers;
  };

const handleErrors = (response) => {
    if (response.status < 200 && response.status > 299) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.data;
};

const getNotificationData = async ({receiver: driverId, sentOn: sentOn  = moment(new Date()).format("YYYY-MM-DD")}) => {
    try {
      const response = await axios.get(
        BASE_URL + `/reports/notifications/all`,
        {
          params: {driverId: driverId, sentOn: sentOn},
          headers: getHeaders(),
        }
      );
      return handleErrors(response);
    } catch (error) {
      throw new Error(`Error fetching data: ${error.message}`);
    }
  };

export { getNotificationData };