import React, { useState, useEffect, useCallback } from "react";
import {
  Popover,
  Typography,
  Checkbox,
  Divider,
  Button,
  Box,
  IconButton,
  TextField,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FilterPopover = ({
  open,
  anchorEl,
  options,
  filterParam,
  handleClose,
  onSelectedValuesChange,
}) => {
  
  
  const [checkedValues, setCheckedValues] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredList] = useState(filterParam ? filterParam : options);

  useEffect(() => {
    if(!filterParam || !Array.isArray(filterParam)) {
      setCheckedValues([]);
    }else {
      setCheckedValues([...filterParam]);
    }   
    
    if(filterParam?.length == options?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [filterParam, options])

  /*
  useEffect(() => {
    if (!open) {
      onSelectedValuesChange(checkedValues); 
    }
  }, [open, checkedValues, onSelectedValuesChange]);
  */

  useEffect(() => {
    const filteredList = options ? options.filter((option) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];
    setFilteredList(filteredList);
  }, [searchTerm, options]);

  useEffect(() => {
    if (selectAll) {
      setCheckedValues([...filteredList]);
    } else {
      setCheckedValues([]);
    }
  }, [selectAll, filteredList]);

  const handleCheckboxChange = (value) => {
    const currentIndex = checkedValues.indexOf(value);
    const newChecked = [...checkedValues];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedValues(newChecked);
    setSelectAll(false);
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
  };

  const handleOkClick = useCallback(() => {
    handleClose(checkedValues);
  }, [checkedValues]);

  const handleCancelClick = () => {
    handleClose([]);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        <Typography variant="h6">Filters</Typography>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: "0px 20px 20px 20px" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: '10px' }}>
          <div>
            <TextField
              variant="outlined"
              placeholder="Search..."
              InputProps={
                {
                  style: { border: '1px solid #ccc !important' }
                  // endAdornment: (
                  //   <IconButton type="submit" aria-label="search">
                  //     <SearchIcon />
                  //   </IconButton>
                  // ),
                }
              }
              InputLabelProps={{ shrink: false }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </Box>
          <Box sx={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', marginLeft: '5px' }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} />} label="(Select All)" />
          <Divider />
          {filteredList.map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={checkedValues.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
        </Box>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={handleOkClick}
            variant="contained"
            color="primary"
            size="small"
          >
            OK
          </Button>
          <Box ml={1}>
            <Button onClick={handleCancelClick} variant="outlined" size="small">
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export default FilterPopover;
