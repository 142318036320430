import {Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { 
    StyledDatePicker, 
    StyledFormControl, 
    StyledFrame, 
    StyledInputText, 

} from "../../../styled";
import moment from "moment";

import {formatPhoneNumber} from "../../../utils/formaters";
import StyledFormInputLabel from "../../../styled/StyledFormInputLabel";

const config = require("../../../config")
const staticURL = config.BASE_URL;

function GeneralDetails({values, setValues}){

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if(name=="mobile")
          value = formatPhoneNumber(value);
        setValues({
            ...values,
            [name]:value
        })
    }

    function handleSelect(e) {
        // console.log(e, e.target)
        let name = e.target.name;
        let value = e.target.checked ? true :false;
        setValues({
            ...values,
            [name]:value
        })
    }

    return(
        <StyledFrame>
            <Grid container>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <StyledFormControl>
                                <StyledFormInputLabel 
                                primary={true}
                                >First Name</StyledFormInputLabel>
                                <StyledInputText 
                                    name="firstName"
                                    value={values.firstName} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={6}>
                            <StyledFormControl>
                                <StyledFormInputLabel 
                                primary={true}
                                >Last Name</StyledFormInputLabel>
                                <StyledInputText 
                                    name="lastName"
                                    value={values.lastName} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={6}>
                            <StyledFormControl>
                                <StyledFormInputLabel 
                                primary={false}>Date of Join</StyledFormInputLabel>
                                <StyledDatePicker
                                    type="date"
                                    id="dateOfJoin"
                                    name="dateOfJoin"
                                    value={values.dateOfJoin?values.dateOfJoin:moment(new Date()).format("yyyy-MM-dd")}
                                    onChange={(e)=>{handleChange(e)}}
                                />
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={6}>
                            <StyledFormControl>
                                <StyledFormInputLabel 
                                    primary={true}>
                                    Mobile
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    name="mobile"
                                    value={formatPhoneNumber(values.mobile)} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={6}>
                            <StyledFormControl>
                                <StyledFormInputLabel 
                                    primary={true}>
                                    Email
                                </StyledFormInputLabel>
                                <StyledInputText 
                                    name="email"
                                    value={values.email} 
                                    onChange={e=>handleChange(e)}/>
                            </StyledFormControl> 
                        </Grid>   
                        <Grid item md={6} style={{display:"flex", alignItems:"end"}}>
                        <StyledFormControl>
                            <FormControlLabel required control={
                                <Checkbox  
                                    name="active" 
                                    checked={values.active? true:false} 
                                    defaultChecked={values.active? true:false}
                                    onChange={e=>handleSelect(e)}
                                />} label="Active" />
                        </StyledFormControl>
                        </Grid>    
                    </Grid>
                </Grid>
            </Grid>
        </StyledFrame>
    )
}

export default GeneralDetails;