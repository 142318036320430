import React, { useState, useEffect } from "react";
import 'fontsource-roboto'; 
import {BASE_URL} from '../../config';

import { forwardRef } from "react";
import MaterialTable from "material-table";
import axios from "axios"; 

import { Alert, Grid, TextField   } from "@mui/material";
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn, PersonAdd } from "@mui/icons-material";
import { StyledHeader } from "../../styled";
var md5 = require('md5');

const tableIcons = {
  Add: forwardRef((props, ref) => <PersonAdd {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


function validateEmail(email) {
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

function CRUDUsers() {

  const [userLevels, setUserLevels] = useState([]);
  let user = {'active':false,name:''}; 
  if(localStorage.getItem('session')){
      user =   JSON.parse(  localStorage.getItem('session'));
  }
  const api = axios.create({
    /*baseURL: `https://reqres.in/api`*/
    baseURL: BASE_URL,
    headers : {
      "x-auth-key": user.key,
    }
  });
  

  useEffect(() => {   
    async function getUserLevels() {
        const result = await api.get('/userlevel/all');     
        console.log(result)  ;
        let rO = []
         for(let o of result.data.data){
          rO[o.id] = o.name ; 

         }  
        console.log(rO)  ;

        setUserLevels(rO);   
    }
    getUserLevels();
    }, []);




  var columns = [
    { title: "id", field: "id", hidden: true },
   
     
    
    { title: "First name", field: "firstName" },
    { title: "Last name", field: "lastName" },
    { title: "email", field: "email" },
    
    { title: "Mobile Phone", field: "mobilePhone" },
    { title: "User name", field: "userName" },
    { title: "User Level", field: "userlevel"  ,
     lookup: userLevels},
    { 
      title: 'Password', field: 'password',
      render: rowData => <p>****</p>,
      editComponent: props => (
          <TextField
              type="password"
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
          />)
      },
    { title: "Active", field: "active" ,  type: 'boolean'},
    { title: "Reset Password", field: "pwdreset" ,  type: 'boolean'},
   
  ];
  const [data, setData] = useState([]); //table data

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    api
      .get("/user/all?massession="+ user.key)
      .then(res => {
        setData(res.data.data);
      })
      .catch(error => {
        console.log("Error");
      });
  }, []); 

  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = [];
    console.log(newData); 
    if (newData.firstName === "") {
      errorList.push("Please enter first name");
    }
    if (newData.lastName === "") {
      errorList.push("Please enter last name");
    }
    if (newData.email === "" || validateEmail(newData.email) === false) {
      errorList.push("Please enter a valid email");
    }
    if(newData.password != oldData.password){
      newData.password = md5(newData.password );
    }
    
    if (errorList.length < 1) {
      api
        .patch("/user/" + newData.id, newData)
        .then(res => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch(error => {
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowAdd = (newData, resolve) => {
    //validation
    let errorList = [];
    if (newData.firstName === undefined) {
      errorList.push("Please enter first name");
    }
    if (newData.lastName === undefined) {
      errorList.push("Please enter last name");
    }
    if (newData.email === undefined || validateEmail(newData.email) === false) {
      errorList.push("Please enter a valid email");
    }
    newData.password = md5(newData.password );
    if (errorList.length < 1) {
      //no error
      api
        .post("/user", newData)
        .then(res => {
          let dataToAdd = [...data];
          dataToAdd.push(newData);
          setData(dataToAdd);
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch(error => {
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
       resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    api
      .delete("/user/" + oldData.id)
      .then(res => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve();
      })
      .catch(error => {
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        resolve();
      });
  };

  return (
    <div className="App">
      <Grid container p={0}>          
        <Grid item xs={12}>
          <div>
            {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
          </div>
          <Grid item md={12} sm={12} xs={12} >
            <StyledHeader style={{textAlign: 'left'}}>
              User Management
            </StyledHeader>
          </Grid>
          <Grid item xs={12} lg={12} p={0} className="material-table-custom">
            <MaterialTable
              title=""
              columns={columns}
              data={data}
              icons={tableIcons}
              options={{
                rowStyle: {
                  fontSize: 12,
                }
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    handleRowUpdate(newData, oldData, resolve);
                  }),
                onRowAdd: newData =>
                  new Promise(resolve => {
                    handleRowAdd(newData, resolve);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    handleRowDelete(oldData, resolve);
                  })
              }}
            />
          </Grid>
        </Grid>
           
      </Grid>
    </div>
  );
}

export default CRUDUsers;
