import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Grid,
  LinearProgress,
} from "@mui/material";
import { StyledFrame } from "../../../styled";
import MaintenanceManagementSummaryHeader from "./MaintenanceManagementSummaryHeader";
import { useEffect, useState } from "react";
import { getDriverList, getVehicles } from "../../../api/DriverManagementApi";
import { filterMaintenanceRecords } from "../../../api/MaintenanceManagementApi";
import SearchBy from "./SearchBy";
import MaintenanceDialog from "./MaintenanceDialog";
import moment from "moment";

const tableSx = {
  "& .MuiTableCell-head": {
    backgroundColor: "#f5fafe",
    color: "#033d5f",
    fontWeight: "bold !important",
    fontSize: 14,
  }
};

const MaintenanceManagementSummary = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [reload, setReload] = useState(true);
  const [isMaintenanceInfoOpen, setMaintenanceInfoOpen] = useState(false);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [search, setSearch] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [loading, setLoading] = useState([]);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRowClick = (rowIndex) => {
    setSelectedRow(rowIndex);
    setMaintenanceInfoOpen(true);
  };

  useEffect(() => {
    async function getRows() {
      try {
        if (!search) {
          return;
        }
        setLoading(true);
        let filter = {
          ...searchParams,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        };

        if(!filter.dateFrom && !filter.dateTo) {
          filter = {
            ...filter,
            dateFrom: moment(new Date()).format("YYYY-MM-DD"),
            dateTo: moment(new Date()).format("YYYY-MM-DD"),
          }          
        }

        let results = await filterMaintenanceRecords(filter);
        if (results) {
          setData(results);
        }

        setLoading(false);
      } catch (error) {
        console.log('[Error] ', error);
      }
    }
    getRows();
    setSearch(false);
  }, [search, page, rowsPerPage]);

  useEffect(() => {
    const loadDrivers = async () => {
      if (reload) {
        if (!user) {
          if (localStorage.getItem("session")) {
            var user = JSON.parse(localStorage.getItem("session"));
          }
        }
        var driverList = await getDriverList(user);
        let _dkDrivers = driverList.data.map((d) => {
          return {
            ...d,
            ["fullName"]: `${d.firstName} ${d.lastName}`,
          };
        });

        var vehicles = await getVehicles(user);

        setVehicles(vehicles);
        setFilteredDrivers(_dkDrivers);
        setReload(false);
      }
    };
    loadDrivers();
  }, [reload]);

  const handleCloseInfoView = (event, reason) => {
    if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setMaintenanceInfoOpen(false);
    }
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearch(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSearch(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <MaintenanceManagementSummaryHeader />

      <Grid item md={12} xs={12} lg={12} p={1}>
        <StyledFrame>
          <SearchBy
            setSearch={setSearch}
            setSearchParams={setSearchParams}
            drivers={filteredDrivers}
            vehicles={vehicles}
          />
        </StyledFrame>
      </Grid>
      
      {loading && (
        <div>
          <LinearProgress />
        </div>
      )}

      <Grid item md={12} xs={12} lg={12} p={1}>
        <TableContainer component={Paper}>
          <Table sx={tableSx}>
            <TableHead>
              <TableRow>
                <TableCell>Vehicle Name</TableCell>
                <TableCell>Type & Make</TableCell>
                <TableCell>License expiry date</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Last Maintenance</TableCell>
                <TableCell>Done by</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={row.id}
                  onClick={() => handleRowClick(index)}
                  selected={selectedRow === index}
                  hover className="hover-row"
                >
                  <TableCell>{row.vehiclenameAndLicense}</TableCell>
                  <TableCell>{row.vehicletype}</TableCell>
                  <TableCell>{moment(row.expiringIn, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{row.assignedTo}</TableCell>
                  <TableCell>{row.lastMaintenance}</TableCell>
                  <TableCell>{row.doneBy}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            component="div"
            count={100}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>

      <MaintenanceDialog
        open={isMaintenanceInfoOpen}
        handleClose={handleCloseInfoView}
        vehicleInfo={data[selectedRow]}
      />
    </div>
  );
};

export default MaintenanceManagementSummary;
