import React, {useEffect, useState }  from 'react';
import './App.css';
import ScreenPicker from './screenPicker';

import  { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';



function App() {
  const [theme, colorMode] = useMode()
  return (     
    <ColorModeContext.Provider   value= {colorMode}>      
      <ThemeProvider theme= {theme}>
        <CssBaseline>
           <main>
             <ScreenPicker/>
          </main>
        </CssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
export default App;
