import { useEffect, useState } from "react";
import CustomerFilter from "./CustomerFilter";
import CustomerManagementHeader from "./CustomerManagementHeader";
import CustomerTable from "./CustomerTable";
import { Grid, IconButton } from "@mui/material";
import { StyledIconWrapper } from "../../../styled";
import { AddCircleOutline } from "@mui/icons-material";

import {
  deleteCustomer,
    getCustomerList
  } from "../../../api/CustomerManagementApi"
import AddEditCustomer from "./AddEditCustomer";
import CustomerDeleteConfirmationPopup from "./CustomerDeleteConfirmationPopup";
import { toast } from 'react-toastify';

const CustomerManagement = () => {
    const [filter, setFilter] = useState("all");
    const [queryFilter, setQueryFilter] = useState(null);
    const [showCustomerList, setShowCustomerList] = useState(true);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);
    const [customers, setCustomers] = useState(null);
    const [action, setAction] = useState({"action":"list","customer":null});
    const [reload, setReload] = useState(true);
    const [openAddEditCustomer, setOpenAddEditCustomer] = useState(false);
    const [currentCustomer, setCurrentCustomer] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const handleCloseAddEditCustomer = () => {
      setOpenAddEditCustomer(false);
      setReload(true);
    };

    const loadCustomers = async (page, rowsPerPage, filter) =>{
      if(reload || pageChanged){
        if(!user){
          if (localStorage.getItem("session")) {
            var user = JSON.parse(localStorage.getItem("session"));
          }
        }
        
        var customerList = await getCustomerList(user, page, rowsPerPage, filter);
        
        let _customerList = customerList.data.map((d)=>{
          return {
            ...d,
          ["fullName"]: `${d.firstname} ${d.lastname}`,
          ["lng"]: `${d.long || ""}`,
          ["lat"]: `${d.lat || ""}`,
          ["provider"]: (d.provider || "01"),
          ["providerId"]: (d.providerId || "MAS"),
          ["state"]: (d.state || ""),
          ["county"]: (d.county || "")
          }
        })
        setAllCustomers(_customerList);
        setFilteredCustomers(_customerList);
        setReload(false);

        setTotalRows(customerList.total);
        setTotalPages(customerList.total_pages);
      } 
    }

    useEffect(()=>{        
      loadCustomers(0, 10, null);
    },[reload])

    const reloadData = (page, rowsPerPage, filter) => {
        setPageChanged(true)
        loadCustomers(page, rowsPerPage, filter);
    }

    const handleFilterChange = (filterType) => {
        if (filterType === "all") {
          setFilteredCustomers(allCustomers);
        } else if (filterType === "assigned") {
          let Customers = allCustomers.filter((customer) => customer.vehicle_id);
          setFilteredCustomers(Customers);
        } else if (filterType === "unassigned") {
          const Customers = allCustomers.filter(
            (customer) => !customer.vehicle_id
          );
          setFilteredCustomers(Customers);
        } else if (filterType === "active") {
          const Customers = allCustomers.filter(
            (customer) => customer.active
          );
          setFilteredCustomers(Customers);
        } else if (filterType === "inactive") {
          const Customers = allCustomers.filter(
            (customer) => !customer.active
          );
          setFilteredCustomers(Customers);
        }
      };

      useEffect(() => {
        if(action.action === 'edit') {
          showHideCustomerView(action.customer)
        } else if(action.action === 'delete') {
          setCurrentCustomer(action.customer);
          setShowDeleteConfirm(true)
        }
      }, [action])

    const showHideCustomerView = (currentCustomer) =>{

      if(currentCustomer != null) {
        setCurrentCustomer(currentCustomer);
      } else {
        let newCustomer = {
          "firstname": "",
          "lastname": "",
          "address": "",
          "contactnumber": "",
          "lat": null,
          "lng": null,
          "cinno": "",
          "addressUpdated": 0,
          "SUN_SELF": 0,
          "MON_SELF": 0,
          "TUE_SELF": 0,
          "WED_SELF": 0,
          "THU_SELF": 0,
          "FRI_SELF": 0,
          "SAT_SELF": 0,
          "SUN_WAIT": 15,
          "MON_WAIT": 15,
          "TUE_WAIT": 15,
          "WED_WAIT": 15,
          "THU_WAIT": 15,
          "FRI_WAIT": 15,
          "SAT_WAIT": 15,
          "dateOfBirth": "",
          "provider": "01",
          "providerId": "01",
          "city": "",
          "zip": "",
          "state": "NY",
          "country": "USA",
          "medicaidAddress": "",

        }
  
        setCurrentCustomer(newCustomer);
      }

      
      setOpenAddEditCustomer(true);
    }

    const handleDeleteConfirm = async() => {
      try {
        await deleteCustomer(currentCustomer)
        
        toast.success("Customer deleted" , {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
        setShowDeleteConfirm(false)
        setReload(true);
      } catch (error) {
        console.log('[Error] : ', error);
        toast.error(("Customer deletion failed. Please try again later"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    const handleDeleteClose = () => {
      setShowDeleteConfirm(false)
    }

    return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >          
          <CustomerManagementHeader />
          <Grid container>
            <Grid item md={6}>
              <CustomerFilter setFilter={setQueryFilter} />
            </Grid>
            <Grid item md={6} style={{textAlign:"end"}}>
              <IconButton style={{padding:0}} variant={"contained"} theme onClick={()=>{showHideCustomerView(null)}}>
                <StyledIconWrapper style={{padding:0}} >
                  <AddCircleOutline fontSize="large" />
                </StyledIconWrapper>
              </IconButton>
            </Grid>
            <Grid item md={12} style={{marginTop:"1.5rem"}}>
              <CustomerTable customers={filteredCustomers} setAction={setAction} reloadData={reloadData} totalPages={totalPages} filter={queryFilter}/>  
            </Grid>
          </Grid> 
          <AddEditCustomer open={openAddEditCustomer} onClose={handleCloseAddEditCustomer} customer={currentCustomer}/>
          <CustomerDeleteConfirmationPopup open={showDeleteConfirm} handleConfirm={handleDeleteConfirm} handleClose={handleDeleteClose}/>
        </div>
      );
}

export default CustomerManagement;