
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const FormInputLabel = ({theme, primary, children}) =>{
    return (
        <Typography  sx={{
            fontWeight:800
            }}>{children}
            {primary?<span style={{color:"red"}}>{' '}*</span>: null}</Typography>
    )
}

const StyledFormInputLabel = styled(FormInputLabel)(({theme}) =>({
    borderColor: theme.palette.primary.light,
    borderWidth:"thin",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  }));


  export default StyledFormInputLabel;